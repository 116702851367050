import React, { Component }  from "react";
import { GoSell } from "@tap-payments/gosell";

class Redirect extends Component {

 render() {
    return (
      <div className="App">
        <GoSell
          gateway={{
            callback: (response) => {
              console.log("callback", response);
            },
          }}
        />
      </div>
    );
  }
}

export default Redirect;