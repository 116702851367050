import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
// import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import WhatshotIcon from '@material-ui/icons/Whatshot';

import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import Abou from "./Abou";
import Testimonials from "../components/Testimonials";
import Review from "../components/Review";
import PickCar from "../components/PickCar";
import ChooseUs from "../components/ChooseUs";
import Faq from "../components/Faq";

import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import KIT1 from '../assets/images/KIT.jpg';


import Tourism from '../assets/images/Tourism.svg';
// import slid2 from '../assets/images/slid2.webp';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import logo from '../assets/images/logo-kar.png';
// import ab2 from '../assets/images/aboutimage.png';
import about from '../assets/images/about1.png';
import ab from '../assets/images/ab.jpg';
import ab15 from '../assets/images/ab1.jpg';
import ab16 from '../assets/images/ab2.jpg';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';
import Im1 from '../assets/images/im1.png';
import Im2 from '../assets/images/im2.png';


import sh1 from '../assets/images/short-term.png';
import mt1 from '../assets/images/monthly.png';
import we1 from '../assets/images/long-term.png';


import ch34 from '../assets/images/Unt.png';
import ch343 from '../assets/images/Unt1.jpg';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
import ch344444 from '../assets/images/dollar-bill.png';
import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
// import ch344444444 from '../assets/images/KIT3.jpg';
import Toyo from '../assets/images/Toyota-Corolla_20230829_201645_0000-2-1024x767.png';
import Toyo2 from '../assets/images/Toyota Land Cruiser V8.png';
import Toyo3 from '../assets/images/Prado TX.png';
import Toyo4 from '../assets/images/toyota-coaster-bus-car-rental-coaster-bus-3f48fa740baeaaeb202cf19c12b8bc31.png';
import Toyo51 from '../assets/images/moving-black-car-road.jpg';
import Toyo511 from '../assets/images/center.png';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import IconBox from './iconBox';
import Carousel1 from './Carosel';
import Carousel11 from './Carosel0';
import Carousel2 from './Carosel1';
// import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
// import Carousel5 from './Carosel4';
// import Carousel6 from './Carosel5';
// import Carousel7 from './Carosel6';
import Carousel8 from './Carosel7';
// import Ico from './iconBox';
// import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";


// import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch1 from '../assets/images/pd1-724x1024.jpg';
// import ch2 from '../assets/images/pd2-724x1024.jpg';
// import ch3 from '../assets/images/pd3-724x1024.jpg';
// import ch4 from '../assets/images/pd4-724x1024.jpg';
// import ch5 from '../assets/images/pd5-724x1024.jpg';




const Home = ( props ) => {
   
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [SliderPhoto8 , setSliderPhoto8] = useState([])
    // const [ArrivalPhoto , setArrivalPhoto] = useState([])
    // const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    // const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    // const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    // const [chk1 , setchk1] = useState(true)
    // const [chk2 , setchk2] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])


    const [CarBookPrice , setCarBookPrice] = useState([])
    
    // const [Ite1 , setIte1] = useState(1)
    // const [Ite2 , setIte2] = useState(1)
    
    // const [fg , s/etfg] = useState(true)




    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    const [pickup , setpickup] = useState("")
    const [drop , setdrop] = useState("")
    const [choosetime , setchoosetime] = useState("")
    const [pickupdate , setpickupdate] = useState("")
    const [dropdate , setdropdate] = useState("")
    const [phone , setphone] = useState("")
    const [car , setcar] = useState("")
    const [email , setemail] = useState("")
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
    

    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
 
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://falcon-rides-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))
    localStorage.setItem("All1",JSON.stringify(res6))
    setdis(true)
   })
    
 },[])

 
   
    
    
    
    

// const SubmitDataMessage = (e) =>{
//   e.preventDefault()
 

// if(pickup===""){
//   swal("Enter Pickup City")
//   return
// }
// if(drop===""){
//   swal("Enter Drop City")
//   return
// }

//                               fetch("https://falcon-rides-main-back.vercel.app/createbooking1",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                             pickup ,  
//                                             drop ,
//                                             choosetime ,
//                                             pickupdate , 
//                                             dropdate,
//                                             phone ,
//                                             car ,
//                                             email ,
//                                             Product_Image_Upload : "A",
//                                             // Product_Image_Upload : res2.url,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(res12.Error){
//                                             this.setState({displayNone : true})

//                                           swal(res12.Error)
//                                       }
//                                       else{
//                                               swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
//                                               this.setState({
//                                                 // Product_Catagories: "",
//                                                 // Product_Image_Upload : "",
//                                                 url : "",
//                                           })
//                                           this.setState({displayNone : true})

//                                       }
//                                   })
//                                   .catch(err=>{
//                                     swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");

//                                       // swal("There is An Error")                  
//                                       }) 
//                                   // }
//                                   // else{
//                                   //   swal("Successfully Upload your Product!");

//                                   //     swal("Upload Again")                  
//                                   // }
//               // })
//               // .catch(err=>swal("There is an Error"))

//           }  

    
//           const setpickup11 = (e)=>{
//             setpickup1(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop1){
//               let a = false
//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
//                   a = true
//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car1").innerHTML ="No Any Package"
//                   }
//                 }
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup21 = (e)=>{
//             setpickup2(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop2){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === " Toyota Land Cruiser V8"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car2").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup31 = (e)=>{
//             setpickup3(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop3){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car3").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup41 = (e)=>{
//             setpickup4(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop1){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car4").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setdrop11 = (e)=>{
//             setdrop1(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//           if(pickup1){
//             let a = false
//             for (let i = 0; i < CarBookPrice.length; i++) {
//               if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
//                 a = true  
//                 if(CarBookPrice[i].Price> 1){
//                     console.log("gdfg",CarBookPrice[i].Price)
//                     document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                   console.log("gdfg",CarBookPrice[i].Price)
//                   document.getElementById("car1").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

//             }
//             console.log("gdfg")
//           }
//           const setdrop21 = (e)=>{
//             setdrop2(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup2){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car2").innerHTML ="No Any Package"
//                 }
//               }
              
//             }
//             if(!a)  document.getElementById("car2").innerHTML ="No Any Package"
//             }
//           }
//           const setdrop31 = (e)=>{
//             setdrop3(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup3){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car3").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car3").innerHTML ="No Any Package"

//             }
//           }
//           const setdrop41 = (e)=>{
//             setdrop4(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup4){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
//                 a=  true
//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car4").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car4").innerHTML ="No Any Package"

//             }
//           }



//           const setCarPick1 = (e)=>{
//             if(pickup1 && drop1){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Toyota Corolla", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup1,
//                       Drop : drop1
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick2 = (e)=>{
//             if(pickup2 && drop2){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "THonda BRV", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup2,
//                       Drop : drop2
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick3 = (e)=>{
//             if(pickup3 && drop3){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Grand Cabin", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup3,
//                       Drop : drop3
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick4 = (e)=>{
//             if(pickup4 && drop4){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Saloon Coaster", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup4,
//                       Drop : drop4
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
        return (
            
          <div>










<Hero/>



























          <div className="page-wrapper">
            
            {/* End .header */}
            <main className="main" style={{maxHeight:  '100%' , overflowX :  "hidden"}}>



            <div className="container deal-section">
                <h1 className="title text-center font-weight-bold mt-3" style={{fontSize: "25px"}}> Explore Dubai With Best <br/> Car Rental In Dubai Like Never Before!

</h1>

                <div className='row'>
            

<Carousel11/>

                       
<br/>
                <span onClick={()=>props.history.push("/categories/Cars/all-products")}  className="shadow-none" style={{borderRadius: "10px",
    minWidth: '275px',
    maxWidth: "350px",
    color: 'white',
    background: "rgb(150, 4, 14)",
    padding: "8px 46px",
    fontSize: "25px",
    fontWeight: 'bold',
    cursor: "pointer",
    margin: "auto",
    textTransform: "uppercase"
  }}>                Explore our fleet
                </span>
                </div>  

                {/* <buttonstyle={{width: "92%"}}>
                Explore our fleet
                </button> */}
              </div>

<br/>
<br/>



            <BookCar />


















            <div className="container banner-group-1">
               
               <div className='container' style={{background:"rgb(240, 240, 240)"}}>
                  {/* <div className='container' style={{backgroundImage: 'url('+ab+')',backgroundPosition: "center",
     backgroundRepeat: "no-repeat",
     backgroundSize: "cover",}}> */}
                   <br/>
              <h3 class="text-center">Our Flexible Car Rental Plans

 </h3>
            
                     <div class="row justify-content-center">
                         <div class="col-lg-6 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px",borderRadius:"14px",borderBottom:"3px solid #96040e" ,height : "auto",color : "black", background : "white"}} >
                                 <span class="icon-box-icon" style={{padding: "0px 27px",
    display: "flex",
    justifyContent: "space-between" }}>
                                  <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px",padding:"0px",margin:"0px"}}> Short Term
<br />
<span style={{color :"#96040e",fontSize:"15px"}}>

Car Rental
</span>

 
 </h3>      {/* <i class="icon-local_car_wash"></i> */}
                                     <img src={sh1} alt="" style={{height   : "40px"}}/>
                                 </span>
                                 <div class="icon-box-content">
                                  
                                     <p className="cgg">Are you trying to find a flexible rental car? Check out our many car hire options, available for both day and weekly rentals. Whether you need to go on a weekend getaway or do a quick errand, we have the ideal short-term car rental deals.


 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-3 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px",borderRadius:"14px",borderBottom:"3px solid #96040e" ,height : "auto",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding: "0px 27px",
    display: "flex",
    justifyContent: "space-between" }}>
 <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px",padding:"0px",margin:"0px"}}>Monthly
                                     <br />
                                     
                                     <span style={{color :"#96040e",fontSize:"15px"}}>
 Car Rental
</span>
 </h3>
                             <img src={mt1} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     
                                     <p className="cgg" style={{}}>At Falcon Rides Car Rental, we offer monthly car hire services to both residents and tourists in the UAE, catering to all car rental requirements.


 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-3 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px",borderRadius:"14px",borderBottom:"3px solid #96040e" ,height : "auto",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding: "0px 27px",
    display: "flex",
    justifyContent: "space-between" }}>
  <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px",padding:"0px",margin:"0px"}}> Long-Term
 
 <br />
 <span style={{color :"#96040e",fontSize:"15px"}}>

 Car Rental
</span>
 </h3>
                             <img src={we1} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                    
                                     <p className="cgg">Our long term car lease plans provide comfortable travels within Dubai, whether you need a small city car or something more substantial.


 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         
                         </div>
                     </div>
 </div>
 









            <br />
 
 <div className="container banner-group-1">



     <div className='container' style={{}}>
   
           <div class="row justify-content-center">
               <div class="col-lg-10 col-sm-10">
<h1 style={{fontSize: "29px",
fontWeight: "700"}}>Document Required to Hire a Car in Dubai</h1>
<p>When reserving a rent a car service, it is essential to have the required paperwork in order to guarantee that you will have the chance to see the stunning towns of the United Arab Emirates. We provide a simple and easy booking process that you may start simply completing the necessary paperwork. We kindly ask that you submit the required paperwork during the booking process.</p>
      </div>  
      </div>  
      <br />
           <div class="row justify-content-center">
               <div class="col-lg-6 col-sm-6">
                   <div class="icon-box text-center" style={{borderLeft: "3px solid rgb(241, 199, 199)",margin  :"5px 15px" ,padding  :"10px 13px" ,height : "200px",color : "black", background : "white",display: "flex",justifyContent: "center",
alignItems: "center"}} >
                       <div>
                          <span class="icon-box-icon" style={{padding  :"0px" , }}>
                            <img src={Im1} alt="" style={{height   : "100px"}}/>
                        </span>
                       </div>
                       <div class="icon-box-content"  style={{paddingLeft:"30px"}}>
                           <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "20px"}}> In The Case Of Visitors To The UAE

                            </h3>
                           <p className="cgg" style={{textAlign :"left",paddingBottom:"12px",fontSize:"1.2rem"}}>⭕ Copy of passport.
                          </p>
                           <p className="cgg" style={{textAlign :"left",paddingBottom:"12px",fontSize:"1.2rem"}}>⭕ A legitimate driver's license from abroad

                          </p>
                           <p className="cgg" style={{textAlign :"left",paddingBottom:"12px",fontSize:"1.2rem"}}>⭕ Copy of Visit Visa or Stamped Visa
                           .
                          </p>
                       </div>
                   </div>
               </div>
               <div class="col-lg-6 col-sm-6">
                   <div class="icon-box text-center" style={{borderLeft: "3px solid rgb(241, 199, 199)",margin  :"5px 15px" ,padding  :"10px 13px" ,height : "200px",color : "black", background : "white",display: "flex",justifyContent: "center",
alignItems: "center"}} >
                       <div>
                          <span class="icon-box-icon" style={{padding  :"0px" , }}>
                            <img src={Im2} alt="" style={{height   : "111px",objectFit:"cover"}}/>
                        </span>
                       </div>
                       <div class="icon-box-content" style={{paddingLeft:"30px"}}>
                           <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "20px"}}> For Citizens Of The United Arab Emirates


                            </h3>
                           <p className="cgg" style={{textAlign :"left",paddingBottom:"12px",fontSize:"1.2rem"}}>⭕ Copy of passport.
                          </p>
                           <p className="cgg" style={{textAlign :"left",paddingBottom:"12px",fontSize:"1.2rem"}}>⭕ A current UAE driver's license


                          </p>
                           <p className="cgg" style={{textAlign :"left",paddingBottom:"12px",fontSize:"1.2rem"}}>⭕ A copy of the Emirates ID

                          </p>
                       </div>
                   </div>
               </div>

               
               
               </div>
           </div>
</div>











{/* <Abou/> */}







          




{/* 
              <div id="booking" class="section">
		<div class="section-center">
			<div class="container">
				<div class="row">
					<div class="booking-form">
						<form onSubmit={(e)=>SubmitDataMessage(e)}>
					
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option value="Choose PickUp">Choose PickUp</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Choose Drop Off">Choose Drop Off</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

                  </div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Choose Time</span>
										<input class="form-control" type="time" required placeholder="Choose Time"  value={choosetime} onChange={(e)=>setchoosetime(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Email</span>
										<input class="form-control" type="email" required placeholder="Enter Email"  value={email} onChange={(e)=>setemail(e.target.value)}/>
									</div>
								</div>
							
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP DATE</span>
										<input class="form-control" type="date" required  value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF DATE  </span>
										<input class="form-control" type="date" required  value={dropdate} onChange={(e)=>setdropdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PHONE NUMBER
</span>
                    <input class="form-control" type="number" required placeholder="Enter PHONE NUMBER"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Car</span>
                    <select class="form-control" required="required" aria-required="true"  value={car} onChange={(e)=>setcar(e.target.value)}>
                      <option value="Saloon Coaster">Saloon Coaster</option>
                      <option value="GRAND CABIN">GRAND CABIN</option>
                      <option value="HONDA BRV">HONDA BRV</option>
                      <option value="Coaster 4c Salon">Coaster 4c Salon</option>
                      <option value="Coaster 5c Salon">Coaster 5c Salon</option>
                      <option value="Grand Cabin 224">Grand Cabin 224</option>
                      <option value="Changan karvaan 7 seater">Changan karvaan 7 seater</option>
                      <option value="Apv 7 seater">Apv 7 seater</option>
                      <option value="Honda BRV">Honda BRV</option>
                      <option value="Voxy 7 seater">Voxy 7 seater</option>
                      <option value="Land Cruiser Limousine">Land Cruiser Limousine</option>
                      <option value="Mercedes C Class">Mercedes C Class</option>
                      <option value="Audi A6">Audi A6</option>
                      <option value="Audi A5">Audi A5</option>
                      <option value="Audi A4">Audi A4</option>
                      <option value="V8 Land cruiser">V8 Land cruiser</option>
                      <option value="Toyota Revo">Toyota Revo</option>
                      <option value="Prado">Prado</option>
                      <option value="Fortuner">Fortuner</option>
                      <option value="Civic 2021">Civic 2021</option>
                      <option value="TOYOTA Grande X">TOYOTA Grande X</option>
                      <option value="Altis X Corolla 1.6">Altis X Corolla 1.6</option>
                      <option value="TOYOTA Corolla Gli">TOYOTA Corolla Gli</option>
                      <option value="TOYOTA COROLLA">TOYOTA COROLLA</option>
</select>
										<span class="select-arrow"></span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-btn">
										<button class="submit-btn">Book Now</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
           <br/>   */}
           <br/>  
           <PickCar />


           <div className="container banner-group-1">
               
               <div className='container' style={{background:"rgb(240, 240, 240)"}}>
                  {/* <div className='container' style={{backgroundImage: 'url('+ab+')',backgroundPosition: "center",
     backgroundRepeat: "no-repeat",
     backgroundSize: "cover",}}> */}
                   <br/>
              <p class="text-center">AFFORDABLE CARS IN Dubai
 
 
 </p>
              <h2 class="title mb-4 text-center" style={{width: "auto"}}>Why <b>falcon-rides.com?</b> 
 
 </h2>
                     <div class="row justify-content-center">
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                 <span class="icon-box-icon" style={{padding  :"0px" , }}>
                                     {/* <i class="icon-local_car_wash"></i> */}
                                     <img src={ch344} alt="" style={{height   : "40px"}}/>
                                 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> First Class Service
 
 </h3>
                                     <p className="cgg">Experience first-class service that surpasses expectations.
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch3444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>24/7 Customer <br /> Support
 </h3>
                                     <p className="cgg" style={{}}>Call us Anywhere Anytime
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch34444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Clean and safe car
 </h3>
                                     <p className="cgg">Efficient safety and cleanliness checks
 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch3444444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Reservation Anytime  
 </h3>
                                     <p className="cgg">24/7 Online Reservation
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <WhatshotIcon alt="" style={{height   : "40px",color : "black",fontSize:"60px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Quality at Minimum Expense
  
 </h3>
                                     <p className="cgg">Delivering quality at a Minimum Spend.
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch34444444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Free Pick-Up & Drop-Off
 
  
 </h3>
                                     <p className="cgg">Enjoy complimentary pick-up and drop-off services.
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                     </div>
 </div>
 






  {/* <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Best Car Rental at Falcon Rides Rent a Car
</h3>

                <div>
            

<Carousel1/>

                       
                </div>  
              </div> */}

           
             



  {/* <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Best Car Rental at Falcon Rides Rent a Car
</h3>

                <div>
            

<Carousel2/>

                       
                </div>  
              </div> */}

           
             


















          


             



                {/* <div className="container">
                <div className="row">
                  <div className="col-12" style={{padding : "0px"}}>
                    <div className="">
                      <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+ch343+')',backgroundRepeat : "no-repeat" ,height:  "410px",backgroundColor : "transparent"}}>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}



              {/* <div className="container deal-section">
                <div>
            

<IconBox/>

                       
                </div>  
              </div>
             



<div className="container banner-group-1">
               
               <div className='container' style={{background:"rgb(240, 240, 240)"}}>
                 <br/>
            <p class="text-center" >Services


</p>
          
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Car Rental Booking
</h3>
                                   <p className="cgg">Experience luxury and convenience with our chauffeur-driven car rental service. Whether you're traveling for business or pleasure, sit back and relax as our professional chauffeurs take the wheel.





</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Tours Booking
</h3>
                                   <p className="cgg" style={{}}>


Book your next adventure with falcon-rides and receive the added benefit of a travel plan. Explore with peace of mind, knowing that you're protected against unforeseen circumstances.
</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Pick-Up & Drop-Off
</h3>
                                   <p className="cgg">Effortless pick-up and drop-off services tailored for your convenience. Experience seamless transportation with us. Your journey, our priority.



</p>
                               </div>
                           </div>
                       </div>
                       
                   </div>
</div>
</div> */}



<div className='container' style={{background :  "#96040e"}}>
          
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >

                  <img src={ch34444} alt="" style={{height   : "40px"}}/>

                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>50+ 
</h3>
                          <p className="cgg1" style={{}}>NO. OF CARS

</p>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <img src={ch344} alt="" style={{height   : "40px"}}/>
                    
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 5,000+

</h3>
                          <p className="cgg1">HAPPY CUSTOMERS




</p>
                      </div>
                  </div>
              </div>


              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >

                  <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 6
</h3>
                          <p className="cgg1">Operate In Locations

</p>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >

                  <img src={ch3444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 24/7
</h3>
                          <p className="cgg1">Quality Support

</p>
                      </div>
                  </div>
              </div>
              
          </div>
</div>

<br />

<ChooseUs />


<Review />


{/* 

<section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h2>Service Areas</h2>
            </div>

            <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/budget-friendly-car-rental-in-karama-by-quick-lease">
    <center>
        <h6>Budget Friendly Car Rental In Karama By Quick Lease</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/our-car-rental-fleet-per-day-aed-100-per-week-aed-1000-per-month-aed-1536">
    <center>
        <h6>Our Car Rental Fleet Per Day AED 100 Per Week AED 1000 Per Month AED 1536</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-karama-hire-quality-cars-at-cost-effective">
    <center>
        <h6>Rent A Car Karama Hire Quality Cars At Cost Effective</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-in-al-karama-hire-cheap-car-in-al-karama">
    <center>
        <h6>Car Rental In Al Karama Hire Cheap Car In Al Karama</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-karama-in-uae-on-the-map-celia-car-rental">
    <center>
        <h6>Rent A Car In Karama In UAE On The Map Celia Car Rental</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-karama-with-great-rent-a-car">
    <center>
        <h6>Rent A Car In Al Karama With Great Rent A Car</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-karama-dubai-cheap-car-hire-dubai">
    <center>
        <h6>Rent A Car In Al Karama Dubai Cheap Car Hire Dubai</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-rental-al-karama-rent-a-cars-royal-dubai-uae">
    <center>
        <h6>Cheap Car Rental Al Karama Rent A Cars Royal Dubai UAE</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-karama-dubai-united-arab-emirates">
    <center>
        <h6>Car Rental Karama Dubai United Arab Emirates</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-karama-dubai-daily-and-long-term-car-hire-al-karama">
    <center>
        <h6>Rent A Car Karama Dubai Daily And Long Term Car Hire Al Karama</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/luxury-car-rental-al-karama-rent-a-car-royal-dubai-uae">
    <center>
        <h6>Luxury Car Rental Al Karama Rent A Car Royal Dubai UAE</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-karama-dubai-best-deals-and-offers">
    <center>
        <h6>Rent A Car In Al Karama Dubai Best Deals And Offers</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-karama-dubai-affordable-reliable">
    <center>
        <h6>Rent A Car In Al Karama Dubai Affordable Reliable</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/do-you-reside-in-al-karama-and-wish-to-hire-a-car-to-make-your-travels-easier?">
    <center>
        <h6>Do You Reside In Al Karama And Wish To Hire A Car To Make Your Travels Easier?</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/best-deals-on-car-rentals-in-al-karama-dubai-daily-weekly-and-monthly-rates-available-book-now!">
    <center>
        <h6>Best Deals On Car Rentals In Al Karama Dubai Daily Weekly And Monthly Rates Available Book Now!</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/helps-you-to-find-perfect-car-for-your-next-ride-with-rent-a-car-dubai-in-al-karama">
    <center>
        <h6>Helps You To Find Perfect Car For Your Next Ride With Rent A Car Dubai In Al Karama</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-at-cheap-price-in-al-karama">
    <center>
        <h6>Rent A Car At Cheap Price In Al Karama</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-has-a-unique-list-of-cars-available-for-rent-in-al-karama-dubai!">
    <center>
        <h6>Rent A Car Has A Unique List Of Cars Available For Rent In Al Karama Dubai!</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-rental-in-al-karama-dubai-uae-price-from-aed-90-per-day">
    <center>
        <h6>Cheap Car Rental In Al Karama Dubai UAE Price From AED 90 Per Day</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/find-cheap-car-rental-deals-for-karama-at-falcon-rides-com">
    <center>
        <h6>Find Cheap Car Rental Deals For Karama At Falcon Rides Com</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/looking-for-a-car-rental-company-in-karama-dubai">
    <center>
        <h6>Looking For A Car Rental Company In Karama Dubai</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-karama-dubai-discover-the-vibrant-neighborhood-of-al-karama-with-our-affordable-car-rental-options">
    <center>
        <h6>Rent A Car In Al Karama Dubai Discover The Vibrant Neighborhood Of Al Karama With Our Affordable Car Rental Options</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/the-cost-of-renting-a-car-in-al-karama-varies-depending-on-the-type-of-vehicle-you-choose-and-the-rental-duration">
    <center>
        <h6>The Cost Of Renting A Car In Al Karama Varies Depending On The Type Of Vehicle You Choose And The Rental Duration</h6>
    </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/renting-a-car-in-al-karama-with-car-rental-dbx-is-not-only-convenient-but-also-remarkably-affordable">
    <center>
        <h6>Renting A Car In Al Karama With Car Rental DBX Is Not Only Convenient But Also Remarkably Affordable</h6>
    </center>
</Link>

          








            <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-online-at-affordable-prices">
  <center>
    <h6>Rent a Car in Dubai Online at Affordable Prices</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-economy-and-luxury-car-rental-uae">
  <center>
    <h6>Rent a Car Dubai | Economy and Luxury Car Rental UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/self-drive-cars-in-dubai-car-rental-services">
  <center>
    <h6>Self Drive Cars in Dubai | Car Rental Services</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/dubai-international-airport-term-1-car-rental">
  <center>
    <h6>dubai international airport term 1 - Car Rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-cheap-car-rental-car-hire-in-dubai-uae">
  <center>
    <h6>Rent a Car | Cheap Car Rental | Car Hire in Dubai UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-search-cheap-rental-car-deals">
  <center>
    <h6>Car Rental Dubai – Search Cheap Rental Car Deals</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-rental-in-dubai-find-and-compare-deals">
  <center>
    <h6>Cheap car rental in Dubai: Find and compare deals</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/weekly-car-rental-in-dubai-rent-a-car-with-no-hidden-charges">
  <center>
    <h6>Weekly Car Rental In Dubai - Rent A Car With No Hidden Charges</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-rent-a-car-dubai-at-lowest-price">
  <center>
    <h6>Car Rental | Rent a Car Dubai at lowest price</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-car-rental-agency-in-uae">
  <center>
    <h6>Rent A Car In Dubai - Car Rental Agency in UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-car-rental-dubai">
  <center>
    <h6>Rent a Car in Dubai - Car Rental Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-best-car-rental-offers-monthly-and-daily">
  <center>
    <h6>Rent a car Dubai | Best car rental offers Monthly and Daily</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-karama-cheap-car-rental-hire-in-dubai">
  <center>
    <h6>Rent a car Al Karama, Cheap Car Rental & Hire in Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-karama-at-cheapest-rates-falcon-rides-car-rental-dubai">
  <center>
    <h6>Rent A Car Karama At Cheapest Rates - Falcon Rides Car Rental Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/affordable-car-rental-al-karama-dubai">
  <center>
    <h6>Affordable Car Rental Al Karama - Dubai</h6>
  </center>
</Link>

            <div className="plan-container__boxes">
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-UAE">
                <center>
                <h6>Rent a Car UAE</h6>
               
                </center>
              </Link>
             
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai">
                <center>
                <h6>Rent a Car Dubai</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-karama">
                <center>
                <h6>Rent a Car Al Karama Dubai</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-quoz">
                <center>

                <h6>Rent a Car Al Quoz</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-Satwa">
                <center>

                <h6>Rent a Car Al Satwa</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-burj-al-arab">
                <center>
                <h6>Rent a Car Burj Al Arab</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-burj-khalifa">
                <center>

                <h6>Rent a Car Burj Khalifa</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-frame">
                <center>

                <h6>Rent a Car Dubai Frame</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-marina">
                <center>
                <h6>Rent a Car Dubai Marina</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-emirates-hills">
                <center>

                <h6>Rent a Car Emirates Hills</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-jumeirah-beach">
                <center>

                <h6>Rent a Car Jumeirah Beach</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-palm-jumeirah">
                <center>

                <h6>Rent a Car Palm Jumeirah</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-downtown">
                <center>

                <h6>Rent a Car Dubai Downtown</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Deira">
                <center>

                <h6>Rent a Car Deira</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-hills">
                <center>

                <h6>Rent a Car Dubai Hills</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-sheikh-zayed-road">
                <center>

                <h6>Rent a Car Sheikh Zayed Road</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-silicon-oasis">
                <center>

                <h6>Rent a Car Dubai Silicon Oasis</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Abu-Dhabi">
                <center>

                <h6>Rent a Car Abu Dhabi</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Ajman">
                <center>

                <h6>Rent a Car Ajman</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Dubai-Fountain">
                <center>

                <h6>Rent a Car Dubai Fountain</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Ski-Dubai">
                <center>

                <h6>Rent a Car Ski Dubai</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Dubai-Miracle-Garden">
                <center>

                <h6>Rent a Car Dubai Miracle Garden</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Sharjah">
                <center>

                <h6>Rent a Car Sharjah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Fujairah">
                <center>

                <h6>Rent a Car Fujairah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Al-Ain">
                <center>

                <h6>Rent a Car Al Ain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Desert-Safari">
                <center>

                <h6>Rent a Car Desert Safari</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Ras-Al-Khaimah">
                <center>

                <h6>Rent a Car Ras Al Khaimah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-Umm-Al-Quwain">
                <center>

                <h6>Rent a Car Umm Al Quwain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-One-&-Only-One-Za'abeel">
                <center>

                <h6>Rent a Car One & Only One Za'abeel</h6>
               
                </center>
              </Link>
              






















              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-UAE">
                <center>
                <h6>Rent a Car Service in UAE</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-UAE">
                <center>
                <h6>Rent a Car Service in UAE</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai">
                <center>
                <h6>Rent a Car Dubai</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-al-karama">
                <center>
                <h6>Rent a Car Service in Al Karama Dubai</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-al-quoz">
                <center>

                <h6>Rent a Car Service in Al Quoz</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-al-Satwa">
                <center>

                <h6>Rent a Car Service in Al Satwa</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-burj-al-arab">
                <center>
                <h6>Rent a Car Service in Burj Al Arab</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-burj-khalifa">
                <center>

                <h6>Rent a Car Service in Burj Khalifa</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-dubai-frame">
                <center>

                <h6>Rent a Car Service in Dubai Frame</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-dubai-marina">
                <center>
                <h6>Rent a Car Service in Dubai Marina</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-emirates-hills">
                <center>

                <h6>Rent a Car Service in Emirates Hills</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-jumeirah-beach">
                <center>

                <h6>Rent a Car Service in Jumeirah Beach</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-palm-jumeirah">
                <center>

                <h6>Rent a Car Service in Palm Jumeirah</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-dubai-downtown">
                <center>

                <h6>Rent a Car Service in Dubai Downtown</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Deira">
                <center>

                <h6>Rent a Car Service in Deira</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-dubai-hills">
                <center>

                <h6>Rent a Car Service in Dubai Hills</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-sheikh-zayed-road">
                <center>

                <h6>Rent a Car Service in Sheikh Zayed Road</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-dubai-silicon-oasis">
                <center>

                <h6>Rent a Car Service in Dubai Silicon Oasis</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Abu-Dhabi">
                <center>

                <h6>Rent a Car Service in Abu Dhabi</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Ajman">
                <center>

                <h6>Rent a Car Service in Ajman</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Dubai-Fountain">
                <center>

                <h6>Rent a Car Service in Dubai Fountain</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Ski-Dubai">
                <center>

                <h6>Rent a Car Service in Ski Dubai</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Dubai-Miracle-Garden">
                <center>

                <h6>Rent a Car Service in Dubai Miracle Garden</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Sharjah">
                <center>

                <h6>Rent a Car Service in Sharjah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Fujairah">
                <center>

                <h6>Rent a Car Service in Fujairah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Al-Ain">
                <center>

                <h6>Rent a Car Service in Al Ain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Desert-Safari">
                <center>

                <h6>Rent a Car Service in Desert Safari</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Ras-Al-Khaimah">
                <center>

                <h6>Rent a Car Service in Ras Al Khaimah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-Umm-Al-Quwain">
                <center>

                <h6>Rent a Car Service in Umm Al Quwain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-service-in-One-&-Only-One-Za'abeel">
                <center>

                <h6>Rent a Car Service in One & Only One Za'abeel</h6>
               
                </center>
              </Link>
              
























              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-UAE">
                <center>
                <h6>Car Rental Service in UAE</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-UAE">
                <center>
                <h6>Car Rental Service in UAE</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/carrental-dubai">
                <center>
                <h6>Car Rental Dubai</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-al-karama">
                <center>
                <h6>Car Rental Service in Al Karama Dubai</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-al-quoz">
                <center>

                <h6>Car Rental Service in Al Quoz</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-al-Satwa">
                <center>

                <h6>Car Rental Service in Al Satwa</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-burj-al-arab">
                <center>
                <h6>Car Rental Service in Burj Al Arab</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-burj-khalifa">
                <center>

                <h6>Car Rental Service in Burj Khalifa</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-frame">
                <center>

                <h6>Car Rental Service in Dubai Frame</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-marina">
                <center>
                <h6>Car Rental Service in Dubai Marina</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-emirates-hills">
                <center>

                <h6>Car Rental Service in Emirates Hills</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-jumeirah-beach">
                <center>

                <h6>Car Rental Service in Jumeirah Beach</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-palm-jumeirah">
                <center>

                <h6>Car Rental Service in Palm Jumeirah</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-downtown">
                <center>

                <h6>Car Rental Service in Dubai Downtown</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Deira">
                <center>

                <h6>Car Rental Service in Deira</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-hills">
                <center>

                <h6>Car Rental Service in Dubai Hills</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-sheikh-zayed-road">
                <center>

                <h6>Car Rental Service in Sheikh Zayed Road</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-silicon-oasis">
                <center>

                <h6>Car Rental Service in Dubai Silicon Oasis</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Abu-Dhabi">
                <center>

                <h6>Car Rental Service in Abu Dhabi</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Ajman">
                <center>

                <h6>Car Rental Service in Ajman</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Dubai-Fountain">
                <center>

                <h6>Car Rental Service in Dubai Fountain</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Ski-Dubai">
                <center>

                <h6>Car Rental Service in Ski Dubai</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Dubai-Miracle-Garden">
                <center>

                <h6>Car Rental Service in Dubai Miracle Garden</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Sharjah">
                <center>

                <h6>Car Rental Service in Sharjah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Fujairah">
                <center>

                <h6>Car Rental Service in Fujairah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Al-Ain">
                <center>

                <h6>Car Rental Service in Al Ain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Desert-Safari">
                <center>

                <h6>Car Rental Service in Desert Safari</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Ras-Al-Khaimah">
                <center>

                <h6>Car Rental Service in Ras Al Khaimah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Umm-Al-Quwain">
                <center>

                <h6>Car Rental Service in Umm Al Quwain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-One-&-Only-One-Za'abeel">
                <center>

                <h6>Car Rental Service in One & Only One Za'abeel</h6>
               
                </center>
              </Link>
              
























              <Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-deira-cheap-car-rental-hire-in-dubai-uae">
  <center>
    <h6>Rent a car Deira, Cheap Car Rental & Hire in Dubai, UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/low-cost-car-rental-deira-dubai">
  <center>
    <h6>Low-Cost Car Rental Deira - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-deira-for-aed-75-day">
  <center>
    <h6>Car Rentals In Deira For AED 75/Day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-deira-budget-car-rentals-deira">
  <center>
    <h6>Rent a Car Deira | Budget Car Rentals Deira</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-deira-dubai-falcon-rides">
  <center>
    <h6>Car Rentals in Deira (Dubai) - Falcon Rides</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-deira-dubai">
  <center>
    <h6>Rent a Car in Deira Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-hire-in-deira-dubai-from-20-day">
  <center>
    <h6>Cheap car hire in Deira, Dubai from £20/day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-deira-car-hire-available-for-aed-72-day">
  <center>
    <h6>Rent a Car Deira | Car Hire Available for AED 72/Day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-from-deira-city-center-dubai">
  <center>
    <h6>Car Rental from Deira City Center - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-dubai-deira-cheap-car-rental-and-hire-in-deira">
  <center>
    <h6>Rent a Car in Dubai Deira | Cheap Car rental and hire in Deira</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-rent-a-car-deira-dubai">
  <center>
    <h6>Cheap Rent A Car Deira Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/best-car-rent-in-deira-hire-self-drive-cars-deposit-free">
  <center>
    <h6>Best Car Rent in Diera - Hire Self Drive Cars Deposit Free</h6>
  </center>
</Link>




<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-jumeirah-beach-residence">
  <center>
    <h6>Rent a Car Jumeirah Beach Residence</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-jumeirah-dubai">
  <center>
    <h6>Car Rental Jumeirah Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-jumeirah-dubai-from-34-day">
  <center>
    <h6>Car Rentals in Jumeirah (Dubai) from $34/day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-at-jumeirah-beach-residence">
  <center>
    <h6>Rent a Car at Jumeirah Beach Residence</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-jumeirah-village-circle-dubai">
  <center>
    <h6>Rent a Car in Jumeirah Village Circle, Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-jumeirah-beach-residence-dubai">
  <center>
    <h6>Rent A Car Jumeirah Beach Residence - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/pearl-jumeirah-rent-a-car-dubai-from-aed-40-day">
  <center>
    <h6>Pearl Jumeirah Rent A Car Dubai From AED 40/Day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-palm-jumeirah-dubai">
  <center>
    <h6>Car rental in Palm Jumeirah - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-hire-in-jumeirah-beach-residence">
  <center>
    <h6>Cheap Car Hire in Jumeirah Beach Residence</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-jumeirah-village-circle-jvc-dubai">
  <center>
    <h6>Rent a Car in Jumeirah Village Circle (JVC) - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-jumeirah-dubai">
  <center>
    <h6>Car Rental in Jumeirah, Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-rentals-in-jumeirah-from-just-32">
  <center>
    <h6>Cheap Car Rentals in Jumeirah from just $32</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/best-car-rent-in-jumeirah-hire-self-drive-cars">
  <center>
    <h6>Best Car Rent in Jumeirah - Hire Self Drive Cars</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-deals-at-hilton-dubai-jumeirah">
  <center>
    <h6>Car Rental Deals at Hilton Dubai Jumeirah</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-hire-in-jumeirah-dubai-from-31-day">
  <center>
    <h6>Cheap car hire in Jumeirah, Dubai from £31/day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/weekly-car-rental-jumeirah-1-dubai">
  <center>
    <h6>Weekly Car Rental Jumeirah 1 - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-rental-cars-jumeirah-beach-residence-book-now">
  <center>
    <h6>Cheap Rental Cars Jumeirah Beach Residence Book Now!</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-palm-jumeirah">
  <center>
    <h6>Rent a Car in Palm Jumeirah</h6>
  </center>
</Link>






<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-hire-in-al-quoz-dubai-from-aed-157-day">
  <center>
    <h6>Car Hire in Al Quoz (Dubai) from AED 157/day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-al-quoz-best-car-rental-offers-al-quoz">
  <center>
    <h6>Rent a Car Al Quoz | Best Car Rental Offers Al Quoz</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cost-effective-car-rental-al-quoz-dubai">
  <center>
    <h6>Cost-Effective Car Rental Al Quoz - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-al-quoz-dubai-from-109-aed">
  <center>
    <h6>Rent A Car In Al Quoz Dubai From 109 AED</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-al-quoz-dubai">
  <center>
    <h6>Rent a Car in Al Quoz, Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-al-quoz-cheap-car-rentals-near-me">
  <center>
    <h6>Rent A Car Al Quoz | Cheap Car Rentals Near Me</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-al-quoz-dubai-from-36-day">
  <center>
    <h6>Car Rentals in Al Quoz (Dubai) from $36/day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-dubai-al-quoz-branch">
  <center>
    <h6>Car rental in Dubai Al Quoze Branch</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-car-al-quoz-dubai">
  <center>
    <h6>Rent car Al Quoz - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-rentals-in-al-quoz-dubai-from-45-day">
  <center>
    <h6>Cheap Car Rentals in Al Quoz, Dubai from $45/day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-al-quoz-dubai">
  <center>
    <h6>Rent a Car in Al Quoz - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-al-quoz-find-special-car-hire-discounts">
  <center>
    <h6>Rent a Car Al Quoz | Find Special Car Hire Discounts</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/hire-car-al-quoz-dubai">
  <center>
    <h6>Hire Car Al Quoz - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/best-car-rent-in-al-quoz-dubai">
  <center>
    <h6>Best Car Rent in Al Quoz Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-dubai-al-quoz-uae-special-discounts-offers">
  <center>
    <h6>Car Rental Dubai Al Quoz, UAE - Special Discounts & Offers</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/affordable-car-rental-services-near-al-quoz-mall">
  <center>
    <h6>Affordable Car Rental Services Near Al Quoz Mall</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-al-quoz-mall-dubai-best-deals-and-offers">
  <center>
    <h6>Rent a Car in Al Quoz Mall, Dubai - Best Deals and Offers</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-al-quoz-dubai">
  <center>
    <h6>Rent a Car in Al Quoz, Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-companies-dubai-rent-a-luxury-car-with-driver">
  <center>
    <h6>Car Rental Companies Dubai - Rent a Luxury Car with Driver</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-al-quoz-mall-best-deals-offers">
  <center>
    <h6>Rent A Car Al Quoz Mall | Best Deals & Offers</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-al-quoz-dubai">
  <center>
    <h6>Car rentals in Al Quoz, Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-al-quoz-dubai-cheap-car-hire">
  <center>
    <h6>Rent a Car in Al Quoz, Dubai, Cheap Car Hire</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-dubai-best-car-rental-services">
  <center>
    <h6>Rent a Car Dubai | Best Car Rental Services</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-hire-leasing-al-quoz">
  <center>
    <h6>Car Hire & Leasing Al Quoz</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-al-quoz-rent-a-car-dubai-at-lowest-price">
  <center>
    <h6>Car Rental Al Quoz | Rent a Car Dubai at lowest price</h6>
  </center>
</Link>


<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-ras-al-khor-dubai-falcon-rides">
  <center>
    <h6>Car rentals in Ras Al Khor, Dubai - Falcon Rides</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/ras-al-khor-rent-a-car-dubai-with-falcon-rides">
  <center>
    <h6>Ras Al Khor Rent A Car Dubai With Falcon Rides</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-ras-al-khor-travel-with-ease-quick-lease">
  <center>
    <h6>Rent a car Ras Al Khor | Travel with ease | Quick Lease</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-ras-al-khor-dubai-best-deals-and-offers">
  <center>
    <h6>Rent a Car in Ras Al Khor, Dubai - Best Deals and Offers</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/premium-car-rental-service-ras-al-khor-dubai">
  <center>
    <h6>Premium Car Rental Service Ras Al Khor - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/21-dollar-car-rental-ras-al-khor-industrial-area">
  <center>
    <h6>$21 Car Rental Ras Al Khor Industrial Area</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-ras-al-khor-dubai">
  <center>
    <h6>Rent a car in Ras Al Khor - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-ras-al-khor">
  <center>
    <h6>Rent a car in Ras Al Khor</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-ras-al-khor-dubai">
  <center>
    <h6>Rent a Car Ras Al Khor - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-ras-al-khor-travel-with-ease">
  <center>
    <h6>Rent a car Ras Al Khor | Travel with ease</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-ras-al-khor-car-rental-in-dubai">
  <center>
    <h6>Rent a Car in Ras al Khor - car rental in Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-rental-cars-ras-al-khor-industrial-area-book-now">
  <center>
    <h6>Cheap Rental Cars Ras Al Khor Industrial Area Book Now!</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rent-in-ras-al-khor-under-140-aed-with-great-dubai">
  <center>
    <h6>Car Rent in Ras Al Khor Under 140 AED With Great Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-cheap-car-rental-hire-in-ras-al-khor-uae">
  <center>
    <h6>Rent a car, Cheap Car Rental & Hire in Ras Al Khor, UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/best-rent-a-car-ras-al-khor">
  <center>
    <h6>Best Rent a Car – Ras Al Khor</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/book-a-rent-a-car-in-dubai-weekly-monthly-cheap">
  <center>
    <h6>Book a Rent a Car in Dubai | Weekly, Monthly Cheap</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-dubai-ras-al-khor">
  <center>
    <h6>Rent a Car in Dubai Ras Al Khor</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/low-cost-car-rental-deals-in-dubai-ras-al-khor-uae">
  <center>
    <h6>Low-cost Car Rental Deals in Dubai Ras Al Khor, UAE 🔥</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/budget-car-rental-ras-al-khor-hire-economy-cars-for-rent-in-dubai">
  <center>
    <h6>Budget Car Rental Ras al Khor Hire Economy Cars for Rent in Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-hire-leasing-ras-al-khor">
  <center>
    <h6>Car Hire & Leasing Ras Al Khor</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-hire-ras-al-khor-dubai">
  <center>
    <h6>Cheap Car Hire Ras Al Khor Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-ras-al-khor-in-uae-on-the-map">
  <center>
    <h6>Car rental ras al khor in UAE on the map</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/pickup-rental-cars-ras-al-khor-industrial-area">
  <center>
    <h6>Pickup Rental Cars Ras Al Khor Industrial Area</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-ras-al-khor-dubai">
  <center>
    <h6>Rent a Car in Ras Al Khor, Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-car-rentals-in-ras-al-khor-industrial-area">
  <center>
    <h6>Car Rental Car Rentals in Ras Al Khor Industrial Area</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/autorent-car-rental-in-dubai-ras-al-khor-uae">
  <center>
    <h6>Autorent Car Rental in Dubai Ras Al Khor, UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/dollar-rent-a-car-car-rentals-in-ras-al-khor-industrial-area">
  <center>
    <h6>Dollar Rent A Car Car Rentals in Ras Al Khor Industrial Area</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-ras-al-khor-dubai">
  <center>
    <h6>Car rentals in Ras Al Khor Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-rental-deals-in-ras-al-khor-dubai">
  <center>
    <h6>Cheap Car Rental Deals in Ras Al Khor Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-hire-ras-al-khor-dubai">
  <center>
    <h6>Car Hire Ras Al Khor - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/best-luxury-car-rental-in-dubai-uae">
  <center>
    <h6>Best Luxury Car Rental in Dubai (UAE)</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-hire-in-zaabeel-dubai-from-77-pound-day">
  <center>
    <h6>Cheap car hire in Za'abeel, Dubai from £77/day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rentals-in-zaabeel-dubai-uae">
  <center>
    <h6>Car rentals in Za'abeel, Dubai UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-zaabeel-dubai">
  <center>
    <h6>Car Rental In Za'abeel - Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-zaabeel-dubai-cheap-car-hire">
  <center>
    <h6>Rent a Car in Za'abeel, Dubai, Cheap Car Hire</h6>
  </center>
</Link>



<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/dubai-festival-city-car-rental-hire-online">
  <center>
    <h6>Dubai Festival City Car Rental & Hire Online</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-dubai-festival-city">
  <center>
    <h6>Car Rental Dubai Festival City</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/21-dollar-car-rental-dubai-festival-city-mall">
  <center>
    <h6>$21 Car Rental Dubai Festival City Mall</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/long-term-car-rental-dubai-festival-city">
  <center>
    <h6>Long Term Car Rental Dubai Festival City</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/festival-city-rent-a-car-cheap-car-rentals">
  <center>
    <h6>Festival City Rent A Car | Cheap Car Rentals</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-dubai-festival-city-falcon-rides-car-rental">
  <center>
    <h6>Rent A Car Dubai Festival City - Falcon Rides Car Rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-dubai-festival-city">
  <center>
    <h6>Car Rental in Dubai Festival City</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-dubai-festival-city">
  <center>
    <h6>Car rental in Dubai Festival City</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/81-dollar-car-rental-dubai-festival-city">
  <center>
    <h6>$81 Car Rental Dubai Festival City</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-dubai-festival-city-united-arab-emirates">
  <center>
    <h6>Car Rental Dubai Festival City - United Arab Emirates</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-dubai-festival-city">
  <center>
    <h6>Rent a Car in Dubai Festival City</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-dubai-festival-city-mall">
  <center>
    <h6>Rent a Car in Dubai Festival City Mall</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-rental-cars-dubai-festival-city-book-now">
  <center>
    <h6>Cheap Rental Cars Dubai Festival City Book Now!</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/affordable-car-rent-in-dubai-festival-city-with-great-dubai">
  <center>
    <h6>Affordable Car Rent in Dubai Festival City with Great Dubai.</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-dubai-festival-city-online-reservation">
  <center>
    <h6>Rent a car Dubai Festival City - Online reservation</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/easy-and-economical-car-rental-festival-city-dubai">
  <center>
    <h6>Easy and economical car rental festival city Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-dubai-festival-city">
  <center>
    <h6>Rent a Car Dubai Festival City</h6>
  </center>
</Link>





<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-bur-dubai-cheap-car-rental-hire-in-bur-dubai">
  <center>
    <h6>Rent a car Bur Dubai, Cheap Car Rental & Hire in Bur Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-bur-dubai-for-75-day">
  <center>
    <h6>Rent A Car Bur Dubai For $75/Day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-bur-dubai-cheap-car-rental-bur-dubai">
  <center>
    <h6>Rent a Car Bur Dubai | Cheap Car Rental Bur Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/long-term-car-rentals-bur-dubai">
  <center>
    <h6>Long-Term Car Rentals Bur Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-hire-in-bur-dubai-search-hire-cars">
  <center>
    <h6>Car Hire in Bur Dubai (Dubai) - Search Hire Cars</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-bur-dubai-affordable-car-rental">
  <center>
    <h6>Rent A Car Bur Dubai | Affordable Car Rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/bur-dubai-rent-a-car-in-dubai">
  <center>
    <h6>Bur Dubai - Rent a Car in Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-dubai-search-cheap-rental-car-deals">
  <center>
    <h6>Car Rental Dubai – Search Cheap Rental Car Deals</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-car-rental-bur-dubai-rent-a-cars-royal-uae">
  <center>
    <h6>Cheap car rental Bur Dubai, rent a cars Royal: UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-bur-dubai">
  <center>
    <h6>Rent a Car in Bur Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-bur-dubai-car-rental-near-me-on-the-map">
  <center>
    <h6>Car rental in Bur Dubai, car rental near me on the map</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-bur-dubai-cheap-rental-cars">
  <center>
    <h6>Car Rental Bur Dubai - Cheap Rental Cars</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/weekly-car-hire-book-your-car-dubai-rental-deals-call-now">
  <center>
    <h6>Weekly Car Hire-book your car | Dubai Rental Deals - Call Now</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rental-cars-in-bur-dubai-in-uae-on-the-map">
  <center>
    <h6>Rental cars in Bur Dubai in UAE on the map</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-bur-dubai-experience-the-best-car">
  <center>
    <h6>Rent A Car Bur Dubai (Experience The Best Car)</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-rent-a-car-in-bur-dubai">
  <center>
    <h6>Cheap Rent a Car in Bur Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-dubai-cheapest-monthly-car-rental">
  <center>
    <h6>Rent a Car in Dubai, Cheapest Monthly Car Rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-bur-dubai-cheapest-monthly-car-rental">
  <center>
    <h6>Rent a Car in Bur Dubai, Cheapest Monthly Car Rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/low-cost-car-rental-deals-in-bur-dubai-uae">
  <center>
    <h6>Low-cost Car Rental Deals in Bur Dubai, UAE 🔥</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/luxury-car-rental-in-bur-dubai-make-my-ride">
  <center>
    <h6>Luxury Car Rental in Bur Dubai | Make My Ride</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-per-hour-in-dubai-hourly-car-rental">
  <center>
    <h6>Rent a car per hour in Dubai | Hourly car rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/cheap-rent-a-car-in-bur-dubai-cheapest-as-150-dhs-book">
  <center>
    <h6>Cheap Rent A Car In Bur Dubai - Cheapest As 150 Dhs Book</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/monthly-car-rental-in-bur-dubai">
  <center>
    <h6>Monthly Car Rental in Bur Dubai</h6>
  </center>
</Link>





<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-dubai-dubai-car-rental">
  <center>
    <h6>Car rental Dubai - Dubai car rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-companies-dubai">
  <center>
    <h6>Car Rental Companies Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/fast-rent-a-car-in-dubai-car-rental-dubai-aed-150-day">
  <center>
    <h6>Fast Rent a Car In Dubai | Car Rental Dubai AED 150/Day</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/dubai-leading-car-rental-rent-a-car-in-dubai">
  <center>
    <h6>Dubai's Leading Car Rental | Rent a Car in Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/dubai-internaional-airport-terminal-1-dxb-car-rental">
  <center>
    <h6>Dubai Intl. Airport Terminal 1 (DXB) Car Rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/renting-a-car-ministry-of-economy-uae">
  <center>
    <h6>Renting a Car | Ministry of Economy - UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/best-rent-a-car-in-dubai-economy-car-rental-dubai">
  <center>
    <h6>Best Rent A Car In Dubai | Economy Car Rental Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-dubai-dubai-car-rental-hire-car-in-dubai">
  <center>
    <h6>Rent a Car in Dubai | Dubai Car Rental | Hire Car in Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-hire-in-dubai-cheap-rental-deals">
  <center>
    <h6>Car Hire in Dubai - Cheap Rental Deals</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-luxury-car-with-driver-car-rental-companies-dubai">
  <center>
    <h6>Rent a Luxury Car with Driver - Car Rental Companies Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/legend-rent-a-car-car-rental-agency-dubai-hire-auto-vehicle">
  <center>
    <h6>Legend Rent a Car: Car Rental Agency Dubai Hire Auto Vehicle</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-dubai-united-arab-emirates">
  <center>
    <h6>Car Rental in Dubai United Arab Emirates</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-dubai-united-arab-emirates">
  <center>
    <h6>Car Rental Dubai - United Arab Emirates</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-leasing-in-dubai-best-car-rental">
  <center>
    <h6>Car Leasing in Dubai | Best Car Rental</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-for-rent-dubai-low-cost-rent-car-dubai">
  <center>
    <h6>Car For Rent Dubai | Low Cost Rent Car Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/dubai-luxury-car-rental-no-deposit-required">
  <center>
    <h6>Dubai Luxury Car Rental - No Deposit Required</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/premium-car-rental-dubai-rent-a-car-self-drive-dubai">
  <center>
    <h6>Premium Car Rental Dubai - Rent a Car Self Drive Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-car-rental-agency-hire-car">
  <center>
    <h6>Rent a Car | Car Rental Agency | Hire car</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/monthly-car-rental-dubai-from-aed-1390">
  <center>
    <h6>Monthly Car Rental Dubai from AED 1390</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/affordable-car-rental-in-dubai-low-cost-car-rental-services">
  <center>
    <h6>Affordable Car Rental in Dubai | Low Cost Car Rental Services</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/affordable-car-rental-in-dubai-rent-a-car-uae">
  <center>
    <h6>Affordable car rental in Dubai - Rent a Car UAE</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/your-cheap-car-hire-in-dubai">
  <center>
    <h6>Your Cheap Car Hire in Dubai</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/number-1-car-rental-in-uae-dubai-lease-our-cars-now">
  <center>
    <h6>#1 Car Rental in UAE, Dubai | Lease Our Cars Now</h6>
  </center>
</Link>

<Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/luxury-car-rental-in-dubai-wow-rent-a-car">
  <center>
    <h6>Luxury Car Rental in Dubai - Wow Rent a Car</h6>
  </center>
</Link>





































































 */}



{/* 




              <Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-deira-cheap-car-rental-hire-in-dubai-uae">                 <center>

                <h6>Rent a car Deira, Cheap Car Rental & Hire in Dubai, UAE</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/low-cost-car-rental-deira-dubai">                 <center>

                <h6>Low-Cost Car Rental Deira - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-deira-for-aed-75-day">                 <center>

                <h6>Car Rentals In Deira For AED 75/Day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-deira-budget-car-rentals-deira">                 <center>

                <h6>Rent a Car Deira | Budget Car Rentals Deira</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-deira-dubai-falcon-rides">                 <center>

                <h6>Car Rentals in Deira (Dubai) - Falcon Rides</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-deira-dubai">                 <center>

                <h6>Rent a Car in Deira Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-hire-in-deira-dubai-from-20-day">                 <center>

                <h6>Cheap car hire in Deira, Dubai from £20/day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-deira-car-hire-available-for-aed-72-day">                 <center>

                <h6>Rent a Car Deira | Car Hire Available for AED 72/Day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-from-deira-city-center-dubai">                 <center>

                <h6>Car Rental from Deira City Center - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-deira-cheap-car-rental-hire-in-deira">                 <center>

                <h6>Rent a Car in Dubai Deira | Cheap Car rental and hire in Deira</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-rent-a-car-deira-dubai">                 <center>

                <h6>Cheap Rent A Car Deira Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/best-car-rent-in-diera-hire-self-drive-cars-deposit-free">                 <center>

                <h6>Best Car Rent in Diera - Hire Self Drive Cars Deposit Free</h6>
               
                </center></Link>






<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-jumeirah-beach-residence">                 <center>

                <h6>Rent a Car Jumeirah Beach Residence</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-jumeirah-dubai">                 <center>

                <h6>Car Rental Jumeirah Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-jumeirah-dubai-from-34-day">                 <center>

                <h6>Car Rentals in Jumeirah (Dubai) from $34/day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-at-jumeirah-beach-residence">                 <center>

                <h6>Rent a Car at Jumeirah Beach Residence</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-jumeirah-village-circle-dubai">                 <center>

                <h6>Rent a Car in Jumeirah Village Circle, Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-jumeirah-beach-residence-dubai">                 <center>

                <h6>Rent A Car Jumeirah Beach Residence - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/pearl-jumeirah-rent-a-car-dubai-from-aed-40-day">                 <center>

                <h6>Pearl Jumeirah Rent A Car Dubai From AED 40/Day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-in-palm-jumeirah-dubai">                 <center>

                <h6>Car rental in Palm Jumeirah - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-hire-in-jumeirah-beach-residence">                 <center>

                <h6>Cheap Car Hire in Jumeirah Beach Residence</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-jumeirah-village-circle-jvc-dubai">                 <center>

                <h6>Rent a Car in Jumeirah Village Circle (JVC) - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-in-jumeirah-dubai">                 <center>

                <h6>Car Rental in Jumeirah, Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-rentals-in-jumeirah-from-32">                 <center>

                <h6>Cheap Car Rentals in Jumeirah from just $32</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/best-car-rent-in-jumeirah-hire-self-drive-cars">                 <center>

                <h6>Best Car Rent in Jumeirah - Hire Self Drive Cars</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-deals-at-hilton-dubai-jumeirah">                 <center>

                <h6>Car Rental Deals at Hilton Dubai Jumeirah</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-hire-in-jumeirah-dubai-from-31-day">                 <center>

                <h6>Cheap car hire in Jumeirah, Dubai from £31/day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/weekly-car-rental-jumeirah-1-dubai">                 <center>

                <h6>Weekly Car Rental Jumeirah 1 - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-rental-cars-jumeirah-beach-residence-book-now">                 <center>

                <h6>Cheap Rental Cars Jumeirah Beach Residence Book Now!</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-palm-jumeirah">                 <center>

                <h6>Rent a Car in Palm Jumeirah</h6>
               
                </center></Link>





<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-hire-in-al-quoz-dubai-from-aed-157-day">                 <center>

                <h6>Car Hire in Al Quoz (Dubai) from AED 157/day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-quoz-best-car-rental-offers-al-quoz">                 <center>

                <h6>Rent a Car Al Quoz | Best Car Rental Offers Al Quoz</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cost-effective-car-rental-al-quoz-dubai">                 <center>

                <h6>Cost-Effective Car Rental Al Quoz - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-quoz-dubai-from-109-aed">                 <center>

                <h6>Rent A Car In Al Quoz Dubai From 109 AED</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-quoz-dubai">                 <center>

                <h6>Rent a Car in Al Quoz, Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-quoz-cheap-car-rentals-near-me">                 <center>

                <h6>Rent A Car Al Quoz | Cheap Car Rentals Near Me</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-al-quoz-dubai-from-36-day">                 <center>

                <h6>Car Rentals in Al Quoz (Dubai) from $36/day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-in-dubai-al-quoze-branch">                 <center>

                <h6>Car rental in Dubai Al Quoze Branch</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-car-al-quoz-dubai">                 <center>

                <h6>Rent car Al Quoz - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-rentals-in-al-quoz-dubai-from-45-day">                 <center>

                <h6>Cheap Car Rentals in Al Quoz, Dubai from $45/day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-quoz-dubai">                 <center>

                <h6>Rent a Car in Al Quoz - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-quoz-find-special-car-hire-discounts">                 <center>

                <h6>Rent a Car Al Quoz | Find Special Car Hire Discounts</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/hire-car-al-quoz-dubai">                 <center>

                <h6>Hire Car Al Quoz - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/best-car-rent-in-al-quoz-dubai">                 <center>

                <h6>Best Car Rent in Al Quoz Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-al-quoz-uae-special-discounts-offers">                 <center>

                <h6>Car Rental Dubai Al Quoz, UAE - Special Discounts & Offers</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/affordable-car-rental-services-near-al-quoz-mall">                 <center>

                <h6>Affordable Car Rental Services Near Al Quoz Mall</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-quoz-mall-dubai-best-deals-and-offers">                 <center>

                <h6>Rent a Car in Al Quoz Mall, Dubai - Best Deals and Offers</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-quoz-dubai">                 <center>

                <h6>Rent a Car in Al Quoz, Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-companies-dubai-rent-a-luxury-car-with-driver">                 <center>

                <h6>Car Rental Companies Dubai - Rent a Luxury Car with Driver</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-al-quoz-mall-best-deals-offers">                 <center>

                <h6>Rent A Car Al Quoz Mall | Best Deals & Offers</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-al-quoz-dubai">                 <center>

                <h6>Car rentals in Al Quoz, Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-al-quoz-dubai-cheap-car-hire">                 <center>

                <h6>Rent a Car in Al Quoz, Dubai, Cheap Car Hire</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-best-car-rental-services">                 <center>

                <h6>Rent a Car Dubai | Best Car Rental Services</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-hire-leasing-al-quoz">                 <center>

                <h6>Car Hire & Leasing Al Quoz</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-al-quoz-rent-a-car-dubai-at-lowest-price">                 <center>

                <h6>Car Rental Al Quoz | Rent a Car Dubai at lowest price</h6>
               
                </center></Link>












<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-ras-al-khor-dubai-falcon-rides">                 <center>

                <h6>Car rentals in Ras Al Khor, Dubai - Falcon Rides</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/ras-al-khor-rent-a-car-dubai-with-falcon-rides">                 <center>

                <h6>Ras Al Khor Rent A Car Dubai WIth Falcon Rides</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-ras-al-khor-travel-with-ease-quick-lease">                 <center>

                <h6>Rent a car Ras Al Khor | Travel with ease | Quick Lease</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-ras-al-khor-dubai-best-deals-and-offers">                 <center>

                <h6>Rent a Car in Ras Al Khor, Dubai - Best Deals and Offers</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/premium-car-rental-service-ras-al-khor-dubai">                 <center>

                <h6>Premium Car Rental Service Ras Al Khor - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/21-car-rental-ras-al-khor-industrial-area">                 <center>

                <h6> $21 Car Rental Ras Al Khor Industrial Area</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-ras-al-khor-dubai">                 <center>

                <h6>Rent a car in Ras Al Khor - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-ras-al-khor">                 <center>

                <h6>Rent a car in Ras Al Khor</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-ras-al-khor-dubai">                 <center>

                <h6>Rent a Car Ras Al Khor - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-ras-al-khor-travel-with-ease">                 <center>

                <h6>Rent a car Ras Al Khor | Travel with ease</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-ras-al-khor-car-rental-in-dubai">                 <center>

                <h6>Rent a Car in Ras al Khor - car rental in Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-rental-cars-ras-al-khor-industrial-area-book-now">                 <center>

                <h6>Cheap Rental Cars Ras Al Khor Industrial Area Book Now!</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rent-in-ras-al-khor-under-140-aed-with-great-dubai">                 <center>

                <h6>Car Rent in Ras Al Khor Under 140 AED With Great Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-cheap-car-rental-hire-in-ras-al-khor-uae">                 <center>

                <h6>Rent a car, Cheap Car Rental & Hire in Ras Al Khor, UAE</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/best-rent-a-car-ras-al-khor">                 <center>

                <h6>Best Rent a Car – Ras Al Khor</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/book-a-rent-a-car-in-dubai-weekly-monthly-cheap">                 <center>

                <h6>Book a Rent a Car in Dubai | Weekly, Monthly Cheap</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-ras-al-khor">                 <center>

                <h6>Rent a Car in Dubai Ras Al Khor</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/low-cost-car-rental-deals-in-dubai-ras-al-khor-uae">                 <center>

                <h6>Low-cost Car Rental Deals in Dubai Ras Al Khor, UAE 🔥</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/budget-car-rental-ras-al-khor-hire-economy-cars-for-rent-in-dubai">                 <center>

                <h6>Budget Car Rental Ras al Khor Hire Economy Cars for Rent in Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-hire-leasing-ras-al-khor">                 <center>

                <h6>Car Hire & Leasing Ras Al Khor</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-hire-ras-al-khor-dubai">                 <center>

                <h6>Cheap Car Hire Ras Al Khor Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-ras-al-khor-in-uae-on-the-map">                 <center>

                <h6>Car rental Ras Al Khor in UAE on the map</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/pickup-rental-cars-ras-al-khor-industrial-area">                 <center>

                <h6>Pickup Rental Cars Ras Al Khor Industrial Area</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-ras-al-khor-dubai">                 <center>

                <h6>Rent a Car in Ras Al Khor, Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-car-rentals-in-ras-al-khor-industrial-area">                 <center>

                <h6>Car Rental Car Rentals in Ras Al Khor Industrial Area</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/autorent-car-rental-in-dubai-ras-al-khor-uae">                 <center>

                <h6>Autorent Car Rental in Dubai Ras Al Khor, UAE</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/dollar-rent-a-car-car-rentals-in-ras-al-khor-industrial-area">                 <center>

                <h6>Dollar Rent A Car Car Rentals in Ras Al Khor Industrial Area</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-ras-al-khor-dubai">                 <center>

                <h6>Car rentals in Ras Al Khor Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-rental-deals-in-ras-al-khor-dubai">                 <center>

                <h6>Cheap Car Rental Deals in Ras Al Khor Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-hire-ras-al-khor-dubai">                 <center>

                <h6>Car Hire Ras Al Khor - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/best-luxury-car-rental-in-dubai-uae">                 <center>

                <h6>Best Luxury Car Rental in Dubai (UAE)</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-hire-in-zaabeel-dubai-from-77-day">                 <center>

                <h6>Cheap car hire in Za'abeel, Dubai from £77/day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rentals-in-zaabeel-dubai">                 <center>

                <h6>Car rentals in Za'abeel, Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-in-zaabeel-dubai">                 <center>

                <h6>Car Rental In Za'abeel - Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-zaabeel-dubai-cheap-car-hire">                 <center>

                <h6>Rent a Car in Za'abeel, Dubai, Cheap Car Hire</h6>
               
                </center></Link>














<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/dubai-festival-city-car-rental-hire-online">                 <center>

                <h6>Dubai Festival City Car Rental & Hire Online</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-festival-city">                 <center>

                <h6>Car Rental Dubai Festival City</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/21-car-rental-dubai-festival-city-mall">                 <center>

                <h6> $21 Car Rental Dubai Festival City Mall</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/long-term-car-rental-dubai-festival-city">                 <center>

                <h6>Long Term Car Rental Dubai Festival City</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/festival-city-rent-a-car-cheap-car-rentals">                 <center>

                <h6>Festival City Rent A Car | Cheap Car Rentals</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-festival-city-falcon-rides">                 <center>

                <h6>Rent A Car Dubai Festival City - Falcon Rides Car Rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-in-dubai-festival-city">                 <center>

                <h6>Car Rental in Dubai Festival City</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-festival-city">                 <center>

                <h6>Car rental in Dubai Festival City</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/81-car-rental-dubai-festival-city">                 <center>

                <h6> $81 Car Rental Dubai Festival City</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-festival-city-united-arab-emirates">                 <center>

                <h6>Car Rental Dubai Festival City - United Arab Emirates</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-festival-city">                 <center>

                <h6>Rent a Car in Dubai Festival City</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-festival-city-mall">                 <center>

                <h6>Rent a Car in Dubai Festival City Mall</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-rental-cars-dubai-festival-city-book-now">                 <center>

                <h6>Cheap Rental Cars Dubai Festival City Book Now!</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/affordable-car-rent-in-dubai-festival-city-with-great-dubai">                 <center>

                <h6>Affordable Car Rent in Dubai Festival City with Great Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-festival-city-online-reservation">                 <center>

                <h6>Rent a car Dubai Festival City - Online reservation</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/easy-economical-car-rental-festival-city-dubai">                 <center>

                <h6>Easy and economical car rental festival city Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-festival-city">                 <center>

                <h6>Rent a Car Dubai Festival City</h6>
               
                </center></Link>










<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-bur-dubai-cheap-car-rental-hire">                 <center>

                <h6>Rent a car Bur Dubai, Cheap Car Rental & Hire in Bur Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-bur-dubai-for-75-day">                 <center>

                <h6>Rent A Car Bur Dubai For $75/Day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-bur-dubai-cheap-rental">                 <center>

                <h6>Rent a Car Bur Dubai | Cheap Car Rental Bur Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/long-term-car-rentals-bur-dubai">                 <center>

                <h6>Long-Term Car Rentals Bur Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-hire-in-bur-dubai">                 <center>

                <h6>Car Hire in Bur Dubai (Dubai) - Search Hire Cars</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/affordable-rent-a-car-bur-dubai">                 <center>

                <h6>Rent A Car Bur Dubai | Affordable Car Rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/bur-dubai-rent-a-car-in-dubai">                 <center>

                <h6>Bur Dubai - Rent a Car in Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-cheap-rental-deals">                 <center>

                <h6>Car Rental Dubai – Search Cheap Rental Car Deals</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-rental-bur-dubai-royal-uae">                 <center>

                <h6>Cheap car rental Bur Dubai, rent a cars Royal: UAE</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-bur-dubai">                 <center>

                <h6>Rent a Car in Bur Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-bur-dubai-near-me">                 <center>

                <h6>Car rental in Bur Dubai, car rental near me on the map</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-bur-dubai-cheap">                 <center>

                <h6>Car Rental Bur Dubai - Cheap Rental Cars</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/weekly-car-hire-book-dubai">                 <center>

                <h6>Weekly Car Hire-book your car | Dubai Rental Deals - Call Now</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rental-cars-bur-dubai-uae-map">                 <center>

                <h6>Rental cars in Bur Dubai in UAE on the map</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-bur-dubai-best-experience">                 <center>

                <h6>Rent A Car Bur Dubai (Experience The Best Car)</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-rent-a-car-bur-dubai">                 <center>

                <h6>Cheap Rent a Car in Bur Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-dubai-monthly">                 <center>

                <h6>Rent a Car in Dubai, Cheapest Monthly Car Rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-bur-dubai-monthly">                 <center>

                <h6>Rent a Car in Bur Dubai, Cheapest Monthly Car Rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/low-cost-car-rental-deals-bur-dubai">                 <center>

                <h6>Low-cost Car Rental Deals in Bur Dubai, UAE 🔥</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/luxury-car-rental-bur-dubai">                 <center>

                <h6>Luxury Car Rental in Bur Dubai | Make My Ride</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-car-per-hour-dubai">                 <center>

                <h6>Rent a car per hour in Dubai | Hourly car rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-rent-a-car-bur-dubai-book-now">                 <center>

                <h6>Cheap Rent A Car In Bur Dubai - Cheapest As 150 Dhs Book</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/monthly-car-rental-bur-dubai">                 <center>

                <h6>Monthly Car Rental in Bur Dubai</h6>
               
                </center></Link>










<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-dubai-car-rental">                 <center>

                <h6>Car rental Dubai - Dubai car rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-companies-dubai">                 <center>

                <h6>Car Rental Companies Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box"

style={{cursor:"pointer"}} to="/fast-rent-a-car-dubai-150-aed-day">                 <center>

                <h6>Fast Rent a Car In Dubai | Car Rental Dubai AED 150/Day</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/leading-car-rental-dubai">                 <center>

                <h6>Dubai's Leading Car Rental | Rent a Car in Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/dxb-airport-terminal-1-car-rental">                 <center>

                <h6>Dubai Intl. Airport Terminal 1 (DXB) Car Rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/renting-a-car-ministry-of-economy-uae">                 <center>

                <h6>Renting a Car | Ministry of Economy - UAE</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/best-rent-a-car-dubai-economy-car-rental">                 <center>

                <h6>Best Rent A Car In Dubai | Economy Car Rental Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-in-dubai-hire-car-dubai">                 <center>

                <h6>Rent a Car in Dubai | Dubai Car Rental | Hire Car in Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-hire-dubai-cheap-rental-deals">                 <center>

                <h6>Car Hire in Dubai - Cheap Rental Deals</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/luxury-car-rental-with-driver-dubai">                 <center>

                <h6>Rent a Luxury Car with Driver - Car Rental Companies Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/legend-rent-a-car-dubai-agency">                 <center>

                <h6>Legend Rent a Car: Car Rental Agency Dubai Hire Auto Vehicle</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-united-arab-emirates">                 <center>

                <h6>Car Rental in Dubai United Arab Emirates</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-dubai-uae">                 <center>

                <h6>Car Rental Dubai - United Arab Emirates</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-leasing-in-dubai">                 <center>

                <h6>Car Leasing in Dubai | Best Car Rental</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-for-rent-dubai-low-cost">                 <center>

                <h6>Car For Rent Dubai | Low Cost Rent Car Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/luxury-car-rental-no-deposit">                 <center>

                <h6>Dubai Luxury Car Rental - No Deposit Required</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/premium-car-rental-self-drive-dubai">                 <center>

                <h6>Premium Car Rental Dubai - Rent a Car Self Drive Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/rent-a-car-agency-hire-car">                 <center>

                <h6>Rent a Car | Car Rental Agency | Hire Car</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/monthly-car-rental-dubai-1390-aed">                 <center>

                <h6>Monthly Car Rental Dubai from AED 1390</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/affordable-car-rental-dubai-low-cost-services">                 <center>

                <h6>Affordable Car Rental in Dubai | Low Cost Car Rental Services</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/affordable-car-rental-dubai-rent-car-uae">                 <center>

                <h6>Affordable car rental in Dubai - Rent a Car UAE</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/cheap-car-hire-dubai">                 <center>

                <h6>Your Cheap Car Hire in Dubai</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-uae-dubai-lease-cars-now">                 <center>

                <h6>#1 Car Rental in UAE, Dubai | Lease Our Cars Now</h6>
               
                </center></Link>
<Link  className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/luxury-car-rental-dubai-wow">                 <center>

                <h6>Luxury Car Rental in Dubai - Wow Rent a Car</h6>
               
                </center></Link> */}












{/* 

             
             
            </div>
          </div>
        </div>
      </section> */}








            <hr className="mt-2 mb-0" />
              </div>
              <br />



           
              
{/*           
<div class="container">
	                	<h2 class="title text-center mb-3">Our Offices</h2>

	                	<div class="row">
	                		<div class="col-lg-12">
	                			<div class="store">
	                				<div class="row">
	                					<div class="col-sm-5 col-xl-6">
	                						<figure class="store-media mb-2 mb-lg-0">
	                							<img src={logo} alt="image"/>
	                						</figure>
	                					</div>
	                					<div class="col-sm-7 col-xl-6">
	                						<div class="store-content">
	                							<h3 class="store-title">Falcon Rides Car Rental, Tours & transport, 
</h3>
	                							<address>     38 4 B St - Al Karama - Dubai - UAE
Dubai</address>
	                							<div>                           <a href="tel:+971563619373">+971 56 361 9373</a></div>
	                						
	                						

	                							<h4 class="store-subtitle">Office Open Hours:</h4>
                								<div>Monday - Sunday 24/7 Hours</div>

                								<a href="https://www.google.com/maps/dir//Chand+Tours+%26+Car+Rental+Agency+Lahore+DHA/@31.4751552,74.3199148,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x39190759509a2d3d:0xbe427f23fada2ed5!2m2!1d74.4023161!2d31.4751816?entry=ttu" class="btn btn-link" target="_blank"><span>View Map</span><i class="icon-long-arrow-right"></i></a>
	                						</div>
	                					</div>
	                				</div>
	                			</div>
	                		</div>
	                		</div>

             
</div>
              */}

        
      <Faq/>     
<br />
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
 
        <h2>Welcome to Falcon Rides Rent a Car!</h2>
        <p> Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models like the KIA , Hyundai and MG. At Falcon Rides, we're committed to delivering an unparalleled experience of comfort, convenience, and excellence.</p>
       <br /> <p> <h2>🌟 Our Mission</h2>
 Redefining the rental car experience through quality service and customer satisfaction. Whether it's a business trip or a family vacation, we ensure every journey is a memorable adventure.</p>
 <br /> <p><h2>🚗 Fleet Excellence</h2> Experience peak performance with our meticulously maintained fleet, featuring the efficiency of the Toyota Corolla anKIA , Hyundai the MG. Choose Falcon Rides for quality and precision.</p>
 <br /> <p><h2>🚗 Rental Solutions</h2> From short-term daily rentals to long-term leases, we provide customizable packages to suit your unique needs. With our commitment to versatility, you'll have the perfect vehicle for every occasion.</p>
 <br /> <p><h2>📅 Book Now</h2> Elevate your travel experience with Falcon Rides Rent a Car! Book with us today and experience top-tier transportation solutions.</p>
                 
            </div>
            </div>
            </div>



              
              {/* End .blog-posts */}
            </main>
            
            {/* End .footer */}
          </div>
          <button id="scroll-top" title="Back to Top">
            <i className="icon-arrow-up" />
          </button>
       
        </div>
        )
    
}

export default Home;