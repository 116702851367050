import React ,{useState,useEffect}from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Checkout2 from './Pages/checkout2';
import Categories from './Pages/Categories';
import Categories1 from './Pages/Categories1';
// import Thankyou from './Pages/Thankyou';
import Header from './components/Header';
import Footer from './components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';












import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import Error from './Pages/Error';
import CorporateEquiries from './Pages/CorporateEquiries';
import Trafficfines from './Pages/Trafficfines';
import testimonials from './Pages/testimonials';




import RentacarDubai from './Pages/rentacarDubai';
import rentacarBurDubai from './Pages/rentacarBur Dubai';
import RentacarDubaiDowntown from './Pages/rentacarDubai Downtown';
import rentacarDubaiHills from './Pages/rentacarDubai Hills';
import rentacarDubaiSiliconOasis from './Pages/rentacarDubaiSiliconOasis';
import rentacarKarama from './Pages/rentacarKarama';
import rentacarDeira from './Pages/rentacarDeira';
import rentacarDubaiFrame from './Pages/rentacarDubaiFrame';
import rentacarEmiratesHills from './Pages/rentacarEmiratesHills';
import rentacarJumeirahBeach from './Pages/rentacarJumeirahBeach';
import rentacarPalmJumeirah from './Pages/rentacarPalmJumeirah';
import rentacarBurjAlArab from './Pages/rentacarBurjAlArab';
import rentacarAlSatwa from './Pages/rentacarAlSatwa';
import rentacarBurjKhalifa from './Pages/rentacarBurjKhalifa';
import rentacarAlQuoz from './Pages/rentacarAlQuoz';
import rentacaralKarama from './Pages/rentacaralKarama';
import rentacarOneOnlyOneZaabeel from './Pages/rentacarOneOnlyOneZaabeel';
import rentacarDubai1 from './Pages/rentacar Dubai';
import rentacarUAE from './Pages/rentacarUAE';
import rentacarUAE1 from './Pages/rentacar UAE';
import rentacarAbuDhabi from './Pages/rentacarAbuDhabi';
import rentacarAjman from './Pages/rentacarAjman';
import rentacarDubaiFountain from './Pages/rentacarDubaiFountain';
import rentacarSkiDubai from './Pages/rentacarSkiDubai';
import rentacarDubaiMiracleGarden from './Pages/rentacarDubaiMiracleGarden';
import rentacarDesertSafari from './Pages/rentacarDesertSafari';
import rentacarSharjah from './Pages/rentacarSharjah';
import rentacarRasAlKhaimah from './Pages/rentacarRasAlKhaimah';
import rentacarUmmAlQuwain from './Pages/rentacarUmmAlQuwain';
import rentacarAlAin from './Pages/rentacarAlAin';
import rentacarFujairah from './Pages/rentacarFujairah';
import rentacarSheikhZayedRoad from './Pages/rentacarSheikh Zayed Road';
import rentacarDubaiMarina from './Pages/rentacarDubaiMarina';




import Rentacar1Dubai from './Pages/rentacar1Dubai';
import rentacar1BurDubai from './Pages/rentacar1Bur Dubai';
import Rentacar1DubaiDowntown from './Pages/rentacar1Dubai Downtown';
import rentacar1DubaiHills from './Pages/rentacar1Dubai Hills';
import rentacar1DubaiSiliconOasis from './Pages/rentacar1DubaiSiliconOasis';
import rentacar1Karama from './Pages/rentacar1Karama';
import rentacar1Deira from './Pages/rentacar1Deira';
import rentacar1DubaiFrame from './Pages/rentacar1DubaiFrame';
import rentacar1EmiratesHills from './Pages/rentacar1EmiratesHills';
import rentacar1JumeirahBeach from './Pages/rentacar1JumeirahBeach';
import rentacar1PalmJumeirah from './Pages/rentacar1PalmJumeirah';
import rentacar1BurjAlArab from './Pages/rentacar1BurjAlArab';
import rentacar1AlSatwa from './Pages/rentacar1AlSatwa';
import rentacar1BurjKhalifa from './Pages/rentacar1BurjKhalifa';
import rentacar1AlQuoz from './Pages/rentacar1AlQuoz';
import rentacar1alKarama from './Pages/rentacar1alKarama';
import rentacar1OneOnlyOneZaabeel from './Pages/rentacar1OneOnlyOneZaabeel';
import rentacar1Dubai1 from './Pages/rentacar1 Dubai';
import rentacar1UAE from './Pages/rentacar1UAE';
import rentacar1UAE1 from './Pages/rentacar1 UAE';
import rentacar1AbuDhabi from './Pages/rentacar1AbuDhabi';
import rentacar1Ajman from './Pages/rentacar1Ajman';
import rentacar1DubaiFountain from './Pages/rentacar1DubaiFountain';
import rentacar1SkiDubai from './Pages/rentacar1SkiDubai';
import rentacar1DubaiMiracleGarden from './Pages/rentacar1DubaiMiracleGarden';
import rentacar1DesertSafari from './Pages/rentacar1DesertSafari';
import rentacar1Sharjah from './Pages/rentacar1Sharjah';
import rentacar1RasAlKhaimah from './Pages/rentacar1RasAlKhaimah';
import rentacar1UmmAlQuwain from './Pages/rentacar1UmmAlQuwain';
import rentacar1AlAin from './Pages/rentacar1AlAin';
import rentacar1Fujairah from './Pages/rentacar1Fujairah';
import rentacar1SheikhZayedRoad from './Pages/rentacar1Sheikh Zayed Road';
import rentacar1DubaiMarina from './Pages/rentacar1DubaiMarina';






import Rentacar12Dubai from './Pages/rentacar1Dubai - Copy';
import rentacar12BurDubai from './Pages/rentacar1Bur Dubai - Copy';
import Rentacar12DubaiDowntown from './Pages/rentacar1Dubai Downtown - Copy';
import rentacar12DubaiHills from './Pages/rentacar1Dubai Hills - Copy';
import rentacar12DubaiSiliconOasis from './Pages/rentacar1DubaiSiliconOasis - Copy';
import rentacar12Karama from './Pages/rentacar1Karama - Copy';
import rentacar12Deira from './Pages/rentacar1Deira - Copy';
import rentacar12DubaiFrame from './Pages/rentacar1DubaiFrame - Copy';
import rentacar12EmiratesHills from './Pages/rentacar1EmiratesHills - Copy';
import rentacar12JumeirahBeach from './Pages/rentacar1JumeirahBeach - Copy';
import rentacar12PalmJumeirah from './Pages/rentacar1PalmJumeirah - Copy';
import rentacar12BurjAlArab from './Pages/rentacar1BurjAlArab - Copy';
import rentacar12AlSatwa from './Pages/rentacar1AlSatwa - Copy';
import rentacar12BurjKhalifa from './Pages/rentacar1BurjKhalifa - Copy';
import rentacar12AlQuoz from './Pages/rentacar1AlQuoz - Copy';
import rentacar12alKarama from './Pages/rentacar1alKarama - Copy';
import rentacar12OneOnlyOneZaabeel from './Pages/rentacar1OneOnlyOneZaabeel - Copy';
// import rentacar12Dubai1 from './Pages/rentacar1 Dubai - Copy';
import rentacar12UAE from './Pages/rentacar1UAE - Copy';
// import rentacar12UAE1 from './Pages/rentacar1 UAE - Copy';
import rentacar12AbuDhabi from './Pages/rentacar1AbuDhabi - Copy';
import rentacar12Ajman from './Pages/rentacar1Ajman - Copy';
import rentacar12DubaiFountain from './Pages/rentacar1DubaiFountain - Copy';
import rentacar12SkiDubai from './Pages/rentacar1SkiDubai - Copy';
import rentacar12DubaiMiracleGarden from './Pages/rentacar1DubaiMiracleGarden - Copy';
import rentacar12DesertSafari from './Pages/rentacar1DesertSafari - Copy';
import rentacar12Sharjah from './Pages/rentacar1Sharjah - Copy';
import rentacar12RasAlKhaimah from './Pages/rentacar1RasAlKhaimah - Copy';
import rentacar12UmmAlQuwain from './Pages/rentacar1UmmAlQuwain - Copy';
import rentacar12AlAin from './Pages/rentacar1AlAin - Copy';
import rentacar12Fujairah from './Pages/rentacar1Fujairah - Copy';
import rentacar12SheikhZayedRoad from './Pages/rentacar1Sheikh Zayed Road - Copy';
import rentacar12DubaiMarina from './Pages/rentacar1DubaiMarina - Copy';


import rent0 from './Pages/rent1';
import rent1 from './Pages/rent1  (1)';
import rent2 from './Pages/rent1  (2)';
import rent3 from './Pages/rent1  (3)';
import rent4 from './Pages/rent1  (4)';
import rent5 from './Pages/rent1  (5)';
import rent6 from './Pages/rent1  (6)';
import rent7 from './Pages/rent1  (7)';
import rent8 from './Pages/rent1  (8)';
import rent9 from './Pages/rent1  (9)';
import rent10 from './Pages/rent1  (10)';
import rent11 from './Pages/rent1  (11)';
import rent12 from './Pages/rent1  (12)';
import rent13 from './Pages/rent1  (13)';
import rent14 from './Pages/rent1  (14)';
import rent15 from './Pages/rent1  (15)';
import rent16 from './Pages/rent1  (16)';
import rent17 from './Pages/rent1  (17)';
import rent18 from './Pages/rent1  (18)';
import rent19 from './Pages/rent1  (19)';
import rent20 from './Pages/rent1  (20)';
import rent21 from './Pages/rent1  (21)';
import rent22 from './Pages/rent1  (22)';
import rent23 from './Pages/rent1  (23)';
import rent24 from './Pages/rent1  (24)';
import rent25 from './Pages/rent1  (25)';
import rent26 from './Pages/rent1  (26)';
import rent27 from './Pages/rent1  (27)';
import rent28 from './Pages/rent1  (28)';
import rent29 from './Pages/rent1  (29)';
import rent30 from './Pages/rent1  (30)';
import rent31 from './Pages/rent1  (31)';
import rent32 from './Pages/rent1  (32)';
import rent33 from './Pages/rent1  (33)';
import rent34 from './Pages/rent1  (34)';
import rent35 from './Pages/rent1  (35)';
import rent36 from './Pages/rent1  (36)';
import rent37 from './Pages/rent1  (37)';
import rent38 from './Pages/rent1  (38)';










import rentcar1 from './Pages/rentcar (131)';
import rentcar2 from './Pages/rentcar (132)';
import rentcar3 from './Pages/rentcar (133)';
import rentcar4 from './Pages/rentcar (134)';
import rentcar5 from './Pages/rentcar (135)';
import rentcar6 from './Pages/rentcar (136)';
import rentcar7 from './Pages/rentcar (137)';
import rentcar8 from './Pages/rentcar (138)';
import rentcar9 from './Pages/rentcar (139)';
import rentcar10 from './Pages/rentcar (140)';
import rentcar11 from './Pages/rentcar (141)';
import rentcar12 from './Pages/rentcar (142)';
import rentcar13 from './Pages/rentcar (143)';
import rentcar14 from './Pages/rentcar (144)';
import rentcar15 from './Pages/rentcar (145)';
import rentcar16 from './Pages/rentcar (146)';
import rentcar17 from './Pages/rentcar (147)';
import rentcar18 from './Pages/rentcar (148)';
import rentcar19 from './Pages/rentcar (149)';
import rentcar20 from './Pages/rentcar (150)';
import rentcar21 from './Pages/rentcar (151)';
import rentcar22 from './Pages/rentcar (152)';
import rentcar23 from './Pages/rentcar (153)';
import rentcar24 from './Pages/rentcar (154)';


import rentcar25 from './Pages/rentcar (108)';
import rentcar26 from './Pages/rentcar (109)';
import rentcar27 from './Pages/rentcar (110)';
import rentcar28 from './Pages/rentcar (111)';
import rentcar29 from './Pages/rentcar (112)';
import rentcar30 from './Pages/rentcar (113)';
import rentcar31 from './Pages/rentcar (114)';
import rentcar32 from './Pages/rentcar (115)';
import rentcar33 from './Pages/rentcar (116)';
import rentcar34 from './Pages/rentcar (117)';
import rentcar35 from './Pages/rentcar (118)';
import rentcar36 from './Pages/rentcar (119)';
import rentcar37 from './Pages/rentcar (120)';
import rentcar38 from './Pages/rentcar (121)';
import rentcar39 from './Pages/rentcar (122)';
import rentcar40 from './Pages/rentcar (123)';
import rentcar41 from './Pages/rentcar (124)';
import rentcar42 from './Pages/rentcar (125)';
import rentcar43 from './Pages/rentcar (126)';
import rentcar44 from './Pages/rentcar (127)';
import rentcar45 from './Pages/rentcar (128)';
import rentcar46 from './Pages/rentcar (129)';
import rentcar47 from './Pages/rentcar (130)';



import rentcar48 from './Pages/rentcar (91)';
import rentcar49 from './Pages/rentcar (92)';
import rentcar50 from './Pages/rentcar (93)';
import rentcar51 from './Pages/rentcar (94)';
import rentcar52 from './Pages/rentcar (95)';
import rentcar53 from './Pages/rentcar (96)';
import rentcar54 from './Pages/rentcar (97)';
import rentcar55 from './Pages/rentcar (98)';
import rentcar56 from './Pages/rentcar (99)';
import rentcar57 from './Pages/rentcar (100)';
import rentcar58 from './Pages/rentcar (101)';
import rentcar59 from './Pages/rentcar (102)';
import rentcar60 from './Pages/rentcar (103)';
import rentcar61 from './Pages/rentcar (104)';
import rentcar62 from './Pages/rentcar (105)';
import rentcar63 from './Pages/rentcar (106)';
import rentcar64 from './Pages/rentcar (107)';





import rentcar65 from './Pages/rentcar (57)';
import rentcar66 from './Pages/rentcar (58)';
import rentcar67 from './Pages/rentcar (59)';
import rentcar68 from './Pages/rentcar (60)';
import rentcar69 from './Pages/rentcar (61)';
import rentcar70 from './Pages/rentcar (62)';
import rentcar71 from './Pages/rentcar (63)';
import rentcar72 from './Pages/rentcar (64)';
import rentcar73 from './Pages/rentcar (65)';
import rentcar74 from './Pages/rentcar (66)';
import rentcar75 from './Pages/rentcar (67)';
import rentcar76 from './Pages/rentcar (68)';
import rentcar77 from './Pages/rentcar (69)';
import rentcar78 from './Pages/rentcar (70)';
import rentcar79 from './Pages/rentcar (71)';
import rentcar80 from './Pages/rentcar (72)';
import rentcar81 from './Pages/rentcar (73)';
import rentcar82 from './Pages/rentcar (74)';
import rentcar83 from './Pages/rentcar (75)';
import rentcar84 from './Pages/rentcar (76)';
import rentcar85 from './Pages/rentcar (77)';
import rentcar86 from './Pages/rentcar (78)';
import rentcar87 from './Pages/rentcar (79)';
import rentcar88 from './Pages/rentcar (80)';
import rentcar89 from './Pages/rentcar (81)';
import rentcar90 from './Pages/rentcar (82)';
import rentcar91 from './Pages/rentcar (83)';
import rentcar92 from './Pages/rentcar (84)';
import rentcar93 from './Pages/rentcar (85)';
import rentcar94 from './Pages/rentcar (86)';
import rentcar95 from './Pages/rentcar (87)';
import rentcar96 from './Pages/rentcar (88)';
import rentcar97 from './Pages/rentcar (89)';
import rentcar98 from './Pages/rentcar (90)';
import rentcar99 from './Pages/rentcar (48)';




import rentcar100 from './Pages/rentcar (31)';
import rentcar101 from './Pages/rentcar (32)';
import rentcar102 from './Pages/rentcar (33)';
import rentcar103 from './Pages/rentcar (34)';
import rentcar104 from './Pages/rentcar (35)';
import rentcar105 from './Pages/rentcar (36)';
import rentcar106 from './Pages/rentcar (37)';
import rentcar107 from './Pages/rentcar (38)';
import rentcar108 from './Pages/rentcar (39)';
import rentcar109 from './Pages/rentcar (40)';
import rentcar110 from './Pages/rentcar (41)';
import rentcar111 from './Pages/rentcar (42)';
import rentcar112 from './Pages/rentcar (43)';
import rentcar113 from './Pages/rentcar (44)';
import rentcar114 from './Pages/rentcar (45)';
import rentcar115 from './Pages/rentcar (46)';
import rentcar116 from './Pages/rentcar (47)';
import rentcar117 from './Pages/rentcar (49)';
import rentcar118 from './Pages/rentcar (50)';
import rentcar119 from './Pages/rentcar (51)';
import rentcar120 from './Pages/rentcar (52)';
import rentcar121 from './Pages/rentcar (53)';
import rentcar122 from './Pages/rentcar (54)';
import rentcar123 from './Pages/rentcar (55)';
import rentcar124 from './Pages/rentcar (56)';




import rentcar125 from './Pages/rentcar (13)';
import rentcar126 from './Pages/rentcar (14)';
import rentcar127 from './Pages/rentcar (15)';
import rentcar128 from './Pages/rentcar (16)';
import rentcar129 from './Pages/rentcar (17)';
import rentcar130 from './Pages/rentcar (18)';
import rentcar131 from './Pages/rentcar (19)';
import rentcar132 from './Pages/rentcar (20)';
import rentcar133 from './Pages/rentcar (21)';
import rentcar134 from './Pages/rentcar (22)';
import rentcar135 from './Pages/rentcar (23)';
import rentcar136 from './Pages/rentcar (24)';
import rentcar137 from './Pages/rentcar (25)';
import rentcar138 from './Pages/rentcar (26)';
import rentcar139 from './Pages/rentcar (27)';
import rentcar140 from './Pages/rentcar (28)';
import rentcar141 from './Pages/rentcar (29)';
import rentcar142 from './Pages/rentcar (30)';


import rentcar143 from './Pages/rentcar (1)';
import rentcar144 from './Pages/rentcar (2)';
import rentcar145 from './Pages/rentcar (3)';
import rentcar146 from './Pages/rentcar (4)';
import rentcar147 from './Pages/rentcar (5)';
import rentcar148 from './Pages/rentcar (6)';
import rentcar149 from './Pages/rentcar (7)';
import rentcar150 from './Pages/rentcar (8)';
import rentcar151 from './Pages/rentcar (9)';
import rentcar152 from './Pages/rentcar (10)';
import rentcar153 from './Pages/rentcar (11)';
import rentcar154 from './Pages/rentcar (12)';



















// import rentcar1 from './Pages/rentcar (1)';
// import rentcar2 from './Pages/rentcar (2)';
// import rentcar3 from './Pages/rentcar (3)';
// import rentcar4 from './Pages/rentcar (4)';
// import rentcar5 from './Pages/rentcar (5)';
// import rentcar6 from './Pages/rentcar (6)';
// import rentcar7 from './Pages/rentcar (7)';
// import rentcar8 from './Pages/rentcar (8)';
// import rentcar9 from './Pages/rentcar (9)';
// import rentcar10 from './Pages/rentcar (10)';
// import rentcar11 from './Pages/rentcar (11)';
// import rentcar12 from './Pages/rentcar (12)';
// import rentcar13 from './Pages/rentcar (13)';
// import rentcar14 from './Pages/rentcar (14)';
// import rentcar15 from './Pages/rentcar (15)';
// import rentcar16 from './Pages/rentcar (16)';
// import rentcar17 from './Pages/rentcar (17)';
// import rentcar18 from './Pages/rentcar (18)';
// import rentcar19 from './Pages/rentcar (19)';
// import rentcar20 from './Pages/rentcar (20)';
// import rentcar21 from './Pages/rentcar (21)';
// import rentcar22 from './Pages/rentcar (22)';
// import rentcar23 from './Pages/rentcar (23)';
// import rentcar24 from './Pages/rentcar (24)';
// import rentcar25 from './Pages/rentcar (25)';
// import rentcar26 from './Pages/rentcar (26)';
// import rentcar27 from './Pages/rentcar (27)';
// import rentcar28 from './Pages/rentcar (28)';
// import rentcar29 from './Pages/rentcar (29)';
// import rentcar30 from './Pages/rentcar (30)';
// import rentcar31 from './Pages/rentcar (31)';
// import rentcar32 from './Pages/rentcar (32)';
// import rentcar33 from './Pages/rentcar (33)';
// import rentcar34 from './Pages/rentcar (34)';
// import rentcar35 from './Pages/rentcar (35)';
// import rentcar36 from './Pages/rentcar (36)';
// import rentcar37 from './Pages/rentcar (37)';
// import rentcar38 from './Pages/rentcar (38)';
// import rentcar39 from './Pages/rentcar (39)';
// import rentcar40 from './Pages/rentcar (40)';
// import rentcar41 from './Pages/rentcar (41)';
// import rentcar42 from './Pages/rentcar (42)';
// import rentcar43 from './Pages/rentcar (43)';
// import rentcar44 from './Pages/rentcar (44)';
// import rentcar45 from './Pages/rentcar (45)';
// import rentcar46 from './Pages/rentcar (46)';
// import rentcar47 from './Pages/rentcar (47)';
// import rentcar48 from './Pages/rentcar (48)';
// import rentcar49 from './Pages/rentcar (49)';
// import rentcar50 from './Pages/rentcar (50)';
// import rentcar51 from './Pages/rentcar (51)';
// import rentcar52 from './Pages/rentcar (52)';
// import rentcar53 from './Pages/rentcar (53)';
// import rentcar54 from './Pages/rentcar (54)';
// import rentcar55 from './Pages/rentcar (55)';
// import rentcar56 from './Pages/rentcar (56)';
// import rentcar57 from './Pages/rentcar (57)';
// import rentcar58 from './Pages/rentcar (58)';
// import rentcar59 from './Pages/rentcar (59)';
// import rentcar60 from './Pages/rentcar (60)';
// import rentcar61 from './Pages/rentcar (61)';
// import rentcar62 from './Pages/rentcar (62)';
// import rentcar63 from './Pages/rentcar (63)';
// import rentcar64 from './Pages/rentcar (64)';
// import rentcar65 from './Pages/rentcar (65)';
// import rentcar66 from './Pages/rentcar (66)';
// import rentcar67 from './Pages/rentcar (67)';
// import rentcar68 from './Pages/rentcar (68)';
// import rentcar69 from './Pages/rentcar (69)';
// import rentcar70 from './Pages/rentcar (70)';
// import rentcar71 from './Pages/rentcar (71)';
// import rentcar72 from './Pages/rentcar (72)';
// import rentcar73 from './Pages/rentcar (73)';
// import rentcar74 from './Pages/rentcar (74)';
// import rentcar75 from './Pages/rentcar (75)';
// import rentcar76 from './Pages/rentcar (76)';
// import rentcar77 from './Pages/rentcar (77)';
// import rentcar78 from './Pages/rentcar (78)';
// import rentcar79 from './Pages/rentcar (79)';
// import rentcar80 from './Pages/rentcar (80)';
// import rentcar81 from './Pages/rentcar (81)';
// import rentcar82 from './Pages/rentcar (82)';
// import rentcar83 from './Pages/rentcar (83)';
// import rentcar84 from './Pages/rentcar (84)';
// import rentcar85 from './Pages/rentcar (85)';
// import rentcar86 from './Pages/rentcar (86)';
// import rentcar87 from './Pages/rentcar (87)';
// import rentcar88 from './Pages/rentcar (88)';
// import rentcar89 from './Pages/rentcar (89)';
// import rentcar90 from './Pages/rentcar (90)';
// import rentcar91 from './Pages/rentcar (91)';
// import rentcar92 from './Pages/rentcar (92)';
// import rentcar93 from './Pages/rentcar (93)';
// import rentcar94 from './Pages/rentcar (94)';
// import rentcar95 from './Pages/rentcar (95)';
// import rentcar96 from './Pages/rentcar (96)';
// import rentcar97 from './Pages/rentcar (97)';
// import rentcar98 from './Pages/rentcar (98)';
// import rentcar99 from './Pages/rentcar (99)';
// import rentcar100 from './Pages/rentcar (100)';
// import rentcar101 from './Pages/rentcar (101)';
// import rentcar102 from './Pages/rentcar (102)';
// import rentcar103 from './Pages/rentcar (103)';
// import rentcar104 from './Pages/rentcar (104)';
// import rentcar105 from './Pages/rentcar (105)';
// import rentcar106 from './Pages/rentcar (106)';
// import rentcar107 from './Pages/rentcar (107)';
// import rentcar108 from './Pages/rentcar (108)';
// import rentcar109 from './Pages/rentcar (109)';
// import rentcar110 from './Pages/rentcar (110)';
// import rentcar111 from './Pages/rentcar (111)';
// import rentcar112 from './Pages/rentcar (112)';
// import rentcar113 from './Pages/rentcar (113)';
// import rentcar114 from './Pages/rentcar (114)';
// import rentcar115 from './Pages/rentcar (115)';
// import rentcar116 from './Pages/rentcar (116)';
// import rentcar117 from './Pages/rentcar (117)';
// import rentcar118 from './Pages/rentcar (118)';
// import rentcar119 from './Pages/rentcar (119)';
// import rentcar120 from './Pages/rentcar (120)';
// import rentcar121 from './Pages/rentcar (121)';
// import rentcar122 from './Pages/rentcar (122)';
// import rentcar123 from './Pages/rentcar (123)';
// import rentcar124 from './Pages/rentcar (124)';
// import rentcar125 from './Pages/rentcar (125)';
// import rentcar126 from './Pages/rentcar (126)';
// import rentcar127 from './Pages/rentcar (127)';
// import rentcar128 from './Pages/rentcar (128)';
// import rentcar129 from './Pages/rentcar (129)';
// import rentcar130 from './Pages/rentcar (130)';
// import rentcar131 from './Pages/rentcar (131)';
// import rentcar132 from './Pages/rentcar (132)';
// import rentcar133 from './Pages/rentcar (133)';
// import rentcar134 from './Pages/rentcar (134)';
// import rentcar135 from './Pages/rentcar (135)';
// import rentcar136 from './Pages/rentcar (136)';
// import rentcar137 from './Pages/rentcar (137)';
// import rentcar138 from './Pages/rentcar (138)';
// import rentcar139 from './Pages/rentcar (139)';
// import rentcar140 from './Pages/rentcar (140)';
// import rentcar141 from './Pages/rentcar (141)';
// import rentcar142 from './Pages/rentcar (142)';
// import rentcar143 from './Pages/rentcar (143)';
// import rentcar144 from './Pages/rentcar (144)';
// import rentcar145 from './Pages/rentcar (145)';
// import rentcar146 from './Pages/rentcar (146)';
// import rentcar147 from './Pages/rentcar (147)';
// import rentcar148 from './Pages/rentcar (148)';
// import rentcar149 from './Pages/rentcar (149)';
// import rentcar150 from './Pages/rentcar (150)';
// import rentcar151 from './Pages/rentcar (151)';
// import rentcar152 from './Pages/rentcar (152)';
// import rentcar153 from './Pages/rentcar (153)';
// import rentcar154 from './Pages/rentcar (154)';



import Text from './Pages/text';
import Redirect from './Pages/redirect';
import Montly from './Pages/montly';
import Weekly from './Pages/weekly';
// import submain from './Pages/submain';

// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';


// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';


// import $ from 'jquery';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
import "./all.css"
import "./custom.css"
import "./bootsnav.css"
import "./responsive.css"
import "./style1.css"
import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

import "./css1/custom.css";
import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
import "./css1/all.css";
import "./css1/style.css";

import "./css1/bootsnav";

import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset1/css/plugins/jquery.countdown.css";
import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";


import Navbar from "../src/components/Navbar";
import "../src/dist/styles.css";
import "./style6.css"

// import Footer from "../components/Footer";

import whatapps1 from './assets/images/png.png';
import car1 from './assets/images/car1.png';
import car2 from './assets/images/car2.png';
import car3 from './assets/images/car3.png';


function App() {
  
  const [showPopup, setShowPopup] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photo, setphoto] = useState([car1,car2,car3]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % 3);
    }, 2000); // 3 seconds

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex,photo]);


  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="App" >  
         <a href="tel:+971563619373" target="_blank"><img src={whatapps1} alt="" style={{position : "fixed" ,zIndex : "999999", top : "75%" , right : "15px",width:"50px",height : "50px"}}/></a>
                  
     <a href="https://wa.me/971563619373?text=Hi *Falcon Rides*! I need more info about Car Rent in Dubai https://falcon-rides.com" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "67%" , right : "10px",width:"60px",height : "60px"}}/></a>





     {/* <button onClick={()=>handleShowPopup()}>Show Popup</button>
      // {showPopup && ( */}
        {/* // <Popup imageUrl={imageUrl} onClose={handleClosePopup} /> */}

{/* {showPopup ?
<a href="tel:+971563619373">
        <div className="popup-container">
      <div className="popup-content">
        <img src={photo[currentIndex]} alt="Popup Image" style={{margin:"20px auto 0px auto"}}/>
        <span className="close-icon" onClick={()=>handleClosePopup(!showPopup)}>&times;</span>
      </div>
    </div>

</a>
:""

} */}



      {/* )} */}








      <Router>

        {/* <Header /> */}
        <Navbar />
        
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Shipping' component={Shipping} />
          <Route exact path='/Corporate-Equiries' component={CorporateEquiries} />
          <Route exact path='/Text' component={Text} />
          <Route exact path='/redirect' component={Redirect} />
          <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} />

          <Route exact path='/carrental-dubai' component={rentacarDubai1} />
          <Route exact path='/car-rental-UAE' component={rentacarUAE1} />
          <Route exact path='/car-rental-service-in-UAE' component={rentacarUAE} />


          <Route exact path='/car-rental-service-in-dubai-frame' component={rentacarDubaiFrame} />
          <Route exact path='/car-rental-service-in-emirates-hills' component={rentacarEmiratesHills} />
          <Route exact path='/car-rental-service-in-jumeirah-beach' component={rentacarJumeirahBeach} />
          <Route exact path='/car-rental-service-in-palm-jumeirah' component={rentacarPalmJumeirah} />
          <Route exact path='/car-rental-service-in-burj-al-arab' component={rentacarBurjAlArab} />
          <Route exact path='/car-rental-service-in-al-Satwa' component={rentacarAlSatwa} />
          <Route exact path='/car-rental-service-in-burj-khalifa' component={rentacarBurjKhalifa} />
          <Route exact path='/car-rental-service-in-al-quoz' component={rentacarAlQuoz} />
          <Route exact path='/car-rental-service-in-al-karama' component={rentacaralKarama} />
          <Route exact path='/car-rental-service-in-dubai-marina' component={rentacarDubaiMarina} />
          <Route exact path='/car-rental-service-in-dubai' component={RentacarDubai} />
          <Route exact path='/car-rental-service-in-bur-dubai' component={rentacarBurDubai} />
          <Route exact path='/car-rental-service-in-dubai-downtown' component={RentacarDubaiDowntown} />
          <Route exact path='/car-rental-service-in-dubai-hills' component={rentacarDubaiHills} />
          <Route exact path='/car-rental-service-in-karama' component={rentacarKarama} />
          <Route exact path='/car-rental-service-in-sheikh-zayed-road' component={rentacarSheikhZayedRoad} />
          <Route exact path='/car-rental-service-in-dubai-silicon-oasis' component={rentacarDubaiSiliconOasis} />
          <Route exact path='/car-rental-service-in-Deira' component={rentacarDeira} />
          <Route exact path='/car-rental-service-in-Abu-Dhabi' component={rentacarAbuDhabi} />
          <Route exact path='/car-rental-service-in-Ajman' component={rentacarAjman} />
          <Route exact path='/car-rental-service-in-Dubai-Fountain' component={rentacarDubaiFountain} />
          <Route exact path='/car-rental-service-in-Ski-Dubai' component={rentacarSkiDubai} />
          <Route exact path='/car-rental-service-in-Dubai-Miracle-Garden' component={rentacarDubaiMiracleGarden} />
          <Route exact path='/car-rental-service-in-Desert-Safari' component={rentacarDesertSafari} />
          <Route exact path='/car-rental-service-in-Sharjah' component={rentacarSharjah} />
          <Route exact path='/car-rental-service-in-Ras-Al-Khaimah' component={rentacarRasAlKhaimah} />
          <Route exact path='/car-rental-service-in-Umm-Al-Quwain' component={rentacarUmmAlQuwain} />
          <Route exact path='/car-rental-service-in-Fujairah' component={rentacarFujairah} />
          <Route exact path='/car-rental-service-in-Al-Ain' component={rentacarAlAin} />
          <Route exact path="/car-rental-service-in-One-&-Only-One-Za'abeel" component={rentacarOneOnlyOneZaabeel} />
        
          <Route exact path='/rentacar-dubai' component={rentacar1Dubai1} />
          <Route exact path='/rent-a-car-in-UAE' component={rentacar1UAE1} />
          <Route exact path='/rent-a-car-service-in-UAE' component={rentacar1UAE} />


          <Route exact path='/rent-a-car-service-in-dubai-frame' component={rentacar1DubaiFrame} />
          <Route exact path='/rent-a-car-service-in-emirates-hills' component={rentacar1EmiratesHills} />
          <Route exact path='/rent-a-car-service-in-jumeirah-beach' component={rentacar1JumeirahBeach} />
          <Route exact path='/rent-a-car-service-in-palm-jumeirah' component={rentacar1PalmJumeirah} />
          <Route exact path='/rent-a-car-service-in-burj-al-arab' component={rentacar1BurjAlArab} />
          <Route exact path='/rent-a-car-service-in-al-Satwa' component={rentacar1AlSatwa} />
          <Route exact path='/rent-a-car-service-in-burj-khalifa' component={rentacar1BurjKhalifa} />
          <Route exact path='/rent-a-car-service-in-al-quoz' component={rentacar1AlQuoz} />
          <Route exact path='/rent-a-car-service-in-al-karama' component={rentacar1alKarama} />
          <Route exact path='/rent-a-car-service-in-dubai-marina' component={rentacar1DubaiMarina} />
          <Route exact path='/rent-a-car-service-in-dubai' component={Rentacar1Dubai} />
          <Route exact path='/rent-a-car-service-in-bur-dubai' component={rentacar1BurDubai} />
          <Route exact path='/rent-a-car-service-in-dubai-downtown' component={Rentacar1DubaiDowntown} />
          <Route exact path='/rent-a-car-service-in-dubai-hills' component={rentacar1DubaiHills} />
          <Route exact path='/rent-a-car-service-in-karama' component={rentacar1Karama} />
          <Route exact path='/rent-a-car-service-in-sheikh-zayed-road' component={rentacar1SheikhZayedRoad} />
          <Route exact path='/rent-a-car-service-in-dubai-silicon-oasis' component={rentacar1DubaiSiliconOasis} />
          <Route exact path='/rent-a-car-service-in-Deira' component={rentacar1Deira} />
          <Route exact path='/rent-a-car-service-in-Abu-Dhabi' component={rentacar1AbuDhabi} />
          <Route exact path='/rent-a-car-service-in-Ajman' component={rentacar1Ajman} />
          <Route exact path='/rent-a-car-service-in-Dubai-Fountain' component={rentacar1DubaiFountain} />
          <Route exact path='/rent-a-car-service-in-Ski-Dubai' component={rentacar1SkiDubai} />
          <Route exact path='/rent-a-car-service-in-Dubai-Miracle-Garden' component={rentacar1DubaiMiracleGarden} />
          <Route exact path='/rent-a-car-service-in-Desert-Safari' component={rentacar1DesertSafari} />
          <Route exact path='/rent-a-car-service-in-Sharjah' component={rentacar1Sharjah} />
          <Route exact path='/rent-a-car-service-in-Ras-Al-Khaimah' component={rentacar1RasAlKhaimah} />
          <Route exact path='/rent-a-car-service-in-Umm-Al-Quwain' component={rentacar1UmmAlQuwain} />
          <Route exact path='/rent-a-car-service-in-Fujairah' component={rentacar1Fujairah} />
          <Route exact path='/rent-a-car-service-in-Al-Ain' component={rentacar1AlAin} />
          <Route exact path="/rent-a-car-service-in-One-&-Only-One-Za'abeel" component={rentacar1OneOnlyOneZaabeel} />
        


          <Route exact path="/rent-a-car-in-dubai-online-at-affordable-prices" component={rent0} />
          <Route exact path="/rent-a-car-dubai-economy-and-luxury-car-rental-uae" component={rent1} />
          <Route exact path="/self-drive-cars-in-dubai-car-rental-services" component={rent2} />
          <Route exact path="/dubai-international-airport-term-1-car-rental" component={rent3} />
          <Route exact path="/rent-a-car-cheap-car-rental-car-hire-in-dubai-uae" component={rent4} />
          <Route exact path="/car-rental-dubai-search-cheap-rental-car-deals" component={rent5} />
          <Route exact path="/cheap-car-rental-in-dubai-find-and-compare-deals" component={rent6} />
          <Route exact path="/weekly-car-rental-in-dubai-rent-a-car-with-no-hidden-charges" component={rent7} />
          <Route exact path="/car-rental-rent-a-car-dubai-at-lowest-price" component={rent8} />
          <Route exact path="/rent-a-car-in-dubai-car-rental-agency-in-uae" component={rent9} />
          <Route exact path="/rent-a-car-in-dubai-car-rental-dubai" component={rent10} />
          <Route exact path="/rent-a-car-dubai-best-car-rental-offers-monthly-and-daily" component={rent11} />
          <Route exact path="/rent-a-car-al-karama-cheap-car-rental-hire-in-dubai" component={rent12} />
          <Route exact path="/rent-a-car-karama-at-cheapest-rates-falcon-rides-car-rental-dubai" component={rent13} />
          <Route exact path="/affordable-car-rental-al-karama-dubai" component={rent14} />













          <Route exact path="/budget-friendly-car-rental-in-karama-by-quick-lease" component={rent15} />
          <Route exact path="/our-car-rental-fleet-per-day-aed-100-per-week-aed-1000-per-month-aed-1536" component={rent16} />
          <Route exact path="/rent-a-car-karama-hire-quality-cars-at-cost-effective" component={rent17} />
          <Route exact path="/car-rental-in-al-karama-hire-cheap-car-in-al-karama" component={rent18} />
          <Route exact path="/rent-a-car-in-karama-in-uae-on-the-map-celia-car-rental" component={rent19} />
          <Route exact path="/rent-a-car-in-al-karama-with-great-rent-a-car" component={rent20} />
          <Route exact path="/rent-a-car-in-al-karama-dubai-cheap-car-hire-dubai" component={rent21} />
          <Route exact path="/cheap-car-rental-al-karama-rent-a-cars-royal-dubai-uae" component={rent22} />
          <Route exact path="/car-rental-karama-dubai-united-arab-emirates" component={rent23} />
          <Route exact path="/rent-a-car-karama-dubai-daily-and-long-term-car-hire-al-karama" component={rent24} />
          <Route exact path="/luxury-car-rental-al-karama-rent-a-car-royal-dubai-uae" component={rent25} />
          <Route exact path="/rent-a-car-in-al-karama-dubai-best-deals-and-offers" component={rent26} />
          <Route exact path="/rent-a-car-in-al-karama-dubai-affordable-reliable" component={rent27} />

          <Route exact path='/do-you-reside-in-al-karama-and-wish-to-hire-a-car-to-make-your-travels-easier?' component={rent28} />
          <Route exact path='/best-deals-on-car-rentals-in-al-karama-dubai-daily-weekly-and-monthly-rates-available-book-now!' component={rent29} />
          <Route exact path='/helps-you-to-find-perfect-car-for-your-next-ride-with-rent-a-car-dubai-in-al-karama' component={rent30} />
          <Route exact path='/rent-a-car-at-cheap-price-in-al-karama' component={rent31} />
          <Route exact path='/rent-a-car-has-a-unique-list-of-cars-available-for-rent-in-al-karama-dubai!' component={rent32} />
          <Route exact path='/cheap-car-rental-in-al-karama-dubai-uae-price-from-aed-90-per-day' component={rent33} />
          <Route exact path='/find-cheap-car-rental-deals-for-karama-at-falcon-rides-com' component={rent34} />
          <Route exact path='/looking-for-a-car-rental-company-in-karama-dubai' component={rent35} />
          <Route exact path='/rent-a-car-in-al-karama-dubai-discover-the-vibrant-neighborhood-of-al-karama-with-our-affordable-car-rental-options' component={rent36} />
          <Route exact path='/the-cost-of-renting-a-car-in-al-karama-varies-depending-on-the-type-of-vehicle-you-choose-and-the-rental-duration' component={rent37} />
          <Route exact path='/renting-a-car-in-al-karama-with-car-rental-dbx-is-not-only-convenient-but-also-remarkably-affordable' component={rent38} />

       






        
          <Route exact path='/rent-a-car-UAE' component={rentacar12UAE} />
          <Route exact path='/rent-a-car-dubai' component={Rentacar12Dubai} />
          <Route exact path='/rent-a-car-dubai-frame' component={rentacar12DubaiFrame} />
          <Route exact path='/rent-a-car-emirates-hills' component={rentacar12EmiratesHills} />
          <Route exact path='/rent-a-car-jumeirah-beach' component={rentacar12JumeirahBeach} />
          <Route exact path='/rent-a-car-palm-jumeirah' component={rentacar12PalmJumeirah} />
          <Route exact path='/rent-a-car-burj-al-arab' component={rentacar12BurjAlArab} />
          <Route exact path='/rent-a-car-al-Satwa' component={rentacar12AlSatwa} />
          <Route exact path='/rent-a-car-burj-khalifa' component={rentacar12BurjKhalifa} />
          <Route exact path='/rent-a-car-al-quoz' component={rentacar12AlQuoz} />
          <Route exact path='/rent-a-car-al-karama' component={rentacar12alKarama} />
          <Route exact path='/rent-a-car-dubai-marina' component={rentacar12DubaiMarina} />
          <Route exact path='/rent-a-car-dubai' component={Rentacar12Dubai} />
          <Route exact path='/rent-a-car-bur-dubai' component={rentacar12BurDubai} />
          <Route exact path='/rent-a-car-dubai-downtown' component={Rentacar12DubaiDowntown} />
          <Route exact path='/rent-a-car-dubai-hills' component={rentacar12DubaiHills} />
          <Route exact path='/rent-a-car-karama' component={rentacar12Karama} />
          <Route exact path='/rent-a-car-sheikh-zayed-road' component={rentacar12SheikhZayedRoad} />
          <Route exact path='/rent-a-car-dubai-silicon-oasis' component={rentacar12DubaiSiliconOasis} />
          <Route exact path='/rent-a-car-Deira' component={rentacar12Deira} />
          <Route exact path='/rent-a-car-Abu-Dhabi' component={rentacar12AbuDhabi} />
          <Route exact path='/rent-a-car-Ajman' component={rentacar12Ajman} />
          <Route exact path='/rent-a-car-Dubai-Fountain' component={rentacar12DubaiFountain} />
          <Route exact path='/rent-a-car-Ski-Dubai' component={rentacar12SkiDubai} />
          <Route exact path='/rent-a-car-Dubai-Miracle-Garden' component={rentacar12DubaiMiracleGarden} />
          <Route exact path='/rent-a-car-Desert-Safari' component={rentacar12DesertSafari} />
          <Route exact path='/rent-a-car-Sharjah' component={rentacar12Sharjah} />
          <Route exact path='/rent-a-car-Ras-Al-Khaimah' component={rentacar12RasAlKhaimah} />
          <Route exact path='/rent-a-car-Umm-Al-Quwain' component={rentacar12UmmAlQuwain} />
          <Route exact path='/rent-a-car-Fujairah' component={rentacar12Fujairah} />
          <Route exact path='/rent-a-car-Al-Ain' component={rentacar12AlAin} />
          <Route exact path="/rent-a-car-One-&-Only-One-Za'abeel" component={rentacar12OneOnlyOneZaabeel} />
















          <Route exact path="/car-rental-dubai-dubai-car-rental" component={rentcar1} />
<Route exact path="/car-rental-companies-dubai" component={rentcar2} />
<Route exact path="/fast-rent-a-car-in-dubai-car-rental-dubai-aed-150-day" component={rentcar3} />
<Route exact path="/dubai-leading-car-rental-rent-a-car-in-dubai" component={rentcar4} />
<Route exact path="/dubai-internaional-airport-terminal-1-dxb-car-rental" component={rentcar5} />
<Route exact path="/renting-a-car-ministry-of-economy-uae" component={rentcar6} />
<Route exact path="/best-rent-a-car-in-dubai-economy-car-rental-dubai" component={rentcar7} />
<Route exact path="/rent-a-car-in-dubai-dubai-car-rental-hire-car-in-dubai" component={rentcar8} />
<Route exact path="/car-hire-in-dubai-cheap-rental-deals" component={rentcar9} />
<Route exact path="/rent-a-luxury-car-with-driver-car-rental-companies-dubai" component={rentcar10} />
<Route exact path="/legend-rent-a-car-car-rental-agency-dubai-hire-auto-vehicle" component={rentcar11} />
<Route exact path="/car-rental-in-dubai-united-arab-emirates" component={rentcar12} />
<Route exact path="/car-rental-dubai-united-arab-emirates" component={rentcar13} />
<Route exact path="/car-leasing-in-dubai-best-car-rental" component={rentcar14} />
<Route exact path="/car-for-rent-dubai-low-cost-rent-car-dubai" component={rentcar15} />
<Route exact path="/dubai-luxury-car-rental-no-deposit-required" component={rentcar16} />
<Route exact path="/premium-car-rental-dubai-rent-a-car-self-drive-dubai" component={rentcar17} />
<Route exact path="/rent-a-car-car-rental-agency-hire-car" component={rentcar18} />
<Route exact path="/monthly-car-rental-dubai-from-aed-1390" component={rentcar19} />
<Route exact path="/affordable-car-rental-in-dubai-low-cost-car-rental-services" component={rentcar20} />
<Route exact path="/affordable-car-rental-in-dubai-rent-a-car-uae" component={rentcar21} />
<Route exact path="/your-cheap-car-hire-in-dubai" component={rentcar22} />
<Route exact path="/number-1-car-rental-in-uae-dubai-lease-our-cars-now" component={rentcar23} />
<Route exact path="/luxury-car-rental-in-dubai-wow-rent-a-car" component={rentcar24} />




<Route exact path="/rent-a-car-bur-dubai-cheap-car-rental-hire-in-bur-dubai" component={rentcar25} />
<Route exact path="/rent-a-car-bur-dubai-for-75-day" component={rentcar26} />
<Route exact path="/rent-a-car-bur-dubai-cheap-car-rental-bur-dubai" component={rentcar27} />
<Route exact path="/long-term-car-rentals-bur-dubai" component={rentcar28} />
<Route exact path="/car-hire-in-bur-dubai-dubai-search-hire-cars" component={rentcar29} />
<Route exact path="/rent-a-car-bur-dubai-affordable-car-rental" component={rentcar30} />
<Route exact path="/bur-dubai-rent-a-car-in-dubai" component={rentcar31} />
<Route exact path="/car-rental-dubai-search-cheap-rental-car-deals" component={rentcar32} />
<Route exact path="/cheap-car-rental-bur-dubai-rent-a-cars-royal-uae" component={rentcar33} />
<Route exact path="/rent-a-car-in-bur-dubai" component={rentcar34} />
<Route exact path="/car-rental-in-bur-dubai-car-rental-near-me-on-the-map" component={rentcar35} />
<Route exact path="/car-rental-bur-dubai-cheap-rental-cars" component={rentcar36} />
<Route exact path="/weekly-car-hire-book-your-car-dubai-rental-deals-call-now" component={rentcar37} />
<Route exact path="/rental-cars-in-bur-dubai-in-uae-on-the-map" component={rentcar38} />
<Route exact path="/rent-a-car-bur-dubai-experience-the-best-car" component={rentcar39} />
<Route exact path="/cheap-rent-a-car-in-bur-dubai" component={rentcar40} />
<Route exact path="/rent-a-car-in-dubai-cheapest-monthly-car-rental" component={rentcar41} />
<Route exact path="/rent-a-car-in-bur-dubai-cheapest-monthly-car-rental" component={rentcar42} />
<Route exact path="/low-cost-car-rental-deals-in-bur-dubai-uae" component={rentcar43} />
<Route exact path="/luxury-car-rental-in-bur-dubai-make-my-ride" component={rentcar44} />
<Route exact path="/rent-a-car-per-hour-in-dubai-hourly-car-rental" component={rentcar45} />
<Route exact path="/cheap-rent-a-car-in-bur-dubai-cheapest-as-150-dhs-book" component={rentcar46} />
<Route exact path="/monthly-car-rental-in-bur-dubai" component={rentcar47} />





<Route exact path="/dubai-festival-city-car-rental-hire-online" component={rentcar48} />
<Route exact path="/car-rental-dubai-festival-city" component={rentcar49} />
<Route exact path="/21-dollar-car-rental-dubai-festival-city-mall" component={rentcar50} />
<Route exact path="/long-term-car-rental-dubai-festival-city" component={rentcar51} />
<Route exact path="/festival-city-rent-a-car-cheap-car-rentals" component={rentcar52} />
<Route exact path="/rent-a-car-dubai-festival-city-falcon-rides-car-rental" component={rentcar53} />
<Route exact path="/car-rental-in-dubai-festival-city" component={rentcar54} />
<Route exact path="/car-rental-dubai-festival-city" component={rentcar55} />
<Route exact path="/81-dollar-car-rental-dubai-festival-city" component={rentcar56} />
<Route exact path="/car-rental-dubai-festival-city-united-arab-emirates" component={rentcar57} />
<Route exact path="/rent-a-car-in-dubai-festival-city" component={rentcar58} />
<Route exact path="/rent-a-car-in-dubai-festival-city-mall" component={rentcar59} />
<Route exact path="/cheap-rental-cars-dubai-festival-city-book-now" component={rentcar60} />
<Route exact path="/affordable-car-rent-in-dubai-festival-city-with-great-dubai" component={rentcar61} />
<Route exact path="/rent-a-car-dubai-festival-city-online-reservation" component={rentcar62} />
<Route exact path="/easy-and-economical-car-rental-festival-city-dubai" component={rentcar63} />
<Route exact path="/rent-a-car-dubai-festival-city" component={rentcar64} />








<Route exact path="/car-rentals-in-ras-al-khor-dubai-falcon-rides" component={rentcar65} />
<Route exact path="/ras-al-khor-rent-a-car-dubai-with-falcon-rides" component={rentcar66} />
<Route exact path="/rent-a-car-ras-al-khor-travel-with-ease-quick-lease" component={rentcar67} />
<Route exact path="/rent-a-car-in-ras-al-khor-dubai-best-deals-and-offers" component={rentcar68} />
<Route exact path="/premium-car-rental-service-ras-al-khor-dubai" component={rentcar69} />
<Route exact path="/21-dollar-car-rental-ras-al-khor-industrial-area" component={rentcar70} />
<Route exact path="/rent-a-car-in-ras-al-khor-dubai" component={rentcar71} />
<Route exact path="/rent-a-car-in-ras-al-khor" component={rentcar72} />
<Route exact path="/rent-a-car-ras-al-khor-dubai" component={rentcar73} />
<Route exact path="/rent-a-car-ras-al-khor-travel-with-ease" component={rentcar74} />
<Route exact path="/rent-a-car-in-ras-al-khor-car-rental-in-dubai" component={rentcar75} />
<Route exact path="/cheap-rental-cars-ras-al-khor-industrial-area-book-now" component={rentcar76} />
<Route exact path="/car-rent-in-ras-al-khor-under-140-aed-with-great-dubai" component={rentcar77} />
<Route exact path="/rent-a-car-cheap-car-rental-hire-in-ras-al-khor-uae" component={rentcar78} />
<Route exact path="/best-rent-a-car-ras-al-khor" component={rentcar79} />
<Route exact path="/book-a-rent-a-car-in-dubai-weekly-monthly-cheap" component={rentcar80} />
<Route exact path="/rent-a-car-in-dubai-ras-al-khor" component={rentcar81} />
<Route exact path="/low-cost-car-rental-deals-in-dubai-ras-al-khor-uae" component={rentcar82} />
<Route exact path="/budget-car-rental-ras-al-khor-hire-economy-cars-for-rent-in-dubai" component={rentcar83} />
<Route exact path="/car-hire-leasing-ras-al-khor" component={rentcar84} />
<Route exact path="/cheap-car-hire-ras-al-khor-dubai" component={rentcar85} />
<Route exact path="/car-rental-ras-al-khor-in-uae-on-the-map" component={rentcar86} />
<Route exact path="/pickup-rental-cars-ras-al-khor-industrial-area" component={rentcar87} />
<Route exact path="/rent-a-car-in-ras-al-khor-dubai" component={rentcar88} />
<Route exact path="/car-rental-car-rentals-in-ras-al-khor-industrial-area" component={rentcar89} />
<Route exact path="/autorent-car-rental-in-dubai-ras-al-khor-uae" component={rentcar90} />
<Route exact path="/dollar-rent-a-car-car-rentals-in-ras-al-khor-industrial-area" component={rentcar91} />
<Route exact path="/car-rentals-in-ras-al-khor-dubai" component={rentcar92} />
<Route exact path="/cheap-car-rental-deals-in-ras-al-khor-dubai" component={rentcar93} />
<Route exact path="/car-hire-ras-al-khor-dubai" component={rentcar94} />
<Route exact path="/best-luxury-car-rental-in-dubai-uae" component={rentcar95} />
<Route exact path="/cheap-car-hire-in-zaabeel-dubai-from-77-pound-day" component={rentcar96} />
<Route exact path="/car-rentals-in-zaabeel-dubai-uae" component={rentcar97} />
<Route exact path="/car-rental-in-zaabeel-dubai" component={rentcar98} />
<Route exact path="/rent-a-car-in-zaabeel-dubai-cheap-car-hire" component={rentcar99} />





<Route exact path="/car-hire-in-al-quoz-dubai-from-aed-157-day" component={rentcar100} />
<Route exact path="/rent-a-car-al-quoz-best-car-rental-offers-al-quoz" component={rentcar101} />
<Route exact path="/cost-effective-car-rental-al-quoz-dubai" component={rentcar102} />
<Route exact path="/rent-a-car-in-al-quoz-dubai-from-109-aed" component={rentcar103} />
<Route exact path="/rent-a-car-in-al-quoz-dubai" component={rentcar104} />
<Route exact path="/rent-a-car-al-quoz-cheap-car-rentals-near-me" component={rentcar105} />
<Route exact path="/car-rentals-in-al-quoz-dubai-from-36-dollar-day" component={rentcar106} />
<Route exact path="/car-rental-in-dubai-al-quoz-branch" component={rentcar107} />
<Route exact path="/rent-car-al-quoz-dubai" component={rentcar108} />
<Route exact path="/cheap-car-rentals-in-al-quoz-dubai-from-45-dollar-day" component={rentcar109} />
<Route exact path="/rent-a-car-in-al-quoz-dubai" component={rentcar110} />
<Route exact path="/rent-a-car-al-quoz-find-special-car-hire-discounts" component={rentcar111} />
<Route exact path="/hire-car-al-quoz-dubai" component={rentcar112} />
<Route exact path="/best-car-rent-in-al-quoz-dubai" component={rentcar113} />
<Route exact path="/car-rental-dubai-al-quoz-uae-special-discounts-offers" component={rentcar114} />
<Route exact path="/affordable-car-rental-services-near-al-quoz-mall" component={rentcar115} />
<Route exact path="/rent-a-car-in-al-quoz-mall-dubai-best-deals-offers" component={rentcar116} />
<Route exact path="/rent-a-car-in-al-quoz-dubai" component={rentcar117} />
<Route exact path="/car-rental-companies-dubai-rent-a-luxury-car-with-driver" component={rentcar118} />
<Route exact path="/rent-a-car-al-quoz-mall-best-deals-offers" component={rentcar119} />
<Route exact path="/car-rentals-in-al-quoz-dubai" component={rentcar120} />
<Route exact path="/rent-a-car-in-al-quoz-dubai-cheap-car-hire" component={rentcar121} />
<Route exact path="/rent-a-car-dubai-best-car-rental-services" component={rentcar122} />
<Route exact path="/car-hire-leasing-al-quoz" component={rentcar123} />
<Route exact path="/car-rental-al-quoz-rent-a-car-dubai-at-lowest-price" component={rentcar124} />









<Route exact path="/rent-a-car-jumeirah-beach-residence" component={rentcar125} />
<Route exact path="/car-rental-jumeirah-dubai" component={rentcar126} />
<Route exact path="/car-rentals-in-jumeirah-dubai-from-34-dollar-day" component={rentcar127} />
<Route exact path="/rent-a-car-at-jumeirah-beach-residence" component={rentcar128} />
<Route exact path="/rent-a-car-in-jumeirah-village-circle-dubai" component={rentcar129} />
<Route exact path="/rent-a-car-jumeirah-beach-residence-dubai" component={rentcar130} />
<Route exact path="/pearl-jumeirah-rent-a-car-dubai-from-aed-40-day" component={rentcar131} />
<Route exact path="/car-rental-in-palm-jumeirah-dubai" component={rentcar132} />
<Route exact path="/cheap-car-hire-in-jumeirah-beach-residence" component={rentcar133} />
<Route exact path="/rent-a-car-in-jumeirah-village-circle-jvc-dubai" component={rentcar134} />
<Route exact path="/car-rental-in-jumeirah-dubai" component={rentcar135} />
<Route exact path="/cheap-car-rentals-in-jumeirah-from-just-32" component={rentcar136} />
<Route exact path="/best-car-rent-in-jumeirah-hire-self-drive-cars" component={rentcar137} />
<Route exact path="/car-rental-deals-at-hilton-dubai-jumeirah" component={rentcar138} />
<Route exact path="/cheap-car-hire-in-jumeirah-dubai-from-31-day" component={rentcar139} />
<Route exact path="/weekly-car-rental-jumeirah-1-dubai" component={rentcar140} />
<Route exact path="/cheap-rental-cars-jumeirah-beach-residence-book-now" component={rentcar141} />
<Route exact path="/rent-a-car-in-palm-jumeirah" component={rentcar142} />






<Route exact path="/rent-a-car-deira-cheap-car-rental-hire-in-dubai-uae" component={rentcar143} />
<Route exact path="/low-cost-car-rental-deira-dubai" component={rentcar144} />
<Route exact path="/car-rentals-in-deira-for-aed-75-day" component={rentcar145} />
<Route exact path="/rent-a-car-deira-budget-car-rentals-deira" component={rentcar146} />
<Route exact path="/car-rentals-in-deira-dubai-falcon-rides" component={rentcar147} />
<Route exact path="/rent-a-car-in-deira-dubai" component={rentcar148} />
<Route exact path="/cheap-car-hire-in-deira-dubai-from-20-day" component={rentcar149} />
<Route exact path="/rent-a-car-deira-car-hire-available-for-aed-72-day" component={rentcar150} />
<Route exact path="/car-rental-from-deira-city-center-dubai" component={rentcar151} />
<Route exact path="/rent-a-car-in-dubai-deira-cheap-car-rental-and-hire-in-deira" component={rentcar152} />
<Route exact path="/cheap-rent-a-car-deira-dubai" component={rentcar153} />
<Route exact path="/best-car-rent-in-deira-hire-self-drive-cars-deposit-free" component={rentcar154} />












          <Route exact path='/shop-all' component={ab} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/traffic-fines' component={Trafficfines} />
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/checkout2' component={Checkout2} />
          <Route exact path='/FAQ' component={FAQ} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/track' component={tracking} />
          <Route exact path='/testimonials' component={testimonials} />
          <Route exact path='/search/product-item' component={SearchData} />
          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          {/* <Route exact path='/main-categories/:categoriesName' component={Categories} /> */}
          <Route exact path='/main-categories-car-rental/:categoriesName' component={Categories1} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          <Route exact path='/categories-montly/:categoriesName/all-products/:productName/:productId' component={Montly} />
          <Route exact path='/categories-weekly/:categoriesName/all-products/:productName/:productId' component={Weekly} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />

          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;


