import { useEffect, useState } from "react";
import CarAudi from "../images/cars-big/audia1.jpg";
import CarGolf from "../images/cars-big/golf6.jpg";
import CarToyota from "../images/cars-big/toyotacamry.jpg";
import CarBmw from "../images/cars-big/bmw320.jpg";
import CarMercedes from "../images/cars-big/benz.jpg";
import CarPassat from "../images/cars-big/passatcc.jpg";
// import { IconCar, IconInfoCircleFilled, IconX } from "@tabler/icons-react";
// import { IconMapPinFilled } from "@tabler/icons-react";
// import { IconCalendarEvent } from "@tabler/icons-react";



// import CalendarMonthIcon from '@material-ui/icons/CalendarMonth';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import TimeToLeaveIcon from '@material-ui/icons/TimeToLeave';
// import StreamIcon from '@material-ui/icons/Stream';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import DateRangeIcon from '@material-ui/icons/DateRange';
// import MarkEmailUnreadIcon from '@material-ui/icons/MarkEmailUnread';

import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';

import swal from 'sweetalert';

function BookCar() {
  const [modal, setModal] = useState(false); //  class - active-modal

  // booking car
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");
  const [ccode, setccode] = useState("");

  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [ID, setID] = useState("");


  const [Product_Image_Upload , setProduct_Image_Upload] = useState("")

  
  // const [pickup1 , setpickup1] = useState("")
  // const [drop1 , setdrop1] = useState("")
  // const [pickup2 , setpickup2] = useState("")
  // const [drop2 , setdrop2] = useState("")
  // const [pickup3 , setpickup3] = useState("")
  // const [drop3 , setdrop3] = useState("")
  // const [pickup4 , setpickup4] = useState("")
  // const [drop4 , setdrop4] = useState("")
  const [pickup , setpickup] = useState("")
  const [drop , setdrop] = useState("")
  const [choosetime , setchoosetime] = useState("")
  const [pickupdate , setpickupdate] = useState("")
  const [dropdate , setdropdate] = useState("")
  // const [phone , setphone] = useState("")
  const [car , setcar] = useState("")
  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleZip = (e) => {
    setZipCode(e.target.value);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickTime === "" ||
      dropTime === "" ||
      carType === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking
  const confirmBooking = (e) => {
    e.preventDefault();
    setModal(!modal);
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "flex";
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    setCarType(e.target.value);
    setCarImg(e.target.value);
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  // based on value name show car img
  let imgUrl;
  switch (carImg) {
    case "Audi A1 S-Line":
      imgUrl = CarAudi;
      break;
    case "VW Golf 6":
      imgUrl = CarGolf;
      break;
    case "Toyota Camry":
      imgUrl = CarToyota;
      break;
    case "BMW 320 ModernLine":
      imgUrl = CarBmw;
      break;
    case "Mercedes-Benz GLK":
      imgUrl = CarMercedes;
      break;
    case "VW Passat CC":
      imgUrl = CarPassat;
      break;
    default:
      imgUrl = "";
  }

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

    

  const SubmitDataMessage = (e) =>{
    e.preventDefault()
   
  
  if(car===""){
    swal("Select Car")
    return
  }
  if(pickup===""){
    swal("Select Pickup City")
    return
  }
  if(drop===""){
    swal("Select Drop Off City")
    return
  }
  const data = new FormData()
  data.append("file", Product_Image_Upload)
  data.append("upload_preset", "commerce")
  data.append("cloud_name", "freedeveloperss123")
  fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
      method : "POST",
      body : data , 
  })
  .then(res=>res.json())
  .then((res2)=>{
    console.log(res2)
    // this.setState({ url : res2.url})
    // setUrl(res2.url)
    // if(res2.url !== ""){
                        console.log(res2)
                                fetch("https://falcon-rides-main-back.vercel.app/createbooking1",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                              pickup ,  
                                              drop ,
                                              ccode ,
                                              choosetime ,
                                              pickupdate , 
                                              dropdate,
                                              phone ,
                                              car ,
                                              email ,
                                              // Product_Image_Upload : ID,
                                              Product_Image_Upload : res2.url ? res2.url : "",
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                            if(res12.Error){
                                              this.setState({displayNone : true})
  
                                            swal(res12.Error)
                                        }
                                        else{
                                                swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
                                                this.setState({
                                                  // Product_Catagories: "",
                                                  // Product_Image_Upload : "",
                                                  url : "",
                                            })
                                            setcar("")
                                            // setcar("")
                                            setpickup("")
                                            setdrop("")
                                            setPhone("")
                                            setEmail("")
                                            this.setState({displayNone : true})
                                            
                                          }
                                    })
                                    .catch(err=>{
                                      swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
                                      setcar("")
                                      // setcar("")
                                      setpickup("")
                                      setdrop("")
                                      setPhone("")
                                      setEmail("")
  
                                        }) 
                                        .catch((err)=>console.log(err))
                                     
                                      })   
  
            }  
  
  return (
    <>
      <section id="booking-section" className="book-section">
        {/* <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div> */}

        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>Book a car</h2>

              <p className="error-message">
                All fields required! <Brightness7Icon width={20} height={20} />
              </p>

              <p className="booking-done">
                Check your email to confirm an order.{" "}
                <Brightness7Icon width={20} height={20} onClick={hideMessage} />
              </p>

              <form className="box-form" onSubmit={(e)=>SubmitDataMessage(e)}>
                <div className="box-form__car-type" style={{width: "92%"}}>
                  <label>
                    <TimeToLeaveIcon className="input-icon"  style={{fontSize: "25px"}}/> &nbsp; Select Your Car
                    Type <b>*</b>
                  </label>
                  <select  value={car} onChange={(e)=>setcar(e.target.value)} required>
                    <option>Select your car </option>
                    <option value="KIA Pegas 2023">KIA Pegas 2023</option>
    <option value="Mitsubishi ASX 2.0 GLX 2WD">Mitsubishi ASX 2.0 GLX 2WD</option>
    <option value="Hyundai Elantra 2024">Hyundai Elantra 2024</option>
    <option value="Hyundai Accent 2024">Hyundai Accent 2024</option>
    <option value="Mazda 3 2024">Mazda 3 2024</option>
    <option value="Mazda 6 2024">Mazda 6 2024</option>
    <option value="KIA K5 2024">KIA K5 2024</option>
    <option value="KIA Picanto 2024">KIA Picanto 2024</option>
    <option value="KIA SONET 2024">KIA SONET 2024</option>
    <option value="KIA Sorento 2024">KIA Sorento 2024</option>
    <option value="KIA CARENS 2024">KIA CARENS 2024</option>
    <option value="KIA Sportage 2024">KIA Sportage 2024</option>
    <option value="KIA SELTOS 2024">KIA SELTOS 2024</option>
    <option value="MG ZS 2024">MG ZS 2024</option>
    <option value="Nissan Sunny 2023">Nissan Sunny 2023</option>
    <option value="Mitsubishi Attrage 2022">Mitsubishi Attrage 2022</option>
    <option value="MG ZS 2023 Model">MG ZS 2023 Model</option>
    <option value="MG5 2022 Model">MG5 2022 Model</option>
    <option value="Hyundai Grand i10 2022">Hyundai Grand i10 2022</option>
    <option value="KIA Pegas 2023 Model">KIA Pegas 2023 Model</option>
    <option value="Hyundai Grand i10 2023">Hyundai Grand i10 2023</option>
    <option value="Hyundai Creta 2023">Hyundai Creta 2023</option>
                  </select>
                </div>

                <div className="box-form__car-type" style={{width: "92%"}}>
                  <label>
                    <EditLocationIcon className="input-icon"  style={{fontSize: "25px"}}/> &nbsp; Pick-up location{" "}
                    <b>*</b>
                  </label>
                  <select   value={pickup} onChange={(e)=>setpickup(e.target.value)} required>
                  <option value="Downtown Dubai">Downtown Dubai</option>
                  <option value="Dubai Marina">Dubai Marina</option>
                  <option value="Dubai Hills">Dubai Hills</option>
                  <option value="Jumeirah Beach Residence (JBR)">Jumeirah Beach Residence (JBR)</option>
                  <option value="Palm Jumeirah">Palm Jumeirah</option>
                  <option value="Jumeirah Lakes Towers (JLT)">Jumeirah Lakes Towers (JLT)</option>
                  <option value="Emirates Hills">Emirates Hills</option>
                  <option value="Arabian Ranches 1">Arabian Ranches 1</option>
                  <option value="Arabian Ranches 2">Arabian Ranches 2</option>
                  <option value="Arabian Ranches 3">Arabian Ranches 3</option>
                  <option value="Business Bay">Business Bay</option>
                  <option value="Dubai International Financial Centre (DIFC)">Dubai International Financial Centre (DIFC)</option>
                  <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                  <option value="Dubai Sports City">Dubai Sports City</option>
                  <option value="Dubai Investment Park (DIP)">Dubai Investment Park (DIP)</option>
                  <option value="Al Barsha">Al Barsha</option>
                  <option value="Al Nahda">Al Nahda</option>
                  <option value="Dubai">Dubai</option>
                  <option value="Al Quoz">Al Quoz</option>
                  <option value="Karama">Karama</option>
                  <option value="Deira">Deira</option>
                  <option value="Bur Dubai">Bur Dubai</option>
                  <option value="Al Barsha South">Al Barsha South</option>
                  <option value="Al Satwa">Al Satwa</option>
                  <option value="Al Mankhool">Al Mankhool</option>
                  <option value="Al Wasl">Al Wasl</option>
                  <option value="Al Rashidiya">Al Rashidiya</option>
                  <option value="Al Rigga">Al Rigga</option>
                  <option value="Al Warqa'a">Al Warqa'a</option>
                  <option value="Dubailand">Dubailand</option>


                  </select>
                  {/* <input
                  style={{height: "43px",width: "100%"}}
                    id="picktime"
                    required

                    placeholder="Enter Your Pick Up Location"

                    value={pickup} onChange={(e)=>setpickup(e.target.value)}
                                        type="text"
                  ></input> */}
                  {/* <select  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option>Select pick up location</option>
                    <option>Belgrade</option>
                    <option>Novi Sad</option>
                    <option>Nis</option>
                    <option>Kragujevac</option>
                    <option>Subotica</option>
                  </select> */}
                </div>

                <div className="box-form__car-type" style={{width: "92%"}}>
                  <label>
                    <EditLocationIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Drop-of location{" "}
                    <b>*</b>
                  </label>
                  <select  value={drop} onChange={(e)=>setdrop(e.target.value)} required>
                  <option value="Palm Jumeirah">Palm Jumeirah</option>
                  <option value="Emirates Hills">Emirates Hills</option>
                  <option value="Jumeirah Beach Residence (JBR)">Jumeirah Beach Residence (JBR)</option>
                  <option value="Downtown Dubai">Downtown Dubai</option>
                  <option value="Dubai Marina">Dubai Marina</option>
                  <option value="Dubai Hills">Dubai Hills</option>

                  <option value="Jumeirah Lakes Towers (JLT)">Jumeirah Lakes Towers (JLT)</option>
                  <option value="Arabian Ranches 1">Arabian Ranches 1</option>
                  <option value="Arabian Ranches 2">Arabian Ranches 2</option>
                  <option value="Arabian Ranches 3">Arabian Ranches 3</option>
                  <option value="Business Bay">Business Bay</option>
                  <option value="Dubai International Financial Centre (DIFC)">Dubai International Financial Centre (DIFC)</option>
                  <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                  <option value="Dubai Sports City">Dubai Sports City</option>
                  <option value="Dubai Investment Park (DIP)">Dubai Investment Park (DIP)</option>
                  <option value="Al Barsha">Al Barsha</option>
                  <option value="Al Nahda">Al Nahda</option>
                  <option value="Al Quoz">Al Quoz</option>
                  <option value="Dubai">Dubai</option>
                  <option value="Karama">Karama</option>
                  <option value="Deira">Deira</option>
                  <option value="Bur Dubai">Bur Dubai</option>
                  <option value="Al Barsha South">Al Barsha South</option>
                  <option value="Al Satwa">Al Satwa</option>
                  <option value="Al Mankhool">Al Mankhool</option>
                  <option value="Al Wasl">Al Wasl</option>
                  <option value="Al Rashidiya">Al Rashidiya</option>
                  <option value="Al Rigga">Al Rigga</option>
                  <option value="Al Warqa'a">Al Warqa'a</option>
                  <option value="Dubailand">Dubailand</option>


                  </select>
              
                </div>

                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="picktime">
                    <DateRangeIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Pick-up Date{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{height: "43px",width: "100%"}}
                    id="picktime"
                    required

                    value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}
                    type="date"
                  ></input>
                </div>

                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                    <DateRangeIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Drop-of Date{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{height: "43px",width: "100%"}}
                    id="droptime"
                    required

                    value={dropdate} onChange={(e)=>setdropdate(e.target.value)}
                    type="date"
                  ></input>
                </div>
                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                    <PhoneCallbackIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Correct Phone No.{" "}
                    <b>*</b>
                  </label>
                  <div style={{display:"flex"}}>
                      <select id="select-state" placeholder="Pick a state..."  style={{width:"110px"}}          
                       value={ccode} onChange={(e)=>setccode(e.target.value)}
                      >
    <option value="">Country Code</option>
    {/* <select id="countrySelect" name="country"> */}
    <option value="+971">+971 United Arab Emirates </option>
    <option value="+965">+965 Kuwait </option>
    <option value="+92">+92 Pakistan </option>
    <option value="+974">+974 Qatar</option>
    <option value="+91">+91 India </option>
    <option value="+966">+966 Saudi Arabia </option>
    <option value="+65">+65 Singapore</option>
    <option value="+1">+1 Canada</option>
    <option value="+44">+44 United Kingdom</option>
    <option value="+1">+1 United States</option>
    <option value="+968">+968 Oman</option>
    <option value="+41">+41 Switzerland (Schweiz)</option>
    <option value="+992">+992 Tajikistan</option>
    <option value="+90">+90 Turkey (Türkiye)</option>
    <option value="+967">+967 Yemen </option>
    <option value="+93">+93 Afghanistan </option>
    <option value="+964">+964 Iraq</option>
    <option value="+353">+353 Ireland</option>
    {/* <option value="+44">+44 Isle of Man</option> */}
    <option value="+972">+972 Israel (‫ישראל‬‎)</option>
    <option value="+39">+39 Italy (Italia)</option>
    {/* <option value="+1876">+1876 Jamaica</option> */}
    <option value="+81">+81 Japan (日本)</option>
    {/* <option value="+44">+44 Jersey</option> */}
    <option value="+962">+962 Jordan (‫الأردن‬‎)</option>
    <option value="+355">+355 Albania (Shqipëri)</option>
    <option value="+213">+213 Algeria (‫الجزائر‬‎)</option>
    <option value="+1684">+1684 American Samoa</option>
    {/* <option value="+376">+376 Andorra</option>
    <option value="+244">+244 Angola</option>
    <option value="+1264">+1264 Anguilla</option>
    <option value="+1268">+1268 Antigua and Barbuda</option>
    <option value="+54">+54 Argentina</option>
    <option value="+374">+374 Armenia (Հայաստան)</option>
    <option value="+297">+297 Aruba</option> */}
    <option value="+61">+61 Australia</option>
    <option value="+43">+43 Austria (Österreich)</option>
    <option value="+994">+994 Azerbaijan (Azərbaycan)</option>
    <option value="+1242">+1242 Bahamas</option>
    <option value="+973">+973 Bahrain (‫البحرين‬‎)</option>
    <option value="+880">+880 Bangladesh (বাংলাদেশ)</option>
    {/* <option value="+1246">+1246 Barbados</option>
    <option value="+375">+375 Belarus (Беларусь)</option> */}
    <option value="+32">+32 Belgium (België)</option>
    {/* <option value="+501">+501 Belize</option>
    <option value="+229">+229 Benin (Bénin)</option>
    <option value="+1441">+1441 Bermuda</option>
    <option value="+975">+975 Bhutan (འབྲུག)</option>
    <option value="+591">+591 Bolivia</option>
    <option value="+387">+387 Bosnia and Herzegovina (Босна и Херцеговина)</option>
    <option value="+267">+267 Botswana</option> */}
    <option value="+55">+55 Brazil (Brasil)</option>
    {/* <option value="+246">+246 British Indian Ocean Territory</option>
    <option value="+1284">+1284 British Virgin Islands</option>
    <option value="+673">+673 Brunei</option>
    <option value="+359">+359 Bulgaria (България)</option>
    <option value="+226">+226 Burkina Faso</option>
    <option value="+257">+257 Burundi (Uburundi)</option>
    <option value="+855">+855 Cambodia (កម្ពុជា)</option>
    <option value="+237">+237 Cameroon (Cameroun)</option> */}
    {/* <option value="+238">+238 Cape Verde (Kabu Verdi)</option>
    <option value="+599">+599 Caribbean Netherlands</option>
    <option value="+1345">+1345 Cayman Islands</option>
    <option value="+236">+236 Central African Republic (République centrafricaine)</option> */}
    {/* <option value="+235">+235 Chad (Tchad)</option>
    <option value="+56">+56 Chile</option>
    <option value="+86">+86 China (中国)</option>
    <option value="+61">+61 Christmas Island</option>
    <option value="+61">+61 Cocos (Keeling) Islands</option> */}
    <option value="+57">+57 Colombia</option>
    {/* <option value="+269">+269 Comoros (‫جزر القمر‬‎)</option>
    <option value="+243">+243 Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)</option>
    <option value="+242">+242 Congo (Republic) (Congo-Brazzaville)</option>
    <option value="+682">+682 Cook Islands</option>
    <option value="+506">+506 Costa Rica</option>
    <option value="+225">+225 Côte d’Ivoire</option>
    <option value="+385">+385 Croatia (Hrvatska)</option>
    <option value="+53">+53 Cuba</option>
    <option value="+599">+599 Curaçao</option>
    <option value="+357">+357 Cyprus (Κύπρος)</option> */}
    <option value="+420">+420 Czech Republic (Česká republika)</option>
    <option value="+45">+45 Denmark (Danmark)</option>
    {/* <option value="+ 253">+253 Djibouti</option>
    <option value="+1767">+1767 Dominica</option>
    <option value="+1">+1 Dominican Republic (República Dominicana)</option>
    <option value="+593">+593 Ecuador</option> */}
    <option value="+20">+20 Egypt (‫مصر‬‎)</option>
    {/* <option value="+503">+503 El Salvador</option>
    <option value="+240">+240 Equatorial Guinea (Guinea Ecuatorial)</option>
    <option value="+291">+291 Eritrea</option>
    <option value="+372">+372 Estonia (Eesti)</option>
    <option value="+251">+251 Ethiopia</option>
    <option value="+500">+500 Falkland Islands (Islas Malvinas)</option>
    <option value="+298">+298 Faroe Islands (Føroyar)</option> */}
    {/* <option value="+679">+679 Fiji</option> */}
    <option value="+358">+358 Finland (Suomi)</option>
    <option value="+33">+33 France</option>
    {/* <option value="+594">+594 French Guiana (Guyane française)</option>
    <option value="+689">+689 French Polynesia (Polynésie française)</option> */}
    {/* <option value="+241">+241 Gabon</option>
    <option value="+220">+220 Gambia</option> */}
    <option value="+995">+995 Georgia (საქართველო)</option>
    <option value="+49">+49 Germany (Deutschland)</option>
    {/* <option value="+233">+233 Ghana (Gaana)</option>
    <option value="+350">+350 Gibraltar</option> */}
    <option value="+30">+30 Greece (Ελλάδα)</option>
    <option value="+299">+299 Greenland (Kalaallit Nunaat)</option>
    {/* <option value="+1473">+1473 Grenada</option>
    <option value="+590">+590 Guadeloupe</option>
    <option value="+1671">+1671 Guam</option>
    <option value="+502">+502 Guatemala</option>
    <option value="+44">+44 Guernsey</option>
    <option value="+224">+224 Guinea (Guinée)</option>
    <option value="+245">+245 Guinea-Bissau (Guiné Bissau)</option>
    <option value="+592">+592 Guyana</option>
    <option value="+509">+509 Haiti</option>
    <option value="+504">+504 Honduras</option> */}
    <option value="+852">+852 Hong Kong (香港)</option>
    <option value="+36">+36 Hungary (Magyarország)</option>
    <option value="+354">+354 Iceland (Ísland)</option>

    <option value="+62">+62 Indonesia</option>
    <option value="+98">+98 Iran (‫ایران‬‎)</option>
    <option value="+7">+7 Kazakhstan (Казахстан)</option>
    {/* <option value="+254">+254 Kenya</option>
    <option value="+686">+686 Kiribati</option>
    <option value="+383">+383 Kosovo</option> */}

    {/* <option value="+996">+996 Kyrgyzstan (Кыргызстан)</option> */}
    {/* <option value="+856">+856 Laos (ລາວ)</option>
    <option value="+371">+371 Latvia (Latvija)</option> */}
    <option value="+961">+961 Lebanon (‫لبنان‬‎)</option>
    {/* <option value="+266">+266 Lesotho</option>
    <option value="+231">+231 Liberia</option> */}
    <option value="+218">+218 Libya (‫ليبيا‬‎)</option>
    {/* <option value="+423">+423 Liechtenstein</option>
    <option value="+370">+370 Lithuania (Lietuva)</option>
    <option value="+352">+352 Luxembourg</option>
    <option value="+853">+853 Macau (澳門)</option>
    <option value="+389">+389 Macedonia (FYROM) (Македонија)</option>
    <option value="+261">+261 Madagascar (Madagasikara)</option> */}
    {/* <option value="+265">+265 Malawi</option> */}
    <option value="+60">+60 Malaysia</option>
    <option value="+960">+960 Maldives</option>
    {/* <option value="+223">+223 Mali</option>
    <option value="+356">+356 Malta</option>
    <option value="+692">+692 Marshall Islands</option>
    <option value="+596">+596 Martinique</option> */}
    {/* <option value="+222">+222 Mauritania (‫موريتانيا‬‎)</option> */}
    {/* <option value="+230">+230 Mauritius (Moris)</option> */}
    {/* <option value="+262">+262 Mayotte</option> */}
    <option value="+52">+52 Mexico (México)</option>
    {/* <option value="+691">+691 Micronesia</option> */}
    {/* <option value="+373">+373 Moldova (Republica Moldova)</option> */}
    {/* <option value="+377">+377 Monaco</option> */}
    {/* <option value="+976">+976 Mongolia (Монгол)</option> */}
    {/* <option value="+382">+382 Montenegro (Crna Gora)</option> */}
    {/* <option value="+1664">+1664 Montserrat</option> */}
    <option value="+212">+212 Morocco (‫المغرب‬‎)</option>
    {/* <option value="+258">+258 Mozambique (Moçambique)</option> */}
    {/* <option value="+95">+95 Myanmar (Burma) (မြန်မာ)</option> */}
    {/* <option value="+264">+264 Namibia (Namibië)</option> */}
    {/* <option value="+674">+674 Nauru</option> */}
    <option value="+977">+977 Nepal (नेपाल)</option>
    <option value="+31">+31 Netherlands (Nederland)</option>
    {/* <option value="+687">+687 New Caledonia (Nouvelle-Calédonie)</option> */}
    <option value="+64">+64 New Zealand</option>
    {/* <option value="+505">+505 Nicaragua</option> */}
    {/* <option value="+227">+227 Niger (Nijar)</option> */}
    {/* <option value="+234">+234 Nigeria</option> */}
    {/* <option value="+683">+683 Niue</option> */}
    {/* <option value="+672">+672 Norfolk Island</option> */}
    {/* <option value="+850">+850 North Korea (조선 민주주의 인민 공화국)</option> */}
    {/* <option value="+1670">+1670 Northern Mariana Islands</option> */}
    <option value="+47">+47 Norway (Norge)</option>
  
    <option value="+680">+680 Palau</option>
    <option value="+970">+970 Palestine (‫فلسطين‬‎)</option>
    {/* <option value="+507">+507 Panama (Panamá)</option> */}
    {/* <option value="+675">+675 Papua New Guinea</option> */}
    {/* <option value="+595">+595 Paraguay</option> */}
    {/* <option value="+51">+51 Peru (Perú)</option> */}
    <option value="+63">+63 Philippines</option>
    <option value="+48">+48 Poland (Polska)</option>
    <option value="+351">+351 Portugal</option>
    {/* <option value="+1">+1 Puerto Rico</option> */}

    {/* <option value="+262">+262 Réunion (La Réunion)</option> */}
    <option value="+40">+40 Romania (România)</option>
    <option value="+7">+7 Russia (Россия)</option>
    {/* <option value="+250">+250 Rwanda</option> */}
    {/* <option value="+590">+590 Saint Barthélemy (Saint-Barthélemy)</option> */}
    {/* <option value="+290">+290 Saint Helena</option> */}
    {/* <option value="+1869">+1869 Saint Kitts and Nevis</option> */}
    {/* <option value="+175 8">+1758 Saint Lucia</option> */}
    {/* <option value="+590">+590 Saint Martin (Saint-Martin (partie française))</option> */}
    {/* <option value="+508">+508 Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)</option> */}
    {/* <option value="+1784">+1784 Saint Vincent and the Grenadines</option> */}
    {/* <option value="+685">+685 Samoa</option> */}
    {/* <option value="+378">+378 San Marino</option> */}
    {/* <option value="+239">+239 São Tomé and Príncipe (São Tomé e Príncipe)</option> */}
 
    {/* <option value="+221">+221 Senegal (Sénégal)</option> */}
    <option value="+381">+381 Serbia (Србија)</option>
    {/* <option value="+248">+248 Seychelles</option> */}
    {/* <option value="+232">+232 Sierra Leone</option> */}
   
    {/* <option value="+1721">+1721 Sint Maarten</option> */}
    {/* <option value="+421">+421 Slovakia (Slovensko)</option> */}
    {/* <option value="+386">+386 Slovenia (Slovenija)</option> */}
    {/* <option value="+677">+677 Solomon Islands</option> */}
    {/* <option value="+252">+252 Somalia (Soomaaliya)</option> */}
    <option value="+27">+27 South Africa</option>
    {/* <option value="+82">+82 South Korea (대한민국)</option> */}
    {/* <option value="+211">+211 South Sudan </option> */}
    <option value="+34">+34 Spain (España)</option>
    <option value="+94">+94 Sri Lanka</option>
    <option value="+249">+249 Sudan (‫السودان‬‎)</option>
    {/* <option value="+597">+597 Suriname</option> */}
    {/* <option value="+47">+47 Svalbard and Jan Mayen</option> */}

    <option value="+268">+268 Swaziland</option>
    <option value="+46">+46 Sweden (Sverige)</option>
    <option value="+963">+963 Syria (‫سوريا‬‎)</option>
    <option value="+886">+886 Taiwan (台灣)</option>
    <option value="+255">+255 Tanzania</option>
    <option value="+66">+66 Thailand (ไทย)</option>
    {/* <option value="+670">+670 Timor-Leste</option> */}
    {/* <option value="+228">+228 Togo</option> */}
    {/* <option value="+690">+690 Tokelau</option> */}
    {/* <option value="+676">+676 Tonga</option> */}
    {/* <option value="+1868">+1868 Trinidad and Tobago</option> */}
    {/* <option value="+216">+216 Tunisia (‫تونس‬‎)</option> */}
    <option value="+993">+993 Turkmenistan</option>
    {/* <option value="+1649">+1649 Turks and Caicos Islands</option> */}
    {/* <option value="+688">+688 Tuvalu</option> */}
    <option value="+1340">+1340 U.S. Virgin Islands</option>
    {/* <option value="+256">+256 Uganda</option> */}
    <option value="+380">+380 Ukraine (Україна)</option>
   
    {/* <option value="+598">+598 Uruguay</option> */}
    <option value="+998">+998 Uzbekistan (Oʻzbekiston)</option>
    {/* <option value="+678">+678 Vanuatu</option> */}
    {/* <option value="+39">+39 Vatican City (Città del Vaticano)</option> */}
    {/* <option value="+58">+58 Venezuela</option> */}
    <option value="+84">+84 Vietnam (Việt Nam)</option>
    {/* <option value="+681">+681 Wallis and Futuna</option> */}
    {/* <option value="+212">+212 Western Sahara (‫الصحراء الغربية‬‎)</option> */}
  
    <option value="+260">+260 Zambia</option>
    <option value="+263">+263 Zimbabwe</option>
    {/* <option value="+358">+358 Åland Islands</option> */}
{/* </select> */}
  </select>
                  <input
                  style={{padding: ".5rem 1.3rem",height: "43px",width: "100%"}}
                    id="droptime"
                    required
                    value={phone} onChange={(e)=>setPhone(e.target.value)}
                    type="tel"
                    placeholder="Example : ( +971 56 361 9373 )"

                  ></input>
                  </div>
                
                </div>
                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                  <i className="icon-envelope"   style={{fontSize: "25px",color:"black"}}/>

                    {/* <MarkEmailUnreadIcon className="input-icon"   style={{fontSize: "25px"}}/> */}
                     &nbsp;Active Email.{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{padding: ".5rem 1.3rem",height: "43px",width: "100%"}}
                    id="email"
                    required
                    value={email} onChange={(e)=>setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter Your Email"

                  ></input>
                </div>
                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                    <DateRangeIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Id Card{" "}
                    <b>( Optional )</b>
                  </label>
                  <input
                                    style={{height: "43px",padding: ".5rem 1.3rem",width: "100%"}}

                    id="droptime"
                    onChange={(e)=>setProduct_Image_Upload(e.target.files[0])}                     type="file"
                  ></input>
                </div>

                <button type="submit"  style={{width: "92%"}}>
                  Book Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}

      {/* <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        <div className="booking-modal__title">
          <h2>Complete Reservation</h2>
          <Brightness7Icon onClick={openModal} />
        </div>
        <div className="booking-modal__message">
          <h4>
            <IconInfoCircleFilled /> Upon completing this reservation enquiry,
            you will receive:
          </h4>
          <p>
            Your rental voucher to produce on arrival at the rental desk and a
            toll-free customer support number.
          </p>
        </div>
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} /{" "}
                    <input
                    style={{height: "43px"}} type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} /{" "}
                    <input
                    style={{height: "43px"}} type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> {carType}
            </h5>
            {imgUrl && <img src={imgUrl} alt="car_img" />}
          </div>
        </div> */}


        {/* <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Enter your phone number"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="18"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  City <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Zip Code <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <span className="info-form__checkbox">
              <input
              style={{height: "43px"}} type="checkbox"></input>
              <p>Please send me latest news and updates</p>
            </span>

            <div className="reserve-button">
              <button onClick={confirmBooking}>Reserve Now</button>
            </div>
          </form>
        </div>
      </div> */}
    </>
  );
}

export default BookCar;
