import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";
// import { IconChevronRight } from "@tabler/icons-react";
import {Link ,useHistory} from "react-router-dom"
import pay from '../assets/images/11.png';


import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={pay}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Why Choose Us</h4>
                <h2>Best valued deals you will ever find</h2>
                <p>
                Falcon Rides Car Rental Dubai, a leading name in Dubai, UAE, 
                offers an extensive selection of over 30+ vehicles,
                 making it the go-to choice for those looking 
                 to rent a car in Dubai. With a fleet that ranges from 
                 economical cars to luxury brands like Nissan, 
                Asx, MG, Mazda, Hyundai and KIA, they cater to every taste and budget. 
                </p>
                <Link to="/categories/Cars/all-products">
                  Find Details &nbsp;
                  <ArrowForwardIosIcon style={{marginTop: "7px"}}/>
                </Link>
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Lowest Prices In Dubai
                    </h4>
                    <p>
                    We are proud to offer some of the lowest rental prices in the market, and frequent promotions allow for even greater savings on your desired vehicle.


                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>First Class Service
                    </h4>
                    <p>
                      Get everything you need in one convenient, transparent
                      price with our all-inclusive pricing policy.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Drop Off Anywhere In Dubai
                    </h4>
                    <p>
                    Customers can now enjoy our complimentary pick-up and drop-off service from any location in Dubai, including the airport.


                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
