

// var tap = Tapjsli('pk_test_EtHFV4BuPQokJT6jiROls87Y');

// var elements = tap.elements({});
// var style = {
//   base: {
//     color: '#535353',
//     lineHeight: '18px',
//     fontFamily: 'sans-serif',
//     fontSmoothing: 'antialiased',
//     fontSize: '16px',
//     '::placeholder': {
//       color: 'rgba(0, 0, 0, 0.26)',
//       fontSize:'15px'
//     }
//   },
//   invalid: {
//     color: 'red'
//   }
// };

// var labels = {
//     cardNumber:"Card Number",
//     expirationDate:"MM/YY",
//     cvv:"CVV",
//     cardHolder:"Card Holder Name"
//   };

//   var paymentOptions = {
//   currencyCode:["KWD","USD","SAR"],
//   labels : labels,
//   TextDirection:'ltr'
// }

// var card = elements.create('card', {style: style},paymentOptions);

// card.mount('#element-container');

// card.addEventListener('change', function(event) {
//   if(event.BIN){
//     console.log(event.BIN)
//   }
//   if(event.loaded){
//     console.log("UI loaded :"+event.loaded);
//     console.log("current currency is :"+card.getCurrency())
//   }
//   var displayError = document.getElementById('error-handler');
//   if (event.error) {
//     displayError.textContent = event.error.message;
//   } else {
//     displayError.textContent = '';
//   }
// });


// var form = document.getElementById('form-container');
// form.addEventListener('submit', function(event) {
//   event.preventDefault();

//   tap.createToken(card).then(function(result) {
//     console.log(result);
//     if (result.error) {

//       var errorElement = document.getElementById('error-handler');
//       errorElement.textContent = result.error.message;
//     } else {

//       var errorElement = document.getElementById('success');
//       errorElement.style.display = "block";
//       var tokenElement = document.getElementById('token');
//       tokenElement.textContent = result.id;
//       console.log(result.id);
//     }
//   });
// });




























import React, { Component }  from "react";
import { GoSell } from "@tap-payments/gosell";

class GoSellDemo extends Component {

  constructor(props){
    super(props);
  }

  callbackFunc(response){
    console.log(response);
  }

  render() {

    return (
      <div className="App">

      <button onClick={() => GoSell.openLightBox()}>open goSell LightBox</button>
      <button onClick={() => GoSell.openPaymentPage()}>open goSell Page</button>

        <GoSell
           gateway={{
             publicKey:"pk_live_Sac9BvWURLDCpI74YZd2jhE5",
             language:"en",
             contactInfo:true,
             supportedCurrencies:"all",
             supportedPaymentMethods:"all",
             saveCardOption:true,
             customerCards: true,
             notifications:'standard',
             backgroundImg: {
              url: 'imgURL',
              opacity: '0.5'
            },
             callback: this.callbackFunc,
             labels:{
                 cardNumber:"Card Number",
                 expirationDate:"MM/YY",
                 cvv:"CVV",
                 cardHolder:"Name on Card",
                 actionButton:"Pay"
             },
             style: {
                 base: {
                   color: '#535353',
                   lineHeight: '18px',
                   fontFamily: 'sans-serif',
                   fontSmoothing: 'antialiased',
                   fontSize: '16px',
                   '::placeholder': {
                     color: 'rgba(0, 0, 0, 0.26)',
                     fontSize:'15px'
                   }
                 },
                 invalid: {
                   color: 'red',
                   iconColor: '#fa755a '
                 }
             }
           }}
           customer={{
             first_name: "First Name",
             middle_name: "Middle Name",
             last_name: "Last Name",
             email: "demo@email.com",
             phone: {
                 country_code: "965",
                 number: "99999999"
             }
           }}
           order={{
             amount: 100,
             currency:"AED",
             items:[{
               id:1,
               name:'item1',
               description: 'item1 desc',
               quantity:'x1',
               amount_per_unit:'KD00.000',
               discount: {
                 type: 'P',
                 value: '10%'
               },
               total_amount: 'KD000.000'
             },
            ],
             shipping:null,
             taxes: null
           }}
           transaction={{
             mode: 'charge',
             charge:{
              saveCard: false,
              threeDSecure: true,
              description: "Test Description",
              statement_descriptor: "Sample",
              reference:{
                transaction: "txn_0001",
                order: "ord_0001"
              }},
              metadata:{},
              receipt:{
                email: false,
                sms: true
              },
              redirect: "/redirect",
              post: null,
            }}
           />
      </div>
    );
  }
}

export default GoSellDemo;