import React, {useState , useEffect} from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
// import p1 from '../assets/images/payment-icon/1.png';
// import p2 from '../assets/images/payment-icon/2.png';
// import p3 from '../assets/images/payment-icon/3.png';
// import p4 from '../assets/images/payment-icon/4.png';
// import p5 from '../assets/images/payment-icon/5.png';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import StripeCheckout from "react-stripe-checkout"
// import axios from "axios"
// import logo1 from '../assets/images/logo1.png';

const Checkout = (props) => {
    const [fname , setfName ] = useState("")
    const [Lname , setLName ] = useState("")
    const [Address , setAddress ] = useState("")
    const [Address1 , setAddress1 ] = useState("")
    const [Country , setCountry ] = useState("")
    const [ZipPostal , setZipPostal ] = useState("1")
    const [Email , setEmail ] = useState("")
    const [Phone , setPhone ] = useState("")
    const [DIFFERENTfname , setDIFFERENTfName ] = useState("")
    const [DIFFERENTLname , setDIFFERENTLName ] = useState("")
    const [DIFFERENTAddress , setDIFFERENTAddress ] = useState("")
    const [DIFFERENTAddress1 , setDIFFERENTAddress1 ] = useState("")
    const [DIFFERENTZipPostal , setDIFFERENTZipPostal ] = useState("")
    const [DIFFERENTEmail , setDIFFERENTEmail ] = useState("")
    const [DIFFERENTPhone , setDIFFERENTPhone ] = useState("")
    const [admindataall , setadmindataall ] = useState(0)

    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])

    const [photo1 , setphoto1 ] = useState("")
    const [discount , setdiscount ] = useState({})
    const [photo2 , setphoto2 ] = useState("")

    const [Order_Notes , setOrder_Notes ] = useState("")
    const [City , setCity ] = useState("")
    const [DIFFERENTOrder_Notes , setDIFFERENTOrder_Notes ] = useState("")
    const [DIFFERENTCity , setDIFFERENTCity ] = useState("")
    const [paymenttype , setpaymenttype ] = useState("")
    const [cart , setCart] = useState([])
    const [tot , setot] = useState(0)
    const [total , settotal] = useState(0)
    const [doctor , setdoctor] = useState(0)

    const [display , setdisplay] = useState(true)
    const [play , setplay] = useState(false)
    const [customPhotojson , setcustomPhotojson] = useState(false)
    const [Num1111 , setNum1111] = useState(0)
    const [Num11 , setNum11] = useState(0)
    const [SubTotal , setSubTotal] = useState(0)
    const [chack, setchack] = useState(false) 
    const [chack1, setchack1] = useState(false) 
    const [paymenttype3, setpaymenttype3] = useState(false) 
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")




    useEffect(() =>{
        window.scrollTo(0, 0)
        // document.getElementById("c").style.display = "none"
        var data =  JSON.parse(localStorage.getItem("CarBookPric")) 
        // var data1 =  JSON.parse(localStorage.getItem("CartPrice")) 
        if (data) {
            setCart({...data,Price : parseInt(data.Price)})


        
        
            fetch("https://falcon-rides-main-back.vercel.app/AllUserCheckoutData",{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res181=>res181.json())
            .then(res191=>{
                // setNum1111(res191.length)
                let date = new Date()
                let day= date.getDate() 
                let month= (date.getUTCMonth()+1)
                let year= date.getUTCFullYear()
                if(date.getDate() < 10) day = "0"+ date.getDate()
                if(date.getUTCMonth()+1 < 10) month = "0"+ (date.getUTCMonth()+1)
                
                let c = day +""+month+ ""+(year -2000)
                const f = res191.filter((res,i)=>res.Date === c)
                console.log(f.length+1,c)
                setNum1111(f.length+2)
                setNum11(c)
            
            })
            // setot(JSON.parse(localStorage.getItem("CartPrice")) )
            // if(JSON.parse(localStorage.getItem("Custom Img")) )
            // setcustomPhotojson(true )

            // data.map((res,i)=>{
            //     if(res.Product_Catagories === "Custom Products"){
            //         setplay(true)
            //     }
            // })
            fetch("https://falcon-rides-main-back.vercel.app/AllUserCheckoutData",{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res181=>res181.json())
            .then(res191=>{
                setNum1111(res191.length)
            })
            
  fetch("https://falcon-rides-main-back.vercel.app/admindata",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res18=>res18.json())
.then(res19=>{
    setdoctor(res19[0].condition)
    console.log(res19[0].DeliveryCharges,JSON.parse(localStorage.getItem("CartPrice")));
  setadmindataall(res19[0].DeliveryCharges)

  let j = 0
//   if(JSON.parse(localStorage.getItem("CartPrice")) < res19[0].condition){
                // j = res19[0].DeliveryCharges
            // }
            // else{
            //     j = 0
            // }
            // data.map((item,i)=>{
            //    j = item.Total_Product_Price + j
            //    if (item.doctor_prescription){
            //     localStorage.setItem("doctor",JSON.stringify(true))
            //    }
            // })

            j = parseInt(data.Price)
            setSubTotal(j)
            // var doc =  JSON.parse(localStorage.getItem("doctor")) 
            // setdoctor(doc)

            if(JSON.parse(localStorage.getItem("discount")) && !JSON.parse(localStorage.getItem("discount")).discount2 ){
                setdiscount(JSON.parse(localStorage.getItem("discount")))
                var d = document.getElementById("discount")
                if(JSON.parse(localStorage.getItem("discount")).discount > 1){
                    d.innerHTML = " Rs : <b style='font-size:20px'></b>" +JSON.parse(localStorage.getItem("discount")).discount
                    // document.getElementById("ci11").style.visibility = "visible"
                    j = j - parseInt(JSON.parse(localStorage.getItem("discount")).discount)
                }
                else{
                    d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount1 +" % Off"
                    j =j - (j*(JSON.parse(localStorage.getItem("discount")).discount1)/100)
                    
                }
            }else{
                var d = document.getElementById("discount")
                d.innerHTML = "No discount"
                
            }
            
            
            if(JSON.parse(localStorage.getItem("discount")) && JSON.parse(localStorage.getItem("discount")).discount2 ){
                setdiscount(JSON.parse(localStorage.getItem("discount")))
            var d = document.getElementById("discount")
           
            d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount2 +" % Off"
            j =j - (j*(JSON.parse(localStorage.getItem("discount")).discount2)/100)
            


                }

              j =  j+  res19[0].DeliveryCharges



            setot(j)
            // var t = document.getElementById("subtotal")
            // t.innerHTML = "Rs "+j
            var f = document.getElementById("total")
            f.innerHTML = "Rs : " +j

        })

        }
        // console.log(total,cart,j)
   else{
    swal("Cart Is Empty")
    props.history.push("/")
            // var t = document.getElementById("subtotal")
            // t.innerHTML = "Rs "+0
            var f = document.getElementById("total")
            f.innerHTML = "Rs " +0
   }
  },[])
  

//   useEffect(() => {
  
//     return () => {
//         localStorage.removeItem("doctor")
//     }
//   }, [])


    const SubmitDataMessage1 = (e) =>{
        e.preventDefault()
             console.log(cart,6787 ,customPhotojson, play,tot ,JSON.parse(localStorage.getItem("CartPrice"))-tot , JSON.parse(localStorage.getItem("CartPrice")))
      



            if (cart){

              if(fname === ""){
                swal("Fill The Name");
                 return
             }
             if(Email === ""){
               setEmail("noemail@gmail.com")
              }
              if(Phone === ""){
                 swal("Fill The Phone");
                  return
              }
            
             if(Address === ""){
                swal("Fill The Address");
                 return
             }
             if(City === ""){
                swal("Fill The City");
                 return
             }

                setdisplay(false)
               let j
        if(admindataall > 0){
                 j = admindataall
            }
            else{
                 j = 0
            }
           
            let differnet = {
                DIFFERENTfname,
                DIFFERENTLname ,
                DIFFERENTAddress ,
                DIFFERENTAddress1 ,
                DIFFERENTZipPostal : 1111 ,
                DIFFERENTCity,
                DIFFERENTEmail ,
                DIFFERENTPhone ,
                DIFFERENTOrder_Notes,
            }
                
                console.log(differnet,                Num11," tfgrtg  ",Num1111,
                )



                let das = {}

                if(discount.discount){
                  if(discount.discount > 1)
                das= {
                  name : discount.discount ,
                  type : "R"
                }
                
                // else
                // t = t -  (t * (discount.discount1 / 100))
                
                }
                
                if(discount.discount1)
                das= {
                  name : discount.discount1 ,
                  type : "P"
                }
                
                
                // if(discount.discount2)
                // t = t -  (t * (discount.discount1 / 100))
                


// payment method here 





                    
                                fetch("https://falcon-rides-main-back.vercel.app/AllCheckoutBOokingCar",{
                        method: "POST",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                        body : JSON.stringify({
                            fname,
                            Lname ,
                            Address ,
                            Address1 ,
                            ZipPostal : 1111 ,
                            City,
                            Email ,
                            Das: das,
                            Phone ,
                            differnet ,
                            Order_Notes,
                            DeliveryCharges:j,
                            discount ,
                               AfterDiscount :  tot ,
                               LessAmount :  JSON.parse(localStorage.getItem("CartPrice"))-tot ,
                               TotalAmount  : JSON.parse(localStorage.getItem("CartPrice")) ,
                            
                            Order : cart,
                            Num123 : Num11+""+Num1111+1,
                        }),
                    }) 
                    .then(res=>res.json())
                    .then((res2)=>{        
                    if(!res2.Error){
                       
                        setdisplay(true)
                        swal("Booking placed Successfully.  Please make the payment to proceed with the booking. After transferring the payment, kindly send the screenshot to this WhatsApp number: +92 300 4421240.");
                       

                            setfName ("")
                            setLName ("" )
                            setAddress  ("")
                            setAddress1 ("" )
                            setZipPostal ("" )
                            setEmail ("" )
                            setCity ("" )
                            setPhone ("" )
                            setOrder_Notes ("")
                        localStorage.removeItem("CarBookPric")
                        localStorage.removeItem("doctor")
                        localStorage.removeItem("SearchData")
                        localStorage.removeItem("Data")
                        localStorage.removeItem("CartPrice")
                        localStorage.removeItem("CateProduct")
                        localStorage.removeItem("discount")
                        localStorage.removeItem("Custom Img")
                        props.history.push(`/thankyou`)

                     }
                     else{
                      console.log("3")
                        setdisplay(true)
                        swal("Plz Refresh And try Again the Order");
                     }
                  })
                  .catch(err=>{
                    setdisplay(true)
                    swal("There is an Error");
                })
            }
           
            else{
                swal("There is No Any Product in Your Cart");
                setTimeout(()=>{
                    
                    props.history.push(`/`)
                },2000)
            }
        // })
        // }
       
    }
  


    return (
      <div className="page-wrapper">
      
      <main className="main">
        <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
          <div className="container">
            <h1 className="page-title">Checkout<span></span></h1>
          </div>{/* End .container */}
        </div>{/* End .page-header */}
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Shop</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Checkout</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="page-content">
          <div className="checkout">
            <div className="container">
              <div className="checkout-discount">
                <form action="#">
                  <input type="text" className="form-control" required id="checkout-discount-input" />
                  <label htmlFor="checkout-discount-input" className="text-truncate">Have a coupon? <Link to="/cart"><span>Click here to enter your code</span></Link> </label>
                </form>
              </div>{/* End .checkout-discount */}
              <form onSubmit={(e)=>SubmitDataMessage1(e)}>
                <div className="row">
                  <div className="col-lg-9">
                    <h2 className="checkout-title">Billing Details</h2>{/* End .checkout-title */}
                    <div className="row">
                      <div className="col-sm-12">
                        <label>Full Name *</label>
                        <input type="text" className="form-control" required value={fname} onChange={(e)=>setfName(e.target.value)} placeholder="Enter Full Name"/>
                      </div>{/* End .col-sm-6 */}
                      {/* <div className="col-sm-6">
                        <label>Last Name *</label>
                        <input type="text" className="form-control" required />
                      </div> */}
                      {/* End .col-sm-6 */}
                    </div>{/* End .row */}
                    {/* <label>Company Name (Optional)</label>
                    <input type="text" className="form-control" /> */}
                    <label>Country *</label>
                    <input type="text" className="form-control" required  placeholder="Enter Country"  value={Country} onChange={(e)=>setCountry(e.target.value)} />
                    <label>Street address *</label>
                    <input type="text" className="form-control" required  placeholder="Enter Address including house no, Block and town"  value={Address} onChange={(e)=>setAddress(e.target.value)} />
                    <input type="text" className="form-control" placeholder="Appartments, suite, unit etc ..." required   value={Address1} onChange={(e)=>setAddress1(e.target.value)} />
                    <div className="row">
                      <div className="col-sm-12">
                        <label>Town / City *</label>
                        <input type="text" className="form-control" required  placeholder="Enter City" value={City} onChange={(e)=>setCity(e.target.value)}/>
                      </div>{/* End .col-sm-6 */}
                      {/* <div className="col-sm-6">
                        <label>State / County *</label>
                        <input type="text" className="form-control" required />
                      </div> */}
                      {/* End .col-sm-6 */}
                    </div>{/* End .row */}
                    <div className="row">
                      {/* <div className="col-sm-6">
                        <label>Postcode / ZIP *</label>
                        <input type="text" className="form-control" required  placeholder="Enter Postcode / ZIP Number"  value={ZipPostal} onChange={(e)=>setZipPostal(e.target.value)}/>
                      </div>End .col-sm-6 */}
                      <div className="col-sm-12">
                        <label>Phone *</label>
                        <input type="tel" className="form-control"  placeholder="Enter Mobile Number"  required value={Phone} onChange={(e)=>setPhone(e.target.value)}  />
                      </div>{/* End .col-sm-6 */}
                    </div>{/* End .row */}
                    <label>Email address *</label>
                    <input type="email" className="form-control"  placeholder="Enter E-mail" required  value={Email} onChange={(e)=>setEmail(e.target.value)}  />
                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="checkout-create-acc" />
                      <label className="custom-control-label" htmlFor="checkout-create-acc">Create an account?</label>
                    </div> */}
                    {/* End .custom-checkbox */}
                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="checkout-diff-address" />
                      <label className="custom-control-label" htmlFor="checkout-diff-address">Ship to a different address?</label>
                    </div> */}
                    {/* End .custom-checkbox */}
                    <label>Order notes (optional)</label>
                    <textarea className="form-control" cols={30} rows={4} placeholder="Notes about your order, e.g. special notes for delivery" defaultValue={""}  value={Order_Notes} onChange={(e)=>setOrder_Notes(e.target.value)} />
                  </div>{/* End .col-lg-9 */}
                  <aside className="col-lg-3">
                    <div className="summary">
                      <h3 className="summary-title">Your Order</h3>{/* End .summary-title */}
                      <table className="table table-summary">
                        <thead>
                          <tr>
                            <th>Car</th>
                            <th>Price</th>
                            <th>City</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                                                    cart
                                                    ?
                                                          <tr>
                                                            <td><a href="#">{cart.Car}   </a>   </td>
                                                            <th>Rs. {cart.Price}</th>
                                                            <td>From :  {cart.Pickup} <br/> To :  {cart.Drop}</td>
                                                          </tr>
                                                       :""
                                                          }
{/* //                                                           <tr>
//                                                           <td><a href="#">Beige knitted elastic runner shoes</a></td>
//                                                           <td>Rs. 84.00</td>
//                                                         </tr>



//                                                             <div className="d-flex justify-content-between">  
//                                                                 {item.Price[0] ?
//         <p> <div>
//         <span className='text-truncate' style={{color : "black",width: "142px"}}>{item.Product_Name}</span>
//         <span style={{paddingLeft  :"30px"}}>{item.Size.length > 2 ? "Color : " :  "Size : "}  {item.Size}</span>
//       </div></p>

//  :
//  <p className='text-truncate' style={{color : "black",width: "142px"}}> {item.Product_Name }</p>
// } 

// <p>x {item.Pieces}</p>
//                     <p>Rs {(item.Total_Product_Price).toFixed(0)}</p>
//                                                             </div>
                                                  
// )
//                                                     })
//                                                 } */}
                          {/* <tr>
                            <td><a href="#">Beige knitted elastic runner shoes</a></td>
                            <td>Rs. 84.00</td>
                          </tr>
                          <tr>
                            <td><a href="#">Blue utility pinafore denimdress</a></td>
                            <td>Rs. 76,00</td>
                          </tr> */}
                          <tr className="summary-subtotal">
                            <td>Subtotal:</td>
                            <td></td>
                            <td>Rs. {SubTotal}</td>
                          </tr>
                          
                          {/* End .summary-subtotal */}
                          <tr className="summary-subtotal">
                            <td></td>
                            <td></td>


                            <td>{ admindataall > 0 ?
                                                            "Rs : "+admindataall
                                                            : 
                                                            <b>Without Tax</b>
                                                         }</td>
                          </tr>
                          <tr className="summary-subtotal">
                            <td>Discount:</td>
                            <td></td>

                            <td id="discount"></td>
                          </tr>
                          <tr className="summary-total">
                            <td>Total:</td>
                            <td></td>

                            <td id="total">Rs. </td>
                          </tr>{/* End .summary-total */}
                        </tbody>
                      </table>{/* End .table table-summary */}
                      <div className="accordion-summary" id="accordion-payment">
                       
                        
                        <div className="card">
                          <div className="card-header" id="heading-3">
                          <h2 className="card-title">
                              <span style={{fontWeight : "bold", color : "black"}}>Note : (Booking is accepted only if you transfer the payment to the following account below.)</span>
                              <br />
                              <br />
                              {/* <a className="collapsed" role="button" value={true} data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3"> */}
                             <span style={{fontWeight : "500", color : "black"}}>
                              {/* <input type="radio" name="" id="" checked value={true} style={{marginRight: "10px"}}/> */}
                              <p>Bank Account : </p>
                              Bank Name: [Meezan bank ] <br />
                              Account Name: [Muhammad Azeem ] <br />
                              Account Number: [11490107597532]

                             </span>
                             <br />
                             <br />
                             <span style={{fontWeight : "500", color : "black"}}>
                              {/* <input type="radio" name="" id="" checked value={true} style={{marginRight: "10px"}}/> */}
                              <p>JazzCash Account :</p>

                               Name: [M.Asif] <br />
                               Number: [+92 300 4421240]

                             </span>


                              {/* </a> */}
                            </h2>
                          </div>{/* End .card-header */}
                          <div id="collapse-3" className="collapse" aria-labelledby="heading-3" data-parent="#accordion-payment">
                            <div className="card-body">Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. 
                            </div>{/* End .card-body */}
                          </div>{/* End .collapse */}
                        </div>{/* End .card */}
                        
                       
                      </div>{/* End .accordion */}
                      <button type="submit" className="btn btn-outline-primary-2 btn-order btn-block">
                        <span className="btn-text">Book Now</span>
                        <span className="btn-hover-text">Proceed to Checkout</span>
                      </button>
                    </div>{/* End .summary */}
                  </aside>{/* End .col-lg-3 */}
                </div>{/* End .row */}
              </form>
            </div>{/* End .container */}
          </div>{/* End .checkout */}
        </div>{/* End .page-content */}
      </main>{/* End .main */}
    </div>
        )
    
}

export default Checkout;