import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Rental Requirements</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "30px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                {/* <h2>This Privacy Policy</h2> */}
                                <h1>Rental Requirements</h1>

<p><strong>Driver’s License</strong></p>

<p><strong>UAE RESIDENTS</strong></p>
<p>All drivers with a UAE-issued Residence or Employment Visa must present a valid UAE driving license held for at least 12 months.</p>

<p><strong>TOURISTS AND VISITORS</strong></p>
<p>Citizens with driving licenses from the following countries may drive in the UAE using their foreign license with a valid visit or tourist visa. The original license should match the country of the customer's residence visa:</p>
<br />
<h3>  GCC COUNTRIES </h3>
  
<ul>
    <li>United Arab Emirates (UAE)</li>
    <li>Kingdom of Saudi Arabia</li>
    <li>Oman</li>
    <li>Bahrain</li>
    <li>Kuwait</li>
    <li>Qatar</li>
 
</ul>
<br />
  <h3>EUROPEAN COUNTRIES</h3> 
<ul>
 
    <li>Germany</li>
    <li>Greece</li>
    <li>France</li>
    <li>Italy</li>
    <li>Sweden</li>
    <li>United Kingdom</li>
    <li>Switzerland</li>
    <li>Belgium</li>
    <li>Norway</li>
    <li>Poland</li>
    <li>Ireland</li>
    <li>Romania</li>
    <li>Slovakia</li>
    <li>Turkey</li>
    <li>Luxembourg</li>
    <li>Finland</li>
    <li>Denmark</li>
    <li>Spain</li>
    <li>Portugal</li>
    <li>Netherlands</li>
    <li>Austria</li>
    <li>Cyprus</li>
    <li>Holland</li>
    <li>Iceland</li>
    <li>Hungary</li>
    <li>Bulgaria</li>
    <li>Serbia</li>
    <li>Lithuania</li>
    <li>Latvia</li>
    <li>The Republic of Montenegro</li>
 </ul>
<br />
<h3>
   NORTH AMERICAN COUNTRIES

</h3>
 <ul>
   
   
    <li>United States of America</li>
    <li>Quebec State in Canada</li>
    
</ul>

<br />
<h3>

   ASIAN COUNTRIES 
</h3>

<ul>
    
    <li>Japan</li>
    <li>Israel</li>
    <li>People’s Republic of China</li>
    <li>Australia</li>
    <li>New Zealand</li>
    <li>Hong Kong</li>
    <li>South Korea</li>
    <li>Singapore</li>
    <li>South Africa</li>
  
</ul>

<p>Customers from Japan, Turkey, Greece, and South Korea are requested to provide a translation of their driver’s license approved by their consulate or embassy.</p>

<p>Drivers from countries not listed above may rent a vehicle with a valid International Driver’s Permit (IDP) and a foreign driver’s license held for at least 12 months.</p>

<p><em>Important: Please note that Driving License Requirement details are subject to updates. While we strive to provide the most current information, Falcon Rides does not take responsibility for its accuracy. Refer to the UAE Government website for the latest details.</em></p>
<br />
<p><strong>Passport Information</strong></p>
<p>For all UAE residents, a copy of their passport (photo ID page and visa page) is required at the time of rental. Tourists and visitors must provide a valid UAE entry visa along with a passport.</p>

<p>Flacon Rides will ask for copies of the passport and visa during booking to avoid issues at the collection stage.</p>
<br />
<p><strong>Method of Payment</strong></p>
<p>A valid credit card in the renter’s name is required. At vehicle pick-up, estimated rental charges are taken upfront, and a pre-authorization is done according to the contract terms. Debit cards are not accepted for security blocking but can be used for payments. All major credit cards are accepted.</p>

<p><em>Credit cards are our preferred mode of payment; however, cash and debit cards may be used at some Falcon Rides counters when settling dues.</em></p>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;