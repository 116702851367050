import AudiA1 from "../assets/images/1.png";
import Golf6 from "../assets/images/2.png";
import Toyota from "../assets/images/3.png";
import Benz from "../assets/images/4.png";
import Bmw320 from "../assets/images/5.png";
import Passat from "../assets/images/6.png";

export const CAR_DATA = [
  [
    {
      name: "Hyundai Creta 2023",
      price: "120",
      img: Golf6,
      model: "Hyundai",
      // mark: "Volkswagen",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Hyundai Grand i10 2023",
      price: "170",
      img: AudiA1,
      model: "Hyundai",
      // mark: "A1",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "KIA Pegas 2023 Model",
      price: "120",
      img: Toyota,
      model: "KIA",
      // mark: "Toyota",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Hyundai Grand i10 2022",
      price: "100",
      img: Bmw320,
      model: "Hyundai",
      // mark: "BMW",
      year: "2022",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "MG5 2022 Model",
      price: "130",
      img: Benz,
      model: "MG5",
      // mark: "Mercedes",
      year: "2022",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "MG ZS 2023",
      price: "140",
      img: Passat,
      model: "MG",
      // mark: "Volkswagen",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
];
