import { Link } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import { useState } from "react";
// import { IconMenu2, IconX } from "@tabler/icons-react";
import logo1 from '../assets/images/logo-3.png';


// import StreamIcon from '@material-ui/icons/Stream';
import MenuIcon from '@material-ui/icons/Menu';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Popup from './Popup';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';



function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };


  return (
    <>


{/* 

<button onClick={()=>handleShowPopup()}>Show Popup</button>
      {showPopup && (
        // <Popup imageUrl={imageUrl} onClose={handleClosePopup} />


        <div className="popup-container">
      <div className="popup-content">
        <img src={imageUrl} alt="Popup Image" />
        <span className="close-icon" onClose={handleClosePopup}>&times;</span>
      </div>
    </div>



      )} */}














{/* 

<div class="popup-container">
  <div class="popup-content">
    <img src="your-image-url.jpg" alt="Popup Image">
    <span class="close-icon">&times;</span>
  </div>
</div>



 */}









      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <Brightness7Icon width={30} height={30}  style={{fontSize: "34px"}}/>
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/categories/Cars/all-products">
             Rental Fleet
              </Link>
            </li>
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/testimonials">
                Testimonials
              </Link>
            </li>
            {/* <li>
              <Link className="fgfgfg" onClick={openNav} to="/team">
                Our Team
              </Link>
            </li> */}
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={logo1} className="der1" alt="logo-img" style={{width: "290px",
    height: "125px",
    maxWidth: "190px"}}/>
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
              <Link className="home-link fgfgfg"  to="/">
                Home
              </Link>
            </li>
            <li>
              {" "}
              <Link className="about-link fgfgfg" to="/about">
                About
              </Link>
            </li>
            <li>
              {" "}
              <Link className="models-link fgfgfg" to="/categories/Cars/all-products">
               Rental Fleet
              </Link>
            </li>
            <li>
              {" "}
              <Link className="testi-link fgfgfg" to="/testimonials">
                Testimonials
              </Link>
            </li>
            {/* <li>
              {" "}
              <Link className="team-link fgfgfg" fgfgfg to="/team">
                Our Team
              </Link>
            </li> */}
            <a class="dropdown1234567">
            <Link class="dropbtn">Pages
            <KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}}/> 
            </Link>
            <div class="dropdown1234567-content">
              {/* <Link to="/luxury-cars">Luxury Cars</Link>
              <Link to="/standard-cars">Standard Cars</Link>
              <Link to="/suv-cars">SUV Cars</Link>
              <Link to="/budget-cars">Budget Cars</Link>
              <Link to="/coasters-cars">Coasters Cars</Link>
              <Link to="/one-way-cars">One-Way Cars</Link>
              <Link to="/monthly-cars">Monthly Rental Cars</Link>
              <Link to="/tourism">Travel & Tourism</Link> */}
              
<Link to="/budget-friendly-car-rental-in-karama-by-quick-lease">
    Budget Friendly Car Rental In Karama By Quick Lease
</Link>

<Link to="/our-car-rental-fleet-per-day-aed-100-per-week-aed-1000-per-month-aed-1536">
    Our Car Rental Fleet Per Day AED 100 Per Week AED 1000 Per Month AED 1536
</Link>

<Link to="/rent-a-car-karama-hire-quality-cars-at-cost-effective">
    Rent A Car Karama Hire Quality Cars At Cost Effective
</Link>

<Link to="/car-rental-in-al-karama-hire-cheap-car-in-al-karama">
    Car Rental In Al Karama Hire Cheap Car In Al Karama
</Link>

<Link to="/rent-a-car-in-karama-in-uae-on-the-map-celia-car-rental">
    Rent A Car In Karama In UAE On The Map Celia Car Rental
</Link>

<Link to="/rent-a-car-in-al-karama-with-great-rent-a-car">
    Rent A Car In Al Karama With Great Rent A Car
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-cheap-car-hire-dubai">
    Rent A Car In Al Karama Dubai Cheap Car Hire Dubai
</Link>

<Link to="/cheap-car-rental-al-karama-rent-a-cars-royal-dubai-uae">
    Cheap Car Rental Al Karama Rent A Cars Royal Dubai UAE
</Link>

<Link to="/car-rental-karama-dubai-united-arab-emirates">
    Car Rental Karama Dubai United Arab Emirates
</Link>

<Link to="/rent-a-car-karama-dubai-daily-and-long-term-car-hire-al-karama">
    Rent A Car Karama Dubai Daily And Long Term Car Hire Al Karama
</Link>

<Link to="/luxury-car-rental-al-karama-rent-a-car-royal-dubai-uae">
    Luxury Car Rental Al Karama Rent A Car Royal Dubai UAE
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-best-deals-and-offers">
    Rent A Car In Al Karama Dubai Best Deals And Offers
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-affordable-reliable">
    Rent A Car In Al Karama Dubai Affordable Reliable
</Link>

<Link to="/do-you-reside-in-al-karama-and-wish-to-hire-a-car-to-make-your-travels-easier?">
    Do You Reside In Al Karama And Wish To Hire A Car To Make Your Travels Easier?
</Link>

<Link to="/best-deals-on-car-rentals-in-al-karama-dubai-daily-weekly-and-monthly-rates-available-book-now!">
    Best Deals On Car Rentals In Al Karama Dubai Daily Weekly And Monthly Rates Available Book Now!
</Link>

<Link to="/helps-you-to-find-perfect-car-for-your-next-ride-with-rent-a-car-dubai-in-al-karama">
    Helps You To Find Perfect Car For Your Next Ride With Rent A Car Dubai In Al Karama
</Link>

<Link to="/rent-a-car-at-cheap-price-in-al-karama">
    Rent A Car At Cheap Price In Al Karama
</Link>

<Link to="/rent-a-car-has-a-unique-list-of-cars-available-for-rent-in-al-karama-dubai!">
    Rent A Car Has A Unique List Of Cars Available For Rent In Al Karama Dubai!
</Link>

<Link to="/cheap-car-rental-in-al-karama-dubai-uae-price-from-aed-90-per-day">
    Cheap Car Rental In Al Karama Dubai UAE Price From AED 90 Per Day
</Link>

<Link to="/find-cheap-car-rental-deals-for-karama-at-falcon-rides-com">
    Find Cheap Car Rental Deals For Karama At Falcon Rides Com
</Link>

<Link to="/looking-for-a-car-rental-company-in-karama-dubai">
    Looking For A Car Rental Company In Karama Dubai
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-discover-the-vibrant-neighborhood-of-al-karama-with-our-affordable-car-rental-options">
    Rent A Car In Al Karama Dubai Discover The Vibrant Neighborhood Of Al Karama With Our Affordable Car Rental Options
</Link>

<Link to="/the-cost-of-renting-a-car-in-al-karama-varies-depending-on-the-type-of-vehicle-you-choose-and-the-rental-duration">
    The Cost Of Renting A Car In Al Karama Varies Depending On The Type Of Vehicle You Choose And The Rental Duration
</Link>

<Link to="/renting-a-car-in-al-karama-with-car-rental-dbx-is-not-only-convenient-but-also-remarkably-affordable">
    Renting A Car In Al Karama With Car Rental DBX Is Not Only Convenient But Also Remarkably Affordable
</Link>

      








        <Link to="/rent-a-car-in-dubai-online-at-affordable-prices">
Rent a Car in Dubai Online at Affordable Prices
</Link>

<Link to="/rent-a-car-dubai-economy-and-luxury-car-rental-uae">
Rent a Car Dubai | Economy and Luxury Car Rental UAE
</Link>

<Link to="/self-drive-cars-in-dubai-car-rental-services">
Self Drive Cars in Dubai | Car Rental Services
</Link>

<Link to="/dubai-international-airport-term-1-car-rental">
dubai international airport term 1 - Car Rental
</Link>

<Link to="/rent-a-car-cheap-car-rental-car-hire-in-dubai-uae">
Rent a Car | Cheap Car Rental | Car Hire in Dubai UAE
</Link>

<Link to="/car-rental-dubai-search-cheap-rental-car-deals">
Car Rental Dubai – Search Cheap Rental Car Deals
</Link>

<Link to="/cheap-car-rental-in-dubai-find-and-compare-deals">
Cheap car rental in Dubai: Find and compare deals
</Link>

<Link to="/weekly-car-rental-in-dubai-rent-a-car-with-no-hidden-charges">
Weekly Car Rental In Dubai - Rent A Car With No Hidden Charges
</Link>

<Link to="/car-rental-rent-a-car-dubai-at-lowest-price">
Car Rental | Rent a Car Dubai at lowest price
</Link>

<Link to="/rent-a-car-in-dubai-car-rental-agency-in-uae">
Rent A Car In Dubai - Car Rental Agency in UAE
</Link>

<Link to="/rent-a-car-in-dubai-car-rental-dubai">
Rent a Car in Dubai - Car Rental Dubai
</Link>

<Link to="/rent-a-car-dubai-best-car-rental-offers-monthly-and-daily">
Rent a car Dubai | Best car rental offers Monthly and Daily
</Link>

<Link to="/rent-a-car-al-karama-cheap-car-rental-hire-in-dubai">
Rent a car Al Karama, Cheap Car Rental & Hire in Dubai
</Link>

<Link to="/rent-a-car-karama-at-cheapest-rates-falcon-rides-car-rental-dubai">
Rent A Car Karama At Cheapest Rates - Falcon Rides Car Rental Dubai
</Link>

<Link to="/affordable-car-rental-al-karama-dubai">
Affordable Car Rental Al Karama - Dubai
</Link>

          <Link to="/rent-a-car-UAE">
            Rent a Car UAE
           
          </Link>
         
          <Link to="/rent-a-car-dubai">
            Rent a Car Dubai
           
          </Link>
          <Link to="/rent-a-car-al-karama">
            Rent a Car Al Karama Dubai
           
          </Link>

          <Link to="/rent-a-car-al-quoz">

            Rent a Car Al Quoz
            
          </Link>
          <Link to="/rent-a-car-al-Satwa">

            Rent a Car Al Satwa
           
          </Link>
          <Link to="/rent-a-car-burj-al-arab">
            Rent a Car Burj Al Arab
           
          </Link>

          <Link to="/rent-a-car-burj-khalifa">

            Rent a Car Burj Khalifa
            
          </Link>
          <Link to="/rent-a-car-dubai-frame">

            Rent a Car Dubai Frame
           
          </Link>
          <Link to="/rent-a-car-dubai-marina">
            Rent a Car Dubai Marina
           
          </Link>

          <Link to="/rent-a-car-emirates-hills">

            Rent a Car Emirates Hills
            
          </Link>
          <Link to="/rent-a-car-jumeirah-beach">

            Rent a Car Jumeirah Beach
           
          </Link>
          <Link to="/rent-a-car-palm-jumeirah">

            Rent a Car Palm Jumeirah
           
          </Link>
          <Link to="/rent-a-car-dubai-downtown">

            Rent a Car Dubai Downtown
            
          </Link>
          <Link to="/rent-a-car-Deira">

            Rent a Car Deira
           
          </Link>
          <Link to="/rent-a-car-dubai-hills">

            Rent a Car Dubai Hills
           
          </Link>
          <Link to="/rent-a-car-sheikh-zayed-road">

            Rent a Car Sheikh Zayed Road
           
          </Link>
          <Link to="/rent-a-car-dubai-silicon-oasis">

            Rent a Car Dubai Silicon Oasis
           
          </Link>
          <Link to="/rent-a-car-Abu-Dhabi">

            Rent a Car Abu Dhabi
           
          </Link>
          <Link to="/rent-a-car-Ajman">

            Rent a Car Ajman
           
          </Link>
          <Link to="/rent-a-car-Dubai-Fountain">

            Rent a Car Dubai Fountain
           
          </Link>
          <Link to="/rent-a-car-Ski-Dubai">

            Rent a Car Ski Dubai
           
          </Link>
          
          <Link to="/rent-a-car-Dubai-Miracle-Garden">

            Rent a Car Dubai Miracle Garden
           
          </Link>
          
          <Link to="/rent-a-car-Sharjah">

            Rent a Car Sharjah
           
          </Link>
          
          <Link to="/rent-a-car-Fujairah">

            Rent a Car Fujairah
           
          </Link>
          
          <Link to="/rent-a-car-Al-Ain">

            Rent a Car Al Ain
           
          </Link>
          
          <Link to="/rent-a-car-Desert-Safari">

            Rent a Car Desert Safari
           
          </Link>
          
          <Link to="/rent-a-car-Ras-Al-Khaimah">

            Rent a Car Ras Al Khaimah
           
          </Link>
          
          <Link to="/rent-a-car-Umm-Al-Quwain">

            Rent a Car Umm Al Quwain
           
          </Link>
          
          <Link to="/rent-a-car-One-&-Only-One-Za'abeel">

            Rent a Car One & Only One Za'abeel
           
          </Link>
          






















          <Link to="/rent-a-car-service-in-UAE">
            Rent a Car Service in UAE
           
          </Link>
          <Link to="/rent-a-car-in-UAE">
            Rent a Car Service in UAE
           
          </Link>
          <Link to="/rent-a-car-dubai">
            Rent a Car Dubai
           
          </Link>
          <Link to="/rent-a-car-service-in-al-karama">
            Rent a Car Service in Al Karama Dubai
           
          </Link>

          <Link to="/rent-a-car-service-in-al-quoz">

            Rent a Car Service in Al Quoz
            
          </Link>
          <Link to="/rent-a-car-service-in-al-Satwa">

            Rent a Car Service in Al Satwa
           
          </Link>
          <Link to="/rent-a-car-service-in-burj-al-arab">
            Rent a Car Service in Burj Al Arab
           
          </Link>

          <Link to="/rent-a-car-service-in-burj-khalifa">

            Rent a Car Service in Burj Khalifa
            
          </Link>
          <Link to="/rent-a-car-service-in-dubai-frame">

            Rent a Car Service in Dubai Frame
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-marina">
            Rent a Car Service in Dubai Marina
           
          </Link>

          <Link to="/rent-a-car-service-in-emirates-hills">

            Rent a Car Service in Emirates Hills
            
          </Link>
          <Link to="/rent-a-car-service-in-jumeirah-beach">

            Rent a Car Service in Jumeirah Beach
           
          </Link>
          <Link to="/rent-a-car-service-in-palm-jumeirah">

            Rent a Car Service in Palm Jumeirah
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-downtown">

            Rent a Car Service in Dubai Downtown
            
          </Link>
          <Link to="/rent-a-car-service-in-Deira">

            Rent a Car Service in Deira
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-hills">

            Rent a Car Service in Dubai Hills
           
          </Link>
          <Link to="/rent-a-car-service-in-sheikh-zayed-road">

            Rent a Car Service in Sheikh Zayed Road
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-silicon-oasis">

            Rent a Car Service in Dubai Silicon Oasis
           
          </Link>
          <Link to="/rent-a-car-service-in-Abu-Dhabi">

            Rent a Car Service in Abu Dhabi
           
          </Link>
          <Link to="/rent-a-car-service-in-Ajman">

            Rent a Car Service in Ajman
           
          </Link>
          <Link to="/rent-a-car-service-in-Dubai-Fountain">

            Rent a Car Service in Dubai Fountain
           
          </Link>
          <Link to="/rent-a-car-service-in-Ski-Dubai">

            Rent a Car Service in Ski Dubai
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Dubai-Miracle-Garden">

            Rent a Car Service in Dubai Miracle Garden
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Sharjah">

            Rent a Car Service in Sharjah
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Fujairah">

            Rent a Car Service in Fujairah
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Al-Ain">

            Rent a Car Service in Al Ain
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Desert-Safari">

            Rent a Car Service in Desert Safari
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Ras-Al-Khaimah">

            Rent a Car Service in Ras Al Khaimah
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Umm-Al-Quwain">

            Rent a Car Service in Umm Al Quwain
           
          </Link>
          
          <Link to="/rent-a-car-service-in-One-&-Only-One-Za'abeel">

            Rent a Car Service in One & Only One Za'abeel
           
          </Link>
          
























          <Link to="/car-rental-service-in-UAE">
            Car Rental Service in UAE
           
          </Link>
          <Link to="/car-rental-UAE">
            Car Rental Service in UAE
           
          </Link>
          <Link to="/carrental-dubai">
            Car Rental Dubai
           
          </Link>
          <Link to="/car-rental-service-in-al-karama">
            Car Rental Service in Al Karama Dubai
           
          </Link>

          <Link to="/car-rental-service-in-al-quoz">

            Car Rental Service in Al Quoz
            
          </Link>
          <Link to="/car-rental-service-in-al-Satwa">

            Car Rental Service in Al Satwa
           
          </Link>
          <Link to="/car-rental-service-in-burj-al-arab">
            Car Rental Service in Burj Al Arab
           
          </Link>

          <Link to="/car-rental-service-in-burj-khalifa">

            Car Rental Service in Burj Khalifa
            
          </Link>
          <Link to="/car-rental-service-in-dubai-frame">

            Car Rental Service in Dubai Frame
           
          </Link>
          <Link to="/car-rental-service-in-dubai-marina">
            Car Rental Service in Dubai Marina
           
          </Link>

          <Link to="/car-rental-service-in-emirates-hills">

            Car Rental Service in Emirates Hills
            
          </Link>
          <Link to="/car-rental-service-in-jumeirah-beach">

            Car Rental Service in Jumeirah Beach
           
          </Link>
          <Link to="/car-rental-service-in-palm-jumeirah">

            Car Rental Service in Palm Jumeirah
           
          </Link>
          <Link to="/car-rental-service-in-dubai-downtown">

            Car Rental Service in Dubai Downtown
            
          </Link>
          <Link to="/car-rental-service-in-Deira">

            Car Rental Service in Deira
           
          </Link>
          <Link to="/car-rental-service-in-dubai-hills">

            Car Rental Service in Dubai Hills
           
          </Link>
          <Link to="/car-rental-service-in-sheikh-zayed-road">

            Car Rental Service in Sheikh Zayed Road
           
          </Link>
          <Link to="/car-rental-service-in-dubai-silicon-oasis">

            Car Rental Service in Dubai Silicon Oasis
           
          </Link>
          <Link to="/car-rental-service-in-Abu-Dhabi">

            Car Rental Service in Abu Dhabi
           
          </Link>
          <Link to="/car-rental-service-in-Ajman">

            Car Rental Service in Ajman
           
          </Link>
          <Link to="/car-rental-service-in-Dubai-Fountain">

            Car Rental Service in Dubai Fountain
           
          </Link>
          <Link to="/car-rental-service-in-Ski-Dubai">

            Car Rental Service in Ski Dubai
           
          </Link>
          
          <Link to="/car-rental-service-in-Dubai-Miracle-Garden">

            Car Rental Service in Dubai Miracle Garden
           
          </Link>
          
          <Link to="/car-rental-service-in-Sharjah">

            Car Rental Service in Sharjah
           
          </Link>
          
          <Link to="/car-rental-service-in-Fujairah">

            Car Rental Service in Fujairah
           
          </Link>
          
          <Link to="/car-rental-service-in-Al-Ain">

            Car Rental Service in Al Ain
           
          </Link>
          
          <Link to="/car-rental-service-in-Desert-Safari">

            Car Rental Service in Desert Safari
           
          </Link>
          
          <Link to="/car-rental-service-in-Ras-Al-Khaimah">

            Car Rental Service in Ras Al Khaimah
           
          </Link>
          
          <Link to="/car-rental-service-in-Umm-Al-Quwain">

            Car Rental Service in Umm Al Quwain
           
          </Link>
          
          <Link to="/car-rental-service-in-One-&-Only-One-Za'abeel">

            Car Rental Service in One & Only One Za'abeel
           
          </Link>
          
























          <Link to="/rent-a-car-deira-cheap-car-rental-hire-in-dubai-uae">
Rent a car Deira, Cheap Car Rental & Hire in Dubai, UAE
</Link>

<Link to="/low-cost-car-rental-deira-dubai">
Low-Cost Car Rental Deira - Dubai
</Link>

<Link to="/car-rentals-in-deira-for-aed-75-day">
Car Rentals In Deira For AED 75/Day
</Link>

<Link to="/rent-a-car-deira-budget-car-rentals-deira">
Rent a Car Deira | Budget Car Rentals Deira
</Link>

<Link to="/car-rentals-in-deira-dubai-falcon-rides">
Car Rentals in Deira (Dubai) - Falcon Rides
</Link>

<Link to="/rent-a-car-in-deira-dubai">
Rent a Car in Deira Dubai
</Link>

<Link to="/cheap-car-hire-in-deira-dubai-from-20-day">
Cheap car hire in Deira, Dubai from £20/day
</Link>

<Link to="/rent-a-car-deira-car-hire-available-for-aed-72-day">
Rent a Car Deira | Car Hire Available for AED 72/Day
</Link>

<Link to="/car-rental-from-deira-city-center-dubai">
Car Rental from Deira City Center - Dubai
</Link>

<Link to="/rent-a-car-in-dubai-deira-cheap-car-rental-and-hire-in-deira">
Rent a Car in Dubai Deira | Cheap Car rental and hire in Deira
</Link>

<Link to="/cheap-rent-a-car-deira-dubai">
Cheap Rent A Car Deira Dubai
</Link>

<Link to="/best-car-rent-in-deira-hire-self-drive-cars-deposit-free">
Best Car Rent in Diera - Hire Self Drive Cars Deposit Free
</Link>




<Link to="/rent-a-car-jumeirah-beach-residence">
Rent a Car Jumeirah Beach Residence
</Link>

<Link to="/car-rental-jumeirah-dubai">
Car Rental Jumeirah Dubai
</Link>

<Link to="/car-rentals-in-jumeirah-dubai-from-34-day">
Car Rentals in Jumeirah (Dubai) from $34/day
</Link>

<Link to="/rent-a-car-at-jumeirah-beach-residence">
Rent a Car at Jumeirah Beach Residence
</Link>

<Link to="/rent-a-car-in-jumeirah-village-circle-dubai">
Rent a Car in Jumeirah Village Circle, Dubai
</Link>

<Link to="/rent-a-car-jumeirah-beach-residence-dubai">
Rent A Car Jumeirah Beach Residence - Dubai
</Link>

<Link to="/pearl-jumeirah-rent-a-car-dubai-from-aed-40-day">
Pearl Jumeirah Rent A Car Dubai From AED 40/Day
</Link>

<Link to="/car-rental-in-palm-jumeirah-dubai">
Car rental in Palm Jumeirah - Dubai
</Link>

<Link to="/cheap-car-hire-in-jumeirah-beach-residence">
Cheap Car Hire in Jumeirah Beach Residence
</Link>

<Link to="/rent-a-car-in-jumeirah-village-circle-jvc-dubai">
Rent a Car in Jumeirah Village Circle (JVC) - Dubai
</Link>

<Link to="/car-rental-in-jumeirah-dubai">
Car Rental in Jumeirah, Dubai
</Link>

<Link to="/cheap-car-rentals-in-jumeirah-from-just-32">
Cheap Car Rentals in Jumeirah from just $32
</Link>

<Link to="/best-car-rent-in-jumeirah-hire-self-drive-cars">
Best Car Rent in Jumeirah - Hire Self Drive Cars
</Link>

<Link to="/car-rental-deals-at-hilton-dubai-jumeirah">
Car Rental Deals at Hilton Dubai Jumeirah
</Link>

<Link to="/cheap-car-hire-in-jumeirah-dubai-from-31-day">
Cheap car hire in Jumeirah, Dubai from £31/day
</Link>

<Link to="/weekly-car-rental-jumeirah-1-dubai">
Weekly Car Rental Jumeirah 1 - Dubai
</Link>

<Link to="/cheap-rental-cars-jumeirah-beach-residence-book-now">
Cheap Rental Cars Jumeirah Beach Residence Book Now!
</Link>

<Link to="/rent-a-car-in-palm-jumeirah">
Rent a Car in Palm Jumeirah
</Link>






<Link to="/car-hire-in-al-quoz-dubai-from-aed-157-day">
Car Hire in Al Quoz (Dubai) from AED 157/day
</Link>

<Link to="/rent-a-car-al-quoz-best-car-rental-offers-al-quoz">
Rent a Car Al Quoz | Best Car Rental Offers Al Quoz
</Link>

<Link to="/cost-effective-car-rental-al-quoz-dubai">
Cost-Effective Car Rental Al Quoz - Dubai
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai-from-109-aed">
Rent A Car In Al Quoz Dubai From 109 AED
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai">
Rent a Car in Al Quoz, Dubai
</Link>

<Link to="/rent-a-car-al-quoz-cheap-car-rentals-near-me">
Rent A Car Al Quoz | Cheap Car Rentals Near Me
</Link>

<Link to="/car-rentals-in-al-quoz-dubai-from-36-day">
Car Rentals in Al Quoz (Dubai) from $36/day
</Link>

<Link to="/car-rental-in-dubai-al-quoz-branch">
Car rental in Dubai Al Quoze Branch
</Link>

<Link to="/rent-car-al-quoz-dubai">
Rent car Al Quoz - Dubai
</Link>

<Link to="/cheap-car-rentals-in-al-quoz-dubai-from-45-day">
Cheap Car Rentals in Al Quoz, Dubai from $45/day
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai">
Rent a Car in Al Quoz - Dubai
</Link>

<Link to="/rent-a-car-al-quoz-find-special-car-hire-discounts">
Rent a Car Al Quoz | Find Special Car Hire Discounts
</Link>

<Link to="/hire-car-al-quoz-dubai">
Hire Car Al Quoz - Dubai
</Link>

<Link to="/best-car-rent-in-al-quoz-dubai">
Best Car Rent in Al Quoz Dubai
</Link>

<Link to="/car-rental-dubai-al-quoz-uae-special-discounts-offers">
Car Rental Dubai Al Quoz, UAE - Special Discounts & Offers
</Link>

<Link to="/affordable-car-rental-services-near-al-quoz-mall">
Affordable Car Rental Services Near Al Quoz Mall
</Link>

<Link to="/rent-a-car-in-al-quoz-mall-dubai-best-deals-and-offers">
Rent a Car in Al Quoz Mall, Dubai - Best Deals and Offers
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai">
Rent a Car in Al Quoz, Dubai
</Link>

<Link to="/car-rental-companies-dubai-rent-a-luxury-car-with-driver">
Car Rental Companies Dubai - Rent a Luxury Car with Driver
</Link>

<Link to="/rent-a-car-al-quoz-mall-best-deals-offers">
Rent A Car Al Quoz Mall | Best Deals & Offers
</Link>

<Link to="/car-rentals-in-al-quoz-dubai">
Car rentals in Al Quoz, Dubai
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai-cheap-car-hire">
Rent a Car in Al Quoz, Dubai, Cheap Car Hire
</Link>

<Link to="/rent-a-car-dubai-best-car-rental-services">
Rent a Car Dubai | Best Car Rental Services
</Link>

<Link to="/car-hire-leasing-al-quoz">
Car Hire & Leasing Al Quoz
</Link>

<Link to="/car-rental-al-quoz-rent-a-car-dubai-at-lowest-price">
Car Rental Al Quoz | Rent a Car Dubai at lowest price
</Link>


<Link to="/car-rentals-in-ras-al-khor-dubai-falcon-rides">
Car rentals in Ras Al Khor, Dubai - Falcon Rides
</Link>

<Link to="/ras-al-khor-rent-a-car-dubai-with-falcon-rides">
Ras Al Khor Rent A Car Dubai With Falcon Rides
</Link>

<Link to="/rent-a-car-ras-al-khor-travel-with-ease-quick-lease">
Rent a car Ras Al Khor | Travel with ease | Quick Lease
</Link>

<Link to="/rent-a-car-in-ras-al-khor-dubai-best-deals-and-offers">
Rent a Car in Ras Al Khor, Dubai - Best Deals and Offers
</Link>

<Link to="/premium-car-rental-service-ras-al-khor-dubai">
Premium Car Rental Service Ras Al Khor - Dubai
</Link>

<Link to="/21-dollar-car-rental-ras-al-khor-industrial-area">
$21 Car Rental Ras Al Khor Industrial Area
</Link>

<Link to="/rent-a-car-in-ras-al-khor-dubai">
Rent a car in Ras Al Khor - Dubai
</Link>

<Link to="/rent-a-car-in-ras-al-khor">
Rent a car in Ras Al Khor
</Link>

<Link to="/rent-a-car-ras-al-khor-dubai">
Rent a Car Ras Al Khor - Dubai
</Link>

<Link to="/rent-a-car-ras-al-khor-travel-with-ease">
Rent a car Ras Al Khor | Travel with ease
</Link>

<Link to="/rent-a-car-in-ras-al-khor-car-rental-in-dubai">
Rent a Car in Ras al Khor - car rental in Dubai
</Link>

<Link to="/cheap-rental-cars-ras-al-khor-industrial-area-book-now">
Cheap Rental Cars Ras Al Khor Industrial Area Book Now!
</Link>

<Link to="/car-rent-in-ras-al-khor-under-140-aed-with-great-dubai">
Car Rent in Ras Al Khor Under 140 AED With Great Dubai
</Link>

<Link to="/rent-a-car-cheap-car-rental-hire-in-ras-al-khor-uae">
Rent a car, Cheap Car Rental & Hire in Ras Al Khor, UAE
</Link>

<Link to="/best-rent-a-car-ras-al-khor">
Best Rent a Car – Ras Al Khor
</Link>

<Link to="/book-a-rent-a-car-in-dubai-weekly-monthly-cheap">
Book a Rent a Car in Dubai | Weekly, Monthly Cheap
</Link>

<Link to="/rent-a-car-in-dubai-ras-al-khor">
Rent a Car in Dubai Ras Al Khor
</Link>

<Link to="/low-cost-car-rental-deals-in-dubai-ras-al-khor-uae">
Low-cost Car Rental Deals in Dubai Ras Al Khor, UAE 🔥
</Link>

<Link to="/budget-car-rental-ras-al-khor-hire-economy-cars-for-rent-in-dubai">
Budget Car Rental Ras al Khor Hire Economy Cars for Rent in Dubai
</Link>

<Link to="/car-hire-leasing-ras-al-khor">
Car Hire & Leasing Ras Al Khor
</Link>

<Link to="/cheap-car-hire-ras-al-khor-dubai">
Cheap Car Hire Ras Al Khor Dubai
</Link>

<Link to="/car-rental-ras-al-khor-in-uae-on-the-map">
Car rental ras al khor in UAE on the map
</Link>

<Link to="/pickup-rental-cars-ras-al-khor-industrial-area">
Pickup Rental Cars Ras Al Khor Industrial Area
</Link>

<Link to="/rent-a-car-in-ras-al-khor-dubai">
Rent a Car in Ras Al Khor, Dubai
</Link>

<Link to="/car-rental-car-rentals-in-ras-al-khor-industrial-area">
Car Rental Car Rentals in Ras Al Khor Industrial Area
</Link>

<Link to="/autorent-car-rental-in-dubai-ras-al-khor-uae">
Autorent Car Rental in Dubai Ras Al Khor, UAE
</Link>

<Link to="/dollar-rent-a-car-car-rentals-in-ras-al-khor-industrial-area">
Dollar Rent A Car Car Rentals in Ras Al Khor Industrial Area
</Link>

<Link to="/car-rentals-in-ras-al-khor-dubai">
Car rentals in Ras Al Khor Dubai
</Link>

<Link to="/cheap-car-rental-deals-in-ras-al-khor-dubai">
Cheap Car Rental Deals in Ras Al Khor Dubai
</Link>

<Link to="/car-hire-ras-al-khor-dubai">
Car Hire Ras Al Khor - Dubai
</Link>

<Link to="/best-luxury-car-rental-in-dubai-uae">
Best Luxury Car Rental in Dubai (UAE)
</Link>

<Link to="/cheap-car-hire-in-zaabeel-dubai-from-77-pound-day">
Cheap car hire in Za'abeel, Dubai from £77/day
</Link>

<Link to="/car-rentals-in-zaabeel-dubai-uae">
Car rentals in Za'abeel, Dubai UAE
</Link>

<Link to="/car-rental-in-zaabeel-dubai">
Car Rental In Za'abeel - Dubai
</Link>

<Link to="/rent-a-car-in-zaabeel-dubai-cheap-car-hire">
Rent a Car in Za'abeel, Dubai, Cheap Car Hire
</Link>



<Link to="/dubai-festival-city-car-rental-hire-online">
Dubai Festival City Car Rental & Hire Online
</Link>

<Link to="/car-rental-dubai-festival-city">
Car Rental Dubai Festival City
</Link>

<Link to="/21-dollar-car-rental-dubai-festival-city-mall">
$21 Car Rental Dubai Festival City Mall
</Link>

<Link to="/long-term-car-rental-dubai-festival-city">
Long Term Car Rental Dubai Festival City
</Link>

<Link to="/festival-city-rent-a-car-cheap-car-rentals">
Festival City Rent A Car | Cheap Car Rentals
</Link>

<Link to="/rent-a-car-dubai-festival-city-falcon-rides-car-rental">
Rent A Car Dubai Festival City - Falcon Rides Car Rental
</Link>

<Link to="/car-rental-in-dubai-festival-city">
Car Rental in Dubai Festival City
</Link>

<Link to="/car-rental-dubai-festival-city">
Car rental in Dubai Festival City
</Link>

<Link to="/81-dollar-car-rental-dubai-festival-city">
$81 Car Rental Dubai Festival City
</Link>

<Link to="/car-rental-dubai-festival-city-united-arab-emirates">
Car Rental Dubai Festival City - United Arab Emirates
</Link>

<Link to="/rent-a-car-in-dubai-festival-city">
Rent a Car in Dubai Festival City
</Link>

<Link to="/rent-a-car-in-dubai-festival-city-mall">
Rent a Car in Dubai Festival City Mall
</Link>

<Link to="/cheap-rental-cars-dubai-festival-city-book-now">
Cheap Rental Cars Dubai Festival City Book Now!
</Link>

<Link to="/affordable-car-rent-in-dubai-festival-city-with-great-dubai">
Affordable Car Rent in Dubai Festival City with Great Dubai.
</Link>

<Link to="/rent-a-car-dubai-festival-city-online-reservation">
Rent a car Dubai Festival City - Online reservation
</Link>

<Link to="/easy-and-economical-car-rental-festival-city-dubai">
Easy and economical car rental festival city Dubai
</Link>

<Link to="/rent-a-car-dubai-festival-city">
Rent a Car Dubai Festival City
</Link>





<Link to="/rent-a-car-bur-dubai-cheap-car-rental-hire-in-bur-dubai">
Rent a car Bur Dubai, Cheap Car Rental & Hire in Bur Dubai
</Link>

<Link to="/rent-a-car-bur-dubai-for-75-day">
Rent A Car Bur Dubai For $75/Day
</Link>

<Link to="/rent-a-car-bur-dubai-cheap-car-rental-bur-dubai">
Rent a Car Bur Dubai | Cheap Car Rental Bur Dubai
</Link>

<Link to="/long-term-car-rentals-bur-dubai">
Long-Term Car Rentals Bur Dubai
</Link>

<Link to="/car-hire-in-bur-dubai-search-hire-cars">
Car Hire in Bur Dubai (Dubai) - Search Hire Cars
</Link>

<Link to="/rent-a-car-bur-dubai-affordable-car-rental">
Rent A Car Bur Dubai | Affordable Car Rental
</Link>

<Link to="/bur-dubai-rent-a-car-in-dubai">
Bur Dubai - Rent a Car in Dubai
</Link>

<Link to="/car-rental-dubai-search-cheap-rental-car-deals">
Car Rental Dubai – Search Cheap Rental Car Deals
</Link>

<Link to="/cheap-car-rental-bur-dubai-rent-a-cars-royal-uae">
Cheap car rental Bur Dubai, rent a cars Royal: UAE
</Link>

<Link to="/rent-a-car-in-bur-dubai">
Rent a Car in Bur Dubai
</Link>

<Link to="/car-rental-in-bur-dubai-car-rental-near-me-on-the-map">
Car rental in Bur Dubai, car rental near me on the map
</Link>

<Link to="/car-rental-bur-dubai-cheap-rental-cars">
Car Rental Bur Dubai - Cheap Rental Cars
</Link>

<Link to="/weekly-car-hire-book-your-car-dubai-rental-deals-call-now">
Weekly Car Hire-book your car | Dubai Rental Deals - Call Now
</Link>

<Link to="/rental-cars-in-bur-dubai-in-uae-on-the-map">
Rental cars in Bur Dubai in UAE on the map
</Link>

<Link to="/rent-a-car-bur-dubai-experience-the-best-car">
Rent A Car Bur Dubai (Experience The Best Car)
</Link>

<Link to="/cheap-rent-a-car-in-bur-dubai">
Cheap Rent a Car in Bur Dubai
</Link>

<Link to="/rent-a-car-in-dubai-cheapest-monthly-car-rental">
Rent a Car in Dubai, Cheapest Monthly Car Rental
</Link>

<Link to="/rent-a-car-in-bur-dubai-cheapest-monthly-car-rental">
Rent a Car in Bur Dubai, Cheapest Monthly Car Rental
</Link>

<Link to="/low-cost-car-rental-deals-in-bur-dubai-uae">
Low-cost Car Rental Deals in Bur Dubai, UAE 🔥
</Link>

<Link to="/luxury-car-rental-in-bur-dubai-make-my-ride">
Luxury Car Rental in Bur Dubai | Make My Ride
</Link>

<Link to="/rent-a-car-per-hour-in-dubai-hourly-car-rental">
Rent a car per hour in Dubai | Hourly car rental
</Link>

<Link to="/cheap-rent-a-car-in-bur-dubai-cheapest-as-150-dhs-book">
Cheap Rent A Car In Bur Dubai - Cheapest As 150 Dhs Book
</Link>

<Link to="/monthly-car-rental-in-bur-dubai">
Monthly Car Rental in Bur Dubai
</Link>





<Link to="/car-rental-dubai-dubai-car-rental">
Car rental Dubai - Dubai car rental
</Link>

<Link to="/car-rental-companies-dubai">
Car Rental Companies Dubai
</Link>

<Link to="/fast-rent-a-car-in-dubai-car-rental-dubai-aed-150-day">
Fast Rent a Car In Dubai | Car Rental Dubai AED 150/Day
</Link>

<Link to="/dubai-leading-car-rental-rent-a-car-in-dubai">
Dubai's Leading Car Rental | Rent a Car in Dubai
</Link>

<Link to="/dubai-internaional-airport-terminal-1-dxb-car-rental">
Dubai Intl. Airport Terminal 1 (DXB) Car Rental
</Link>

<Link to="/renting-a-car-ministry-of-economy-uae">
Renting a Car | Ministry of Economy - UAE
</Link>

<Link to="/best-rent-a-car-in-dubai-economy-car-rental-dubai">
Best Rent A Car In Dubai | Economy Car Rental Dubai
</Link>

<Link to="/rent-a-car-in-dubai-dubai-car-rental-hire-car-in-dubai">
Rent a Car in Dubai | Dubai Car Rental | Hire Car in Dubai
</Link>

<Link to="/car-hire-in-dubai-cheap-rental-deals">
Car Hire in Dubai - Cheap Rental Deals
</Link>

<Link to="/rent-a-luxury-car-with-driver-car-rental-companies-dubai">
Rent a Luxury Car with Driver - Car Rental Companies Dubai
</Link>

<Link to="/legend-rent-a-car-car-rental-agency-dubai-hire-auto-vehicle">
Legend Rent a Car: Car Rental Agency Dubai Hire Auto Vehicle
</Link>

<Link to="/car-rental-in-dubai-united-arab-emirates">
Car Rental in Dubai United Arab Emirates
</Link>

<Link to="/car-rental-dubai-united-arab-emirates">
Car Rental Dubai - United Arab Emirates
</Link>

<Link to="/car-leasing-in-dubai-best-car-rental">
Car Leasing in Dubai | Best Car Rental
</Link>

<Link to="/car-for-rent-dubai-low-cost-rent-car-dubai">
Car For Rent Dubai | Low Cost Rent Car Dubai
</Link>

<Link to="/dubai-luxury-car-rental-no-deposit-required">
Dubai Luxury Car Rental - No Deposit Required
</Link>

<Link to="/premium-car-rental-dubai-rent-a-car-self-drive-dubai">
Premium Car Rental Dubai - Rent a Car Self Drive Dubai
</Link>

<Link to="/rent-a-car-car-rental-agency-hire-car">
Rent a Car | Car Rental Agency | Hire car
</Link>

<Link to="/monthly-car-rental-dubai-from-aed-1390">
Monthly Car Rental Dubai from AED 1390
</Link>

<Link to="/affordable-car-rental-in-dubai-low-cost-car-rental-services">
Affordable Car Rental in Dubai | Low Cost Car Rental Services
</Link>

<Link to="/affordable-car-rental-in-dubai-rent-a-car-uae">
Affordable car rental in Dubai - Rent a Car UAE
</Link>

<Link to="/your-cheap-car-hire-in-dubai">
Your Cheap Car Hire in Dubai
</Link>

<Link to="/number-1-car-rental-in-uae-dubai-lease-our-cars-now">
#1 Car Rental in UAE, Dubai | Lease Our Cars Now
</Link>

<Link to="/luxury-car-rental-in-dubai-wow-rent-a-car">
Luxury Car Rental in Dubai - Wow Rent a Car
</Link>



            </div>
          </a>
            <li>
              {" "}
              <Link className="contact-link fgfgfg" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <div className="navbar__buttons">
            {/* <Link className="navbar__buttons__sign-in" to="/">
              Sign In
            </Link> */}
            {/* <Link> */}
              <a className="navbar__buttons__register" href="tel:+971563619373">

              Call Now
              </a>
            {/* </Link> */}
          <Link to="/cart">
            <div className="icon">
              <i className="icon-shopping-cart" style={{fontSize:"34px"}}/>
              {/* <span className="cart-count">{ cart1 ? cart1.length :0}</span> */}
            {/* <p>Cart</p> */}
            </div>
          </Link>
          </div>
          {/* mobile */}
          <div className="mobile-hamb">
            <Link to="/cart">
            <div className="icon">
              <i className="icon-shopping-cart" style={{fontSize:"34px"}}/>
              {/* <span className="cart-count">{ cart1 ? cart1.length :0}</span> */}
            {/* <p>Cart</p> */}
            </div>
          </Link>
            <MenuIcon onClick={openNav} width={30} height={30} style={{fontSize:"29px",marginTop: "17px",marginLeft:"10px",color :"#96040e"}}/>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
